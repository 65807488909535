import React, { useContext, useEffect, useState } from "react";

import Your_Account from "./components/Pages/Your_Account.js";
import getUsername from "./components/Account_Infomation/Username.js";
import Cashout_Page from "./components/Pages/Cashout_Page.js";
import Support_Page from "./components/Pages/Support_Page.js";
import getIsVerified from "./components/Account_Infomation/isVerified.js";
import Modal from "react-modal";
import VerifyMessagePopup from "./components/VerifyMessagePopup.js";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import How_It_Works from "./components/Pages/How_It_Works.js";
import ReddemCoupon from "./components/Pages/ReddemCoupon.js";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { PayProvider } from "./components/PayContext";
import "./components/burgercss.css";
import Payment from "./components/Payment.js";
import MessageBoard from "./components/Pages/MessageBoard.js";
import UserMessages from "./components/Pages/UserMessages.js";
import VerifyMessagePopup2 from "./components/VerifyMessagePopup2.js";
import UserShops from "./components/Pages/shop/UserShops.js";
import { GlobalContext } from "./components/GlobalContext.js";
import ProductForm from "./components/product/product-form.js";
import CreateShopForm from "./components/Pages/shop/create-shop-form.js";
function Test() {
  const [Verified, setVerified] = useState(getIsVerified());

  global.username = Auth.currentUserInfo().username;

  const customStyles = {
    overlay: {
      background: "rgba(52, 52, 52, 0.9)",
    },

    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFFFFF",
    },
  };

  document.title = "Zaplyn App";
  const [menuclick, setMenuclick] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(!Verified);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };
  const setModalIsOpenToTrue2 = () => {
    setModalIsOpen2(true);
  };
  const setModalIsOpenToFalse2 = () => {
    setModalIsOpen2(false);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modalIsOpen}
        className="font-lexand  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
      >
        <VerifyMessagePopup
          onClick={setModalIsOpenToFalse}
          onClick2={setModalIsOpenToTrue2}
        />
      </Modal>

      {modalIsOpen2 && (
        <Modal
          isOpen={modalIsOpen2}
          className="font-lexand  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md shadow-lg text-center"
          overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        >
          <button
            onClick={setModalIsOpenToFalse2}
            className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-pink-400 rounded-md text-sm font-semibold flex-shrink"
          >
            x
          </button>
          <VerifyMessagePopup2 onClick3={setModalIsOpenToFalse2} />
        </Modal>
      )}
      <PayProvider>
        <Routes>
          <Route path="home" element={<MessageBoard />} />
          <Route path="add-product" element={<ProductForm />} />
          <Route path="create-shop" element={<CreateShopForm />} />
          <Route
            path={`cashout`}
            element={<Cashout_Page currentusername={getUsername()} />}
          />
          <Route
            path="support"
            element={<Support_Page currentusername={getUsername()} />}
          />
          <Route
            path="payments"
            element={<Payment currentusername={getUsername()} />}
          />
          <Route
            path="account"
            element={<Your_Account currentusername={getUsername()} />}
          />
          <Route path="how-it-works" element={<How_It_Works />} />
          <Route path="redeem-coupon" element={<ReddemCoupon />} />

          <Route
            path="orders"
            element={<UserMessages currentusername={getUsername()} />}
          />
          <Route path="shops" element={<UserShops />} />
          <Route path="*" element={<Navigate to="home" />} />
        </Routes>
      </PayProvider>
    </React.Fragment>
  );
}

export default Test;
