import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { convertCurrency } from "../../../helper/helper";
import { ThemeContext } from "../../ThemeContext";

const ShopZerocheckoutForm = ({
  orderData,
  shopDetails,
  schedule_delivery,
  partial,
  customerNote,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const navigate = useNavigate();
  const {
    theme,
    setTheme,
    themBtn,
    setThemBtn,
    themBtnStyle,
    setThemBtnStyle,
    themeTileBorder,
    setThemeTileBorder,
    themeTileBorderStyle,
    setThemeTileBorderStyle,
    themeFont,
    setThemeFont,
  } = useContext(ThemeContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postcode, setPostcode] = useState("");

  const [price, setPrice] = useState([]);

  const [receivedOrid, setReceivedOrId] = useState("");

  const [errorMessage, setErrorMessage] = useState(null);

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const validateEmail = () => {
    setIsEmailValid(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) // regex for email validation
    );
  };

  const validatePhone = () => {
    setIsPhoneValid(
      /^[\d\s()+-]+$/.test(phone) && phone.length >= 10 // regex for phone validation
    );
  };

  const handleAddress = async (e) => {
    e.preventDefault();
    validateEmail();
    validatePhone();
    const datanew = orderData.addon.split("-").map((item) => JSON.parse(item));
    var convertedObject = datanew.reduce((result, obj) => {
      Object.keys(obj).forEach((key) => {
        result[key] = obj[key];
      });
      return result;
    }, {});

    if (isEmailValid && isPhoneValid) {
      try {
        const response = await fetch(
          `https://omnistack.org${process.env.REACT_APP_LINK}`+"send-address/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ZaplynTileRef: orderData.ZaplynTileRef,
              shopname: shopDetails.shopname,
              shopref: shopDetails.ZaplynShopRef,
              itemname: orderData.itemname,
              category: orderData.category,
              size: orderData.attribute_1 || "",
              color: orderData.color || "",
              sub_category: orderData.sub_category,
              name: name,
              phone: phone,
              email: email,
              add1: add1,
              add2: add2,
              city: city,
              partial: partial,
              scheduled_time: schedule_delivery || "",
              country: country,
              customerNote: customerNote,
              postcode: postcode,
              price: orderData.newprice,
              qty: orderData.quantity,
              variant: orderData.variantName,
              add_ons: convertedObject,
              attribute_1: orderData.newatrribute_1,
              attribute_2: orderData.newatrribute_2,
              attribute_3: orderData.newatrribute_3,
              attribute_4: orderData.newatrribute_4,
              attribute_5: orderData.newatrribute_5,
            }),
          }
        );

        const json = await response.json();
        if (json) {
          setReceivedOrId(json.Order_id);
          navigate(`/sucess?${json.Order_id}`);
        }
      } catch (error) {
        console.log("Err", error);
      }
    } else {
      console.log("invalid credentials");
    }
  };

  return (
    <section className="mt-1">
      <div>
        {isVisible ? (
          <div
            className={`${
              themeTileBorder === "none"
                ? ``
                : themeTileBorder === "light"
                ? ` ${theme}  ${themeTileBorderStyle} shadow-sm p-0.25`
                : ` ${theme} ${themeTileBorderStyle} shadow-sm p-0.5`
            } mt-5`}
          >
            <form
              id="address-form"
              onSubmit={handleAddress}
              className={`${themeTileBorderStyle} bg-white  p-2`}
            >
              <div className=" ">
                <p className="text-gray-400 text-xl">
                  Please enter address details:
                </p>

                <div className="container flex flex-col mx-auto space-y-12">
                  <div className="grid grid-cols-6 gap-4 col-span-full ">
                    <div className="col-span-full ">
                      <label name="f-name">Name: </label>
                      <input
                        required
                        name="f-name"
                        placeholder="Name"
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                      />
                    </div>
                    <div className="col-span-full md:col-span-3">
                      <label name="email">Email </label>
                      <input
                        required
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="email"
                        type="text"
                        className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                        onBlur={validateEmail}
                      />
                      {!isEmailValid && (
                        <p className="text-xs text-red-400 ">
                          Invalid email address
                        </p>
                      )}
                    </div>
                    <div className="col-span-full md:col-span-3">
                      <label name="phone number">Phone Number </label>
                      <input
                        required
                        name="phone number"
                        onChange={(event) =>
                          setPhone(event.target.value.replace(/[^0-9+\s]/g, ""))
                        }
                        placeholder="Phone Number"
                        type="text"
                        className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                        onBlur={validatePhone}
                      />
                      {!isPhoneValid && (
                        <p className="text-xs text-red-400 ">
                          Invalid phone number
                        </p>
                      )}
                    </div>
                    {shopDetails.shipping_enabled && (
                      <div className="col-span-full grid grid-cols-6 gap-4 ">
                        <div className="col-span-full">
                          <label name="address line 1">Address line 1 </label>
                          <input
                            required
                            name="address line 1"
                            placeholder="Address line 1"
                            type="text"
                            onChange={(e) => setAdd1(e.target.value)}
                            className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                          />
                        </div>
                        <div className="col-span-full">
                          <label name="address line 1">Address line 2 </label>
                          <input
                            required
                            name="address line 2"
                            placeholder="Address line 2"
                            type="text"
                            onChange={(e) => setAdd2(e.target.value)}
                            className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                          />
                        </div>
                        <div className="col-span-full md:col-span-2 ">
                          <label name="city">City </label>
                          <input
                            required
                            name="city"
                            placeholder="City"
                            type="text"
                            onChange={(e) => setCity(e.target.value)}
                            className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                          />
                        </div>
                        <div className="col-span-full md:col-span-2 ">
                          <label name="country">Country </label>
                          <input
                            required
                            name="country"
                            placeholder="Country"
                            type="text"
                            onChange={(e) => setCountry(e.target.value)}
                            className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                          />
                        </div>
                        <div className="col-span-full md:col-span-2 ">
                          <label name="postcode">ZIP / Postal </label>
                          <input
                            required
                            name="postcode"
                            placeholder="Postal code"
                            type="text"
                            onChange={(e) => setPostcode(e.target.value)}
                            className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className={`cursor-pointer shadow-md w-full p-2 mt-2 text-xl font-semibold ${theme}  ${themBtnStyle}`}
              >
                Submit
              </button>
            </form>
          </div>
        ) : (
          <></>
        )}
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </div>
    </section>
  );
};

export default ShopZerocheckoutForm;
