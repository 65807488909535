import React, { createContext, useState } from "react";

export const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState("");
  const [themBtn, setThemBtn] = useState("");
  const [themBtnStyle, setThemBtnStyle] = useState("");
  const [themeTileBorder, setThemeTileBorder] = useState("");
  const [themeTileBorderStyle, setThemeTileBorderStyle] = useState("");
  const [themeFont, setThemeFont] = useState("");
  const [themeView, setThemeView] = useState("");

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        themBtn,
        setThemBtn,
        themBtnStyle,
        themeTileBorder,
        setThemeTileBorder,
        themeTileBorderStyle,
        setThemeTileBorderStyle,
        themeFont,
        setThemeFont,
        setThemBtnStyle,
        themeView,
        setThemeView,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
