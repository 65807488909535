import React from "react";
import { useState, useCallback, useEffect } from "react";
import getToken from "../Token";
import getUsername from "../Account_Infomation/Username";
import { Link } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Support(props) {
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const contactForm = document.querySelector("content-wrapper");
  const [posting, setPosting] = useState(false);
  const accesstoken = getToken();
  const username = getUsername();
  const [body, setBody] = useState("");
  const [message_name, setmessage_name] = useState("");
  const [message_email, setmessage_email] = useState("");
  const [message_subject, setmessage_subject] = useState("");
  const [message, setmessage] = useState("");
  const [mobile, setmobile] = useState("");

  const handleSumbit = (e) => {
    e.preventDefault(); //prevents refreshing page
    const content = {
      message_name,
      message_email,
      message_subject,
      message,
    };

    if (typeof props.tileDetails != "undefined") {
      content.SaturnTileRef = props.tileDetails.supportModelData.SaturnTileRef;
    } else {
      content.SaturnTileRef = 0;
    }

    global.messagelist.push(content);
    // document.getElementById("messagesubject").reset();
  };

  return (
    <div>
      <form class="w-full max-w-lg" onSubmit={handleSumbit}>
        <div class="flex flex-wrap -mx-3 mb-6">
          {/* Name input */}

          <div class="w-full p-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
              for="grid-first-name"
            >
              Full Name
            </label>
            <input
              class="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
              type="text"
              value={message_name}
              onChange={(e) => setmessage_name(e.target.value)}
              name="message-name"
              placeholder="Name"
              // required
            />
          </div>

          {/* Email Input */}

          <div class="w-full p-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              E-mail
            </label>
            <input
              class="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
              type="email"
              value={message_email}
              onChange={(e) => setmessage_email(e.target.value)}
              name="message-email"
              placeholder="Email"
              // required
            />
          </div>
          <div class="w-full p-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              Mobile
            </label>
            <input
              class="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
              type="mobile"
              value={mobile}
              onChange={(e) => setmobile(e.target.value)}
              name="message-email"
              placeholder="Mobile"
              // required
            />
          </div>

          {/* Subject Input */}

          <div class="w-full p-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              Subject
            </label>
            <input
              class="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
              type="text"
              value={message_subject}
              onChange={(e) => setmessage_subject(e.target.value)}
              name="message-subject"
              placeholder="Subject"
              // required
            />
          </div>
        </div>

        {/* Message Input */}

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              Message
            </label>
            <textarea
              class="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
              id="message"
              name="message"
              value={message}
              onChange={(e) => setmessage(e.target.value)}
              placeholder="Message"
              // required
            ></textarea>
          </div>
        </div>
        <ToastContainer />
        <div class="flex justify-center">
          <div class="flex items-center px-10">
            <button
              className="col-span-2 focus:outline-none text-center"
              onClick={() => {
                fetch(link + "contact-email/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accesstoken,
                  },
                  body: JSON.stringify({
                    message_name: username,
                    message_email: message_email,
                    message_mobile: mobile,
                    message_subject: message_subject,
                    message: message,
                  }),
                })
                  .then((response) => response.json())
                  .then((json) => {
                    if (
                      message_email !== "" &&
                      mobile !== "" &&
                      message_subject !== "" &&
                      message !== ""
                    ) {
                      toast(
                        JSON.stringify(json["Notifications-text"]).replace(
                          /"|'/g,
                          ""
                        )
                      );
                    } else {
                      toast("Please fill all fields to create support request");
                    }
                  });

                setmessage_name("");
                setmessage_email("");
                setmessage_subject("");
                setmessage("");
                setmobile("");
              }}
            >
              <div className="focus:border-0 flex-3 ">
                <div className="md:w-40 sm:w-30 button cursor-pointer py-2 px-4 bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full text-xs font-semibold text-gray-600 shadow-md ">
                  Submit
                </div>
              </div>
            </button>
          </div>
          <div class="flex items-center px-10">
            {typeof props.tileDetails != "undefined" && (
              <span
                className="px-10 md:w-40 sm:w-30 button cursor-pointer text-center hover:text-white flex items-center justify-center shadow-lg font-semibold hover:bg-red-800 focus:outline-none rounded-full h-8 bg-yellow-400"
                onClick={props.handleClose}
              >
                Close
              </span>
            )}
            {/* {typeof props.tileDetails == 'undefined' &&
                            <Link to="">
                                <button className="px-10 md:w-40 sm:w-30 button cursor-pointer text-center hover:text-white flex items-center justify-center shadow-lg font-semibold hover:bg-red-800 focus:outline-none rounded-full h-8 bg-yellow-400">
                                    Back
                                </button>
                            </Link>

                        } */}
          </div>
          <div class=""></div>
        </div>
      </form>
    </div>
  );
}
