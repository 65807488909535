import React, { useEffect, useState } from "react";

function SizeComponent({ name, onSelect, selectedSizes }) {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (selectedSizes?.includes(name)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedSizes]);
  return (
    <div
      onClick={() => onSelect(name)}
      className={` shadow-sm
      group relative flex items-center cursor-pointer justify-center rounded-md border p-2  text-xs font-medium uppercase focus:outline-none flex-1 
      ${selected ? "bg-gray-200 " : ""}
       `}
    >
      {name}
    </div>
  );
}

export default SizeComponent;
