import React, { useContext, useEffect, useRef, useState } from "react";
import getToken from "../../Token";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Endpoints from "../../../api/endpoints";
import { GlobalContext } from "../../GlobalContext";
import Modal from "react-modal";
import { useQueryClient } from "react-query";
import QRCode from "qrcode";
import dayjs from "dayjs";
import LoadingModal from "../../popups/LoadingModal";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(tz);

function OrderCards({ productDetails }) {
  const statusRef = useRef(null);
  const statusWrapperRef = useRef(null);
  const [popupPosition, setPopupPosition] = useState({});
  const queryClient = useQueryClient();
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  // const { productCategories } = useContext(GlobalContext);
  const timeZone = dayjs.tz.guess();

  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  const [status, setStatus] = useState("status");
  const [showStatus, setShowStatus] = useState(false);
  const [isShippingOpen, setIsShippingOpen] = useState(false);
  const [productData, setProductData] = useState({});

  const [trackingId, setTrackingId] = useState("");
  const [shippingProvider, setShippingProvider] = useState("");

  const [refundAmount, setRefundAmount] = useState(productDetails?.total_price);

  //note modal
  const [isCustomerNoteModalOpen, setIsCustomerNoteModalOpen] = useState(false);
  const setIsCustomerNoteModalOpenToFalse = () => {
    setIsCustomerNoteModalOpen(false);
  };

  //link modal
  const [linkModalIsOpen, setLinkModalIsOpen] = useState(false);
  const setLinkModalIsOpenToTrue = () => {
    setLinkModalIsOpen(true);
  };
  const setLinkModalIsOpenToFalse = () => {
    setLinkModalIsOpen(false);
  };

  const handleShareCardClick = (tileref) => {
    QRCode.toDataURL("https://zaplyn.link/cart/" + tileref)
      .then((url) => {
        setQrCodeUrl(url);
      })
      .catch((err) => console.error(err));
  };

  const handleDownloadQrCode = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = qrCodeUrl;
    downloadLink.download = "qr-code.png";
    downloadLink.click();
  };

  //tracking_Id modal
  const [trackingIdModal, setTrackingIdModal] = useState(false);
  const setTrackingIdModalIsClosed = () => {
    setTrackingId("");
    setShippingProvider("");
    setTrackingIdModal(false);
  };

  //refund modal
  const [refundModal, setRefundModal] = useState(false);
  const setRefundModalIsClosed = () => {
    setRefundModal(false);
  };

  //product items vielALl modal
  const [showAllItemsModal, setShowAllItemsModal] = useState(false);
  const setShowAllItemsModalIsClosed = () => {
    setShowAllItemsModal(false);
  };

  const handleChangeStatus = async (updatedStatus) => {
    setStatus(updatedStatus);
    if (
      updatedStatus === "dispatched" &&
      (trackingId === "" || shippingProvider === "")
    ) {
      return setTrackingIdModal(true);
      // return toast("please add tracking id");
    } else if (updatedStatus === "status") {
      return console.log("");
    }
    var accesstoken = getToken();
    setIsLoadingModalOpen(true);
    try {
      const response = await fetch(link + "update-status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          order_id: productDetails.order_id,
          status: updatedStatus,
          tracking_id: trackingId || "",
          shipping_provider: shippingProvider || "",
        }),
      });

      const json = await response.json();

      if (json) {
        await queryClient.invalidateQueries(["ordersData"]);
        await queryClient.invalidateQueries(["filteredOrdersData"]);

        toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
        setTrackingId("");
        setShowStatus(false);
        setShippingProvider("");
      }
    } catch (error) {
      // console.log(error);
      toast("Something went wrong, please try again later");
    } finally {
      setStatus("status");

      setIsLoadingModalOpen(false);
    }
  };

  useEffect(() => {
    const first_product = productDetails.Products;
    setProductData(first_product);
  }, [productDetails]);

  useEffect(() => {
    // Add the 'overflow-hidden' class to the body element when the modal is open
    if (showAllItemsModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Remove the event listener when the component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showAllItemsModal]);

  // useEffect(() => {
  //   if (status === "dispatched") {
  //     setTrackingIdModal(true);
  //   }
  // }, [status]);

  // handling status Toggle
  // useEffect(() => {
  //   if (showStatus && statusRef.current) {
  //     const { top, bottom } = statusRef.current.getBoundingClientRect();
  //     const scrollTop =
  //       window.pageYOffset || document.documentElement.scrollTop;
  //     const popupTop = top + scrollTop <= window.innerHeight / 2 ? "top-3" : "";
  //     const popupBottom = bottom >= window.innerHeight / 2 ? "bottom-3" : "";

  //     setPopupPosition(`absolute ${popupTop} ${popupBottom} right-4 `);
  //   }
  // }, [showStatus]);

  // handle status popup
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (
  //       showStatus &&
  //       statusRef.current &&
  //       statusWrapperRef.current &&
  // //       !statusRef.current.contains(event.target) &&
  // //       !statusWrapperRef.current.contains(event.target)
  //     ) {
  //       setShowStatus(false);
  //     }
  //   }

  //   function handleScroll() {
  //     if (showStatus) {
  //       setShowStatus(false);
  //     }
  //   }

  //   document.addEventListener("click", handleClickOutside);
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [showStatus]);

  // console.log(status);

  return (
    <div>
      <div className="my-3">
        <div className="border shadow-md border-pink-300 rounded-t-md ">
          <div className=" flex justify-between bg-gradient-to-b from-pink-200 to-pink-300 rounded-t-md p-1 text-xs md:text-sm">
            <div className="md:flex md:w-2/3 md:justify-between ">
              <p className="text-gray-700">
                Order{" "}
                <span className="text-gray-700">
                  #{productDetails?.order_id}
                </span>
              </p>
              <p className="text-gray-700">
                Received at:{" "}
                <span className="text-gray-700">
                  {dayjs
                    .utc(productDetails?.date)
                    .tz(timeZone)
                    .format("YYYY-MM-DD HH:mm:ss A")}
                </span>
              </p>
            </div>
            <div className=" md:flex ">
              <p className="text-gray-700">Total: &nbsp; </p>
              <span className="text-xs text-gray-700 md:text-sm">
                {productDetails?.currency === "GBP" && (
                  <span className="mr-0.25">£</span>
                )}
                {productDetails?.currency === "EUR" && (
                  <span className="mr-0.25">€</span>
                )}
                {productDetails?.currency === "USD" && (
                  <span className="mr-0.25">$</span>
                )}{" "}
                {productDetails?.total_amount?.toFixed(1)}
              </span>
            </div>
          </div>
          {/* Buyer Info div */}
          <div className="p-1">
            <div className="  md:flex  gap-2   justify-between ">
              {/* md+ devices div customerinfo && shopref && note */}
              <div className="hidden md:flex flex-col gap-1 w-3/5 px-1   my-2 text-gray-700 text-xs">
                <button
                  onClick={() => setIsShippingOpen(!isShippingOpen)}
                  className=" flex items-center justify-between border border-pink-100 rounded-md  px-5 text-sm w-full text-gray-700 "
                >
                  <p>Customer Details:</p>
                  {!isShippingOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                    </svg>
                  )}
                </button>
                {isShippingOpen && (
                  <div className="flex gap-5 p-0.5">
                    <div className="flex  flex-col gap-1">
                      <p className="text-xs text-gray-500 ">
                        Buyer Name:{" "}
                        <span className="text-gray-700 text-sm">
                          {productDetails?.buyer}
                        </span>
                      </p>
                      <div className="flex flex-col">
                        <div className="flex flex-wrap md:flex-col">
                          <div className="flex gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="16"
                              height="16"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z"
                                fill="rgba(50,152,219,1)"
                              />
                            </svg>
                            <p className="mr-4 flex-1 text-gray-700 text-xs">
                              {productDetails?.phone}
                            </p>
                          </div>
                          <div className="flex text-xs gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="16"
                              height="16"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm9.06 8.683L5.648 6.238 4.353 7.762l7.72 6.555 7.581-6.56-1.308-1.513-6.285 5.439z"
                                fill="rgba(241,196,14,1)"
                              />
                            </svg>
                            <p className="text-xs text-gray-700 ">
                              {productDetails?.email}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {productDetails?.add_line_1 !== "" && (
                      <div className="">
                        <p className="text-gray-600 ">Shipping Address: </p>
                        <p>{productDetails?.add_line_1}</p>
                        <p> {productDetails?.add_line_2}</p>
                        <p className="flex space-x-1">
                          <span>{productDetails?.city}</span>
                          <span>{productDetails?.country}</span>
                          <span>{productDetails?.postcode}</span>
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {productDetails?.shopref !== null &&
                  productDetails?.shopref !== "" && (
                    <p className="text-xs p-1">
                      Shop Id{" "}
                      <span className="text-gray-600 text-center  ">
                        #{productDetails?.shopref}
                      </span>
                    </p>
                  )}
                {productDetails?.customer_note !== null &&
                  productDetails?.customer_note !== "" && (
                    <p
                      onClick={() => setIsCustomerNoteModalOpen(true)}
                      className="cursor-pointer w-32 text-xs px-4 py-1 bg-gradient-to-b from-purple-200 to-pink-300 rounded-full font-semibold shadow-md"
                    >
                      Customer Note
                    </p>
                  )}
              </div>
              {/* address div -  devices < md*/}
              <div className="md:hidden  px-1 border border-pink-300 rounded-md my-2 text-gray-700 text-xs">
                <button
                  onClick={() => setIsShippingOpen(!isShippingOpen)}
                  className=" flex items-center justify-between text-sm w-full text-gray-700 "
                >
                  <p>Customer Details:</p>
                  {!isShippingOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                    </svg>
                  )}
                </button>
                {isShippingOpen && (
                  <div className="p-0.5">
                    <div className="flex  flex-col gap-1">
                      <p className="text-sm text-gray-700 ">
                        Buyer Name:{" "}
                        <span className="text-gray-700 text-sm">
                          {productDetails?.buyer}
                        </span>
                      </p>
                      <div className="flex flex-col">
                        <div className="flex flex-wrap md:flex-col">
                          <div className="flex gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="16"
                              height="16"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z"
                                fill="rgba(50,152,219,1)"
                              />
                            </svg>
                            <p className="mr-4 flex-1 text-gray-700 text-xs">
                              {productDetails?.phone}
                            </p>
                          </div>
                          <div className="flex text-xs gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="16"
                              height="16"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm9.06 8.683L5.648 6.238 4.353 7.762l7.72 6.555 7.581-6.56-1.308-1.513-6.285 5.439z"
                                fill="rgba(241,196,14,1)"
                              />
                            </svg>
                            <p className="text-xs text-gray-700 ">
                              {productDetails?.email}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {productDetails?.add_line_1 !== "" && (
                      <div className="mt-2">
                        <p className="text-gray-600 ">Shipping Address: </p>
                        <p>{productDetails?.add_line_1}</p>
                        <p> {productDetails?.add_line_2}</p>
                        <p className="flex space-x-1">
                          <span>{productDetails?.city}</span>
                          <span>{productDetails?.country}</span>
                          <span>{productDetails?.postcode}</span>
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="flex justify-between gap-1 items-center">
                {productDetails?.shopref !== null &&
                  productDetails?.shopref !== "" && (
                    <p className="text-xs md:hidden p-1">
                      Shop Id{" "}
                      <span className="text-gray-600 text-center  ">
                        #{productDetails?.shopref}
                      </span>
                    </p>
                  )}
                {productDetails?.customer_note !== null &&
                  productDetails?.customer_note !== "" && (
                    <p
                      onClick={() => setIsCustomerNoteModalOpen(true)}
                      className="md:hidden cursor-pointer text-xs px-4 py-1 bg-gradient-to-b from-purple-200 to-pink-300 rounded-full font-semibold shadow-md "
                    >
                      Customer Note
                    </p>
                  )}
              </div>

              {/*attributes on mobile devices start */}
              <div className=" md:hidden">
                {productData &&
                  Object.keys(productData).length > 0 &&
                  Object.keys(productData)
                    .slice(0, 2)
                    .map((productRef) => (
                      <div
                        key={productRef}
                        className="flex flex-col md:flex-row items-start w-full p-2"
                      >
                        {/* image */}
                        <div className="flex gap-2 items-center">
                          <div className="w-12">
                            <img
                              src={productData[productRef]?.images}
                              alt=""
                              className="w-12 h-12 rounded-lg"
                            />
                          </div>
                          <div className=" ">
                            {/* product name */}
                            <p className="text-sm md:text-sm capitalize">
                              {productData[productRef]?.itemname}
                            </p>
                            {/* quantity */}
                            <div className="flex flex-row items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                className="w-4 h-4"
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M4 6.414L.757 3.172l1.415-1.415L5.414 5h15.242a1 1 0 0 1 .958 1.287l-2.4 8a1 1 0 0 1-.958.713H6v2h11v2H5a1 1 0 0 1-1-1V6.414zM6 7v6h11.512l1.8-6H6zm-.5 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm12 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                              </svg>
                              <p className="text-gray-700  ml-1 flex text-xs ">
                                Quantity:
                              </p>
                              <span className=" text-xs text-gray-600 p-1">
                                {productData[productRef]?.quantity}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* attributes */}

                        <div className="flex items-start  justify-start pt-1 w-full space-x-1 divide-x  overflow-x-auto">
                          {productData[productRef]?.add_ons !== null &&
                            productData[productRef]?.add_ons !== undefined &&
                            Object.keys(productData[productRef]?.add_ons)
                              .length > 0 &&
                            Object.keys(productData[productRef]?.add_ons).map(
                              (addonName) => (
                                <div className="pl-2 text-xxs">
                                  <p className=" text-gray-700 capitalize ">
                                    {addonName}
                                  </p>
                                  <p className=" text-gray-500">
                                    <span className="mr-1">£</span>

                                    {
                                      productData[productRef]?.add_ons[
                                        addonName
                                      ]
                                    }
                                  </p>
                                </div>
                              )
                            )}
                          {Object.keys(productData[productRef]?.variant)
                            .length > 0 &&
                            Object.values(
                              productData[productRef]?.variant
                            ).toString() !== "null" && (
                              <div className="pl-2 text-xxs">
                                <p className=" text-gray-700 capitalize ">
                                  {Object.keys(
                                    productData[productRef]?.variant
                                  )}
                                </p>
                                <p className=" text-gray-500">
                                  <span className="mr-1">£</span>

                                  {Object.values(
                                    productData[productRef]?.variant
                                  )}
                                </p>
                              </div>
                            )}
                          {productData[productRef]?.attribute_1.split(
                            "-"
                          )[0] === "color" &&
                            productData[productRef]?.attribute_1.split(
                              "-"
                            )[1] !== "null" && (
                              <div className="">
                                <p className="text-xxs text-gray-700 capitalize ">
                                  color
                                </p>
                                <button
                                  className="rounded-full"
                                  style={{
                                    backgroundColor: `${
                                      productData[
                                        productRef
                                      ]?.attribute_1.split("-")[1]
                                    }`,
                                    width: "18px",
                                    height: "18px",
                                    marginLeft: "5px",
                                    marginTop: "3px",
                                  }}
                                ></button>
                              </div>
                            )}
                          {productData[productRef]?.attribute_1.split(
                            "-"
                          )[0] !== "color" &&
                            productData[productRef]?.attribute_1.split(
                              "-"
                            )[1] !== "null" && (
                              <div className="ml-2">
                                <div className="pl-2">
                                  <p className="text-xxs text-gray-700 capitalize ">
                                    {
                                      productData[
                                        productRef
                                      ]?.attribute_1.split("-")[0]
                                    }
                                  </p>
                                  <p className="text-xxs text-gray-500">
                                    {
                                      productData[
                                        productRef
                                      ]?.attribute_1.split("-")[1]
                                    }
                                  </p>
                                </div>
                              </div>
                            )}
                          {productData[productRef]?.attribute_2.split(
                            "-"
                          )[0] !== "null" &&
                            productData[productRef]?.attribute_2.split(
                              "-"
                            )[1] !== "null" && (
                              <div className="pl-2">
                                <p className="text-xxs text-gray-700 capitalize ">
                                  {
                                    productData[productRef]?.attribute_2.split(
                                      "-"
                                    )[0]
                                  }
                                </p>
                                <p className="text-xxs text-gray-500">
                                  {
                                    productData[productRef]?.attribute_2.split(
                                      "-"
                                    )[1]
                                  }
                                </p>
                              </div>
                            )}
                          {productData[productRef]?.attribute_3.split(
                            "-"
                          )[0] !== "null" &&
                            productData[productRef]?.attribute_3.split(
                              "-"
                            )[1] !== "null" && (
                              <div className="pl-2">
                                <p className="text-xxs text-gray-700 capitalize ">
                                  {
                                    productData[productRef]?.attribute_3.split(
                                      "-"
                                    )[0]
                                  }
                                </p>
                                <p className="text-xxs text-gray-500">
                                  {
                                    productData[productRef]?.attribute_3.split(
                                      "-"
                                    )[1]
                                  }
                                </p>
                              </div>
                            )}
                          {productData[productRef]?.attribute_4.split(
                            "-"
                          )[0] !== "null" &&
                            productData[productRef]?.attribute_4.split(
                              "-"
                            )[1] !== "null" && (
                              <div className="pl-2">
                                <p className="text-xxs text-gray-700 capitalize ">
                                  {
                                    productData[productRef]?.attribute_4.split(
                                      "-"
                                    )[0]
                                  }
                                </p>
                                <p className="text-xxs text-gray-500">
                                  {
                                    productData[productRef]?.attribute_4.split(
                                      "-"
                                    )[1]
                                  }
                                </p>
                              </div>
                            )}
                          {productData[productRef]?.attribute_5.split(
                            "-"
                          )[0] !== "null" &&
                            productData[productRef]?.attribute_5.split(
                              "-"
                            )[1] !== "null" && (
                              <div className="pl-2">
                                <p className="text-xxs text-gray-700 capitalize ">
                                  {
                                    productData[productRef]?.attribute_5.split(
                                      "-"
                                    )[0]
                                  }
                                </p>
                                <p className="text-xxs text-gray-500">
                                  {
                                    productData[productRef]?.attribute_5.split(
                                      "-"
                                    )[1]
                                  }
                                </p>
                              </div>
                            )}

                          {/* Estimated delivery */}
                          {productData[productRef]?.etm_date !== "" &&
                            productData[productRef]?.etm_date !== null &&
                            productData[productRef]?.etm_date?.split(",")[2] !==
                              "" &&
                            productData[productRef]?.etm_date?.split(",")[2] !==
                              "select" && (
                              <div className="text-xxs pl-2 flex flex-col items-end text-gray-500  ">
                                <p className="items-center justify-end ">
                                  Estimated Delivery
                                </p>
                                <p className="flex gap-2 text-xxs ">
                                  {
                                    productData[productRef]?.etm_date?.split(
                                      ","
                                    )[0]
                                  }
                                  -
                                  {
                                    productData[productRef]?.etm_date?.split(
                                      ","
                                    )[1]
                                  }
                                  <span className=" text-gray-500">
                                    {
                                      productData[productRef]?.etm_date?.split(
                                        ","
                                      )[2]
                                    }
                                  </span>
                                </p>
                              </div>
                            )}
                        </div>
                      </div>
                    ))}
                {productData &&
                  productData !== null &&
                  productData !== undefined &&
                  Object.keys(productData).length > 2 && (
                    <button
                      onClick={() => setShowAllItemsModal(true)}
                      className="text-xs font-semibold text-gray-700"
                    >
                      View All
                    </button>
                  )}
              </div>
              {/*attributes on mobile devices END */}

              {/* schedule_Time div */}
              {productDetails?.scheduled_time !== null &&
                productDetails?.scheduled_time !== "" &&
                productDetails?.scheduled_time?.slot !== null &&
                productDetails?.scheduled_time?.slot !== undefined &&
                Object.keys(productDetails?.scheduled_time?.slot).length >
                  0 && (
                  <div className="flex md:hidden text-xs mt-2 gap-1 text-gray-600  items-center justify-center">
                    <p>Scheduled Time</p>
                    <span className="bg-gradient-to-t from-purple-500 to-pink-500 to-20%  text-transparent bg-clip-text">
                      {/* {productDetails?.scheduled_time} */}
                      {dayjs
                        .utc(productDetails?.scheduled_time?.date)
                        .tz(timeZone)
                        .format("YYYY-MM-DD")}{" "}
                      {dayjs
                        .utc(productDetails?.scheduled_time?.slot?.from)
                        .tz(timeZone)
                        .format("HH:mm A")}{" "}
                      {dayjs
                        .utc(productDetails?.scheduled_time?.slot?.to)
                        .tz(timeZone)
                        .format("HH:mm A")}
                    </span>
                  </div>
                )}

              {/* check status and save div >> popup */}
              <div className="relative z-10 justify-between gap-2  ">
                {/* status div */}
                <div className="flex flex-col gap-1 items-center w-full px-1">
                  <p className="text-center z-10 md:text-left w-full text-sm font-semibold bg-gradient-to-t from-purple-500 to-pink-500 to-20%  text-transparent bg-clip-text">
                    Payment Status{" "}
                    <span className="text-gray-600 text-xs font-light">
                      £ {productDetails?.total_price?.toFixed(1)}{" "}
                      {`(${productDetails?.partial}%)`} paid
                    </span>
                  </p>
                  <div
                    ref={statusRef}
                    onClick={() => setShowStatus(!showStatus)}
                    className=" flex z-10 items-center justify-center cursor-pointer text-center text-xs   p-0.5 sm:px-2 w-full"
                  >
                    <span className="text-xs w-1/3"> Current Status:</span>
                    <div className="flex items-center gap-2 text-xs bg-gradient-to-b from-purple-500 to-pink-500 to-20%  text-transparent bg-clip-text p-1.5   ">
                      {(productDetails.status === "received" ||
                        productDetails.status === "Received") && (
                        <p className="flex gap-1 items-center">
                          Received at: <br />
                          {dayjs
                            .utc(productDetails?.date)
                            .tz(timeZone)
                            .format("YYYY-MM-DD HH:mm:ss A")}
                        </p>
                      )}
                      {productDetails.status === "Ready" && (
                        <p className="flex gap-1 items-center">
                          Ready at: <br />
                          {dayjs
                            .utc(productDetails?.ready)
                            .tz(timeZone)
                            .format("YYYY-MM-DD HH:mm:ss A")}
                        </p>
                      )}
                      {productDetails.status === "Dispatched" && (
                        <p className="flex gap-1 items-center">
                          Dispatched at: <br />
                          {dayjs
                            .utc(productDetails?.dispatched_at)
                            .tz(timeZone)
                            .format("YYYY-MM-DD HH:mm:ss A")}
                        </p>
                      )}
                      {productDetails.status === "Out For Delivery" && (
                        <p className="flex gap-1 items-center ">
                          Out for delivery at: <br />
                          {dayjs
                            .utc(productDetails?.out_for_delivery_at)
                            .tz(timeZone)
                            .format("YYYY-MM-DD HH:mm:ss A")}
                        </p>
                      )}
                      {productDetails.status === "Delivered" && (
                        <p className="flex gap-1 items-center">
                          Delivered at: <br />
                          {dayjs
                            .utc(productDetails?.delivered_at)
                            .tz(timeZone)
                            .format("YYYY-MM-DD HH:mm:ss A")}
                        </p>
                      )}
                      {productDetails.status === "Refunded" && (
                        <p className="flex gap-1 items-center">
                          Refunded at: <br />
                          {dayjs
                            .utc(productDetails?.refunded_at)
                            .tz(timeZone)
                            .format("YYYY-MM-DD HH:mm:ss A")}
                        </p>
                      )}
                    </div>
                  </div>

                  {productDetails.status !== "Delivered" && (
                    <div className=" flex flex-col gap-2 items-center justify-around w-full px-4">
                      {/* select status */}
                      <select
                        class="focus:outline-none text-xs w-full text-gray-700 bg-gradient-to-t from-pink-100 to-white p-1 my-2 cursor-pointer rounded-md border border-pink-300"
                        required
                        value={status}
                        onChange={(e) => handleChangeStatus(e.target.value)}
                      >
                        <option value="status">Update Status</option>

                        <option value="received">Received</option>
                        <option value="ready">Ready</option>
                        <option value="dispatched">Dispatched</option>

                        <option value="out_for_delivery">
                          Out For Delivery
                        </option>

                        <option value="delivered">Delivered</option>
                      </select>
                    </div>
                  )}
                </div>

                {/* refund button */}
                <div className="flex gap-1 justify-around flex-row-reverse md:px-3 mt-2 md:mt-1 ">
                  {productDetails?.refunded_at === null &&
                    productDetails.status !== "Delivered" && (
                      <button
                        className=" rounded-full bg-gradient-to-b from-purple-200 to-pink-300 px-4 p-0.5 font-semibold text-sm text-gray-700 my-1 shadow-md "
                        onClick={() => setRefundModal(true)}
                      >
                        Refund
                      </button>
                    )}
                  {productDetails.partial !== 100 &&
                    productDetails?.refund_amount !== null && (
                      <button
                        className=" rounded-full bg-gradient-to-b from-purple-200 to-pink-300 px-4 p-0.5 font-semibold text-sm text-gray-700 my-1 shadow-md "
                        onClick={() => {
                          setLinkModalIsOpenToTrue();
                          handleShareCardClick(productDetails.order_id);
                          // toast("Sharing link copied!");
                        }}
                      >
                        Request Payment
                      </button>
                    )}
                </div>
                {productDetails?.refunded_at !== null &&
                  productDetails?.refund_amount !== null && (
                    <p className="flex items-center justify-center gap-2 mt-2 text-center w-full font-semibold  ">
                      <span className="bg-gradient-to-b from-purple-500 to-pink-500 to-20%  text-transparent bg-clip-text">
                        Refunded amount :
                      </span>
                      <span>{productDetails?.refund_amount}</span>
                    </p>
                  )}
              </div>
            </div>

            {/* schedule_Time div */}
            {productDetails?.scheduled_time !== null &&
              productDetails?.scheduled_time !== "" &&
              productDetails?.scheduled_time?.slot !== null &&
              productDetails?.scheduled_time?.slot !== undefined &&
              Object.keys(productDetails?.scheduled_time?.slot).length > 0 && (
                <div className="hidden md:flex text-xs mt-2 gap-1 text-gray-600  items-center justify-center">
                  <p>Scheduled Time</p>
                  <span className="bg-gradient-to-t from-purple-500 to-pink-500 to-20%  text-transparent bg-clip-text">
                    {/* {productDetails?.scheduled_time} */}
                    {dayjs
                      .utc(productDetails?.scheduled_time?.date)
                      .tz(timeZone)
                      .format("YYYY-MM-DD")}
                    {"  "}
                    {dayjs
                      .utc(productDetails?.scheduled_time?.slot?.from)
                      .tz(timeZone)
                      .format("HH:mm A")}{" "}
                    {dayjs
                      .utc(productDetails?.scheduled_time?.slot?.to)
                      .tz(timeZone)
                      .format("HH:mm A")}
                  </span>
                </div>
              )}

            {/* md+ devices attributes start */}
            {/* product details */}
            <div className="hidden md:block">
              {productData &&
                Object.keys(productData).length > 0 &&
                Object.keys(productData)
                  .slice(0, 2)
                  .map((productRef) => (
                    <div
                      key={productRef}
                      className="flex flex-col md:flex-row items-start w-full p-2"
                    >
                      {/* image */}
                      <div className="flex gap-2 items-center">
                        <div className="w-12">
                          <img
                            src={productData[productRef]?.images}
                            alt=""
                            className="w-12 h-12 rounded-lg"
                          />
                        </div>
                        <div className=" ">
                          {/* product name */}
                          <p className="text-sm md:text-sm capitalize">
                            {productData[productRef]?.itemname}
                          </p>
                          {/* quantity */}
                          <div className="flex flex-row items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              className="w-4 h-4"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M4 6.414L.757 3.172l1.415-1.415L5.414 5h15.242a1 1 0 0 1 .958 1.287l-2.4 8a1 1 0 0 1-.958.713H6v2h11v2H5a1 1 0 0 1-1-1V6.414zM6 7v6h11.512l1.8-6H6zm-.5 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm12 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                            </svg>
                            <p className="text-gray-700  ml-1 flex text-xs ">
                              Quantity:
                            </p>
                            <span className=" text-xs text-gray-600 p-1">
                              {productData[productRef]?.quantity}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* attributes */}

                      <div className="flex items-start text-xs  justify-start ml-2 pt-1 w-full  space-x-1 divide-x  ">
                        {productData[productRef]?.add_ons !== null &&
                          productData[productRef]?.add_ons !== undefined &&
                          Object.keys(productData[productRef]?.add_ons).length >
                            0 &&
                          Object.keys(productData[productRef]?.add_ons).map(
                            (addonName) => (
                              <div className="pl-2 text-xs">
                                <p className=" text-gray-700 capitalize ">
                                  {addonName}
                                </p>
                                <p className=" text-gray-500">
                                  <span className="mr-1">£</span>

                                  {productData[productRef]?.add_ons[addonName]}
                                </p>
                              </div>
                            )
                          )}
                        {Object.keys(productData[productRef]?.variant).length >
                          0 &&
                          Object.values(
                            productData[productRef]?.variant
                          ).toString() !== "null" && (
                            <div className="pl-2">
                              <p className=" text-gray-700 capitalize ">
                                {Object.keys(productData[productRef]?.variant)}
                              </p>
                              <p className=" text-gray-500">
                                {productDetails.currency === "GBP" && (
                                  <span className="mr-1">£</span>
                                )}
                                {productDetails.currency === "EUR" && (
                                  <span className="mr-1">€</span>
                                )}
                                {productDetails.currency === "USD" && (
                                  <span className="mr-1">$</span>
                                )}
                                {Object.values(
                                  productData[productRef]?.variant
                                )}
                              </p>
                            </div>
                          )}

                        {productData[productRef]?.attribute_1.split("-")[0] ===
                          "color" &&
                          productData[productRef]?.attribute_1.split("-")[1] !==
                            "null" && (
                            <div className="">
                              <p className=" text-gray-700 capitalize ">
                                color
                              </p>
                              <button
                                className="rounded-full"
                                style={{
                                  backgroundColor: `${
                                    productData[productRef]?.attribute_1.split(
                                      "-"
                                    )[1]
                                  }`,
                                  width: "18px",
                                  height: "18px",
                                  marginLeft: "5px",
                                  marginTop: "3px",
                                }}
                              ></button>
                            </div>
                          )}
                        {productData[productRef]?.attribute_1.split("-")[0] !==
                          "color" &&
                          productData[productRef]?.attribute_1.split("-")[1] !==
                            "null" && (
                            <div className="ml-2">
                              <div className="pl-2">
                                <p className=" text-gray-700 capitalize ">
                                  {
                                    productData[productRef]?.attribute_1.split(
                                      "-"
                                    )[0]
                                  }
                                </p>
                                <p className=" text-gray-500">
                                  {
                                    productData[productRef]?.attribute_1.split(
                                      "-"
                                    )[1]
                                  }
                                </p>
                              </div>
                            </div>
                          )}
                        {productData[productRef]?.attribute_2.split("-")[0] !==
                          "null" &&
                          productData[productRef]?.attribute_2.split("-")[1] !==
                            "null" && (
                            <div className="pl-2">
                              <p className=" text-gray-700 capitalize ">
                                {
                                  productData[productRef]?.attribute_2.split(
                                    "-"
                                  )[0]
                                }
                              </p>
                              <p className=" text-gray-500">
                                {
                                  productData[productRef]?.attribute_2.split(
                                    "-"
                                  )[1]
                                }
                              </p>
                            </div>
                          )}
                        {productData[productRef]?.attribute_3.split("-")[0] !==
                          "null" &&
                          productData[productRef]?.attribute_3.split("-")[1] !==
                            "null" && (
                            <div className="pl-2">
                              <p className=" text-gray-700 capitalize ">
                                {
                                  productData[productRef]?.attribute_3.split(
                                    "-"
                                  )[0]
                                }
                              </p>
                              <p className=" text-gray-500">
                                {
                                  productData[productRef]?.attribute_3.split(
                                    "-"
                                  )[1]
                                }
                              </p>
                            </div>
                          )}
                        {productData[productRef]?.attribute_4.split("-")[0] !==
                          "null" &&
                          productData[productRef]?.attribute_4.split("-")[1] !==
                            "null" && (
                            <div className="pl-2">
                              <p className=" text-gray-700 capitalize ">
                                {
                                  productData[productRef]?.attribute_4.split(
                                    "-"
                                  )[0]
                                }
                              </p>
                              <p className=" text-gray-500">
                                {
                                  productData[productRef]?.attribute_4.split(
                                    "-"
                                  )[1]
                                }
                              </p>
                            </div>
                          )}
                        {productData[productRef]?.attribute_5.split("-")[0] !==
                          "null" &&
                          productData[productRef]?.attribute_5.split("-")[1] !==
                            "null" && (
                            <div className="pl-2">
                              <p className=" text-gray-700 capitalize ">
                                {
                                  productData[productRef]?.attribute_5.split(
                                    "-"
                                  )[0]
                                }
                              </p>
                              <p className=" text-gray-500">
                                {
                                  productData[productRef]?.attribute_5.split(
                                    "-"
                                  )[1]
                                }
                              </p>
                            </div>
                          )}

                        {/* Estimated delivery */}
                        {productData[productRef]?.etm_date !== "" &&
                          productData[productRef]?.etm_date !== null &&
                          productData[productRef]?.etm_date?.split(",")[2] !==
                            "" &&
                          productData[productRef]?.etm_date?.split(",")[2] !==
                            "select" && (
                            <div className=" pl-2 flex flex-col items-end text-gray-500  ">
                              <p className="items-center justify-end ">
                                Estimated Delivery
                              </p>
                              <p className="flex gap-2  ">
                                {
                                  productData[productRef]?.etm_date?.split(
                                    ","
                                  )[0]
                                }
                                -
                                {
                                  productData[productRef]?.etm_date?.split(
                                    ","
                                  )[1]
                                }
                                <span className=" text-gray-500">
                                  {
                                    productData[productRef]?.etm_date?.split(
                                      ","
                                    )[2]
                                  }
                                </span>
                              </p>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
              {productData &&
                productData !== null &&
                productData !== undefined &&
                Object.keys(productData).length > 2 && (
                  <button
                    onClick={() => setShowAllItemsModal(true)}
                    className="text-xs font-semibold text-gray-700"
                  >
                    View All
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
      {/* ordered products scrollable modal */}
      <Modal
        isOpen={showAllItemsModal}
        className=" max-w-lg z-50 mx-5 sm:mx-auto my-5 md:my-10 rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setShowAllItemsModal(false)}
      >
        <button
          onClick={setShowAllItemsModalIsClosed}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer text-white hover:text-black bg-pink-400 hover:bg-white rounded-md text-sm font-semibold flex-shrink"
        >
          x
        </button>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle max-w-lg w-full">
          <div className="bg-white space-y-3 px-0.5 pt-5 pb-4 sm:p-6 sm:pb-4 overflow-y-scroll max-h-96">
            {productData &&
              productData !== null &&
              productData !== undefined &&
              Object.keys(productData).map((productRef) => (
                <div
                  key={productRef}
                  className="flex flex-col md:flex-row gap-2 items-start p-1 w-full shadow-sm"
                >
                  <div className="flex gap-2 items-start">
                    {/* image */}
                    <div className="">
                      <img
                        src={productData[productRef]?.images}
                        alt=""
                        className="w-12 h-12 rounded-lg"
                      />
                    </div>
                    <div className=" ">
                      {/* product name */}
                      <p className="text-sm md:text-sm capitalize">
                        {productData[productRef]?.itemname}
                      </p>
                      {/* quantity */}
                      <div className="flex flex-row items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className="w-4 h-4"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M4 6.414L.757 3.172l1.415-1.415L5.414 5h15.242a1 1 0 0 1 .958 1.287l-2.4 8a1 1 0 0 1-.958.713H6v2h11v2H5a1 1 0 0 1-1-1V6.414zM6 7v6h11.512l1.8-6H6zm-.5 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm12 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                        </svg>
                        <p className="text-gray-700  ml-1 flex text-xs ">
                          Quantity:
                        </p>
                        <span className=" text-xs text-gray-600 p-1">
                          {productData[productRef]?.quantity}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* attributes */}
                  <div className="flex space-x-2 text-sm divide-x ">
                    {productData[productRef]?.attribute_1.split("-")[0] ===
                      "color" &&
                      productData[productRef]?.attribute_1.split("-")[1] !==
                        "null" && (
                        <div className="ml-2">
                          <p className="text-xxs text-gray-700 capitalize ">
                            color
                          </p>
                          <button
                            className="rounded-full"
                            style={{
                              backgroundColor: `${
                                productData[productRef]?.attribute_1.split(
                                  "-"
                                )[1]
                              }`,
                              width: "18px",
                              height: "18px",
                              marginLeft: "5px",
                              marginTop: "3px",
                            }}
                          ></button>
                        </div>
                      )}
                    {productData[productRef]?.attribute_1.split("-")[0] !==
                      "color" &&
                      productData[productRef]?.attribute_1.split("-")[1] !==
                        "null" && (
                        <div className="ml-2">
                          <div className="pl-2">
                            <p className="text-xxs text-gray-700 capitalize ">
                              {
                                productData[productRef]?.attribute_1.split(
                                  "-"
                                )[0]
                              }
                            </p>
                            <p className=" text-gray-500">
                              {
                                productData[productRef]?.attribute_1.split(
                                  "-"
                                )[1]
                              }
                            </p>
                          </div>
                        </div>
                      )}
                    {productData[productRef]?.attribute_2.split("-")[0] !==
                      "null" &&
                      productData[productRef]?.attribute_2.split("-")[1] !==
                        "null" && (
                        <div className="pl-2">
                          <p className="text-xxs text-gray-700 capitalize ">
                            {productData[productRef]?.attribute_2.split("-")[0]}
                          </p>
                          <p className=" text-gray-500">
                            {productData[productRef]?.attribute_2.split("-")[1]}
                          </p>
                        </div>
                      )}
                    {productData[productRef]?.attribute_3.split("-")[0] !==
                      "null" &&
                      productData[productRef]?.attribute_3.split("-")[1] !==
                        "null" && (
                        <div className="pl-2">
                          <p className="text-xxs text-gray-700 capitalize ">
                            {productData[productRef]?.attribute_3.split("-")[0]}
                          </p>
                          <p className=" text-gray-500">
                            {productData[productRef]?.attribute_3.split("-")[1]}
                          </p>
                        </div>
                      )}
                    {productData[productRef]?.attribute_4.split("-")[0] !==
                      "null" &&
                      productData[productRef]?.attribute_4.split("-")[1] !==
                        "null" && (
                        <div className="pl-2">
                          <p className="text-xxs text-gray-700 capitalize ">
                            {productData[productRef]?.attribute_4.split("-")[0]}
                          </p>
                          <p className=" text-gray-500">
                            {productData[productRef]?.attribute_4.split("-")[1]}
                          </p>
                        </div>
                      )}
                    {productData[productRef]?.attribute_5.split("-")[0] !==
                      "null" &&
                      productData[productRef]?.attribute_5.split("-")[1] !==
                        "null" && (
                        <div className="pl-2">
                          <p className="text-xxs text-gray-700 capitalize ">
                            {productData[productRef]?.attribute_5.split("-")[0]}
                          </p>
                          <p className=" text-gray-500">
                            {productData[productRef]?.attribute_5.split("-")[1]}
                          </p>
                        </div>
                      )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>

      {/* ordered products scrollable modal */}
      <Modal
        isOpen={trackingIdModal}
        className=" max-w-lg z-50 mx-5 sm:mx-auto my-5 md:my-10 rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
      >
        <button
          onClick={setTrackingIdModalIsClosed}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer text-white hover:text-black bg-pink-400 hover:bg-white rounded-md text-sm font-semibold flex-shrink"
        >
          x
        </button>
        <div className="flex items-start justify-center bg-white p-5  rounded-xl">
          <div className="flex flex-col items-end space-y-4 ">
            <div className="flex flex-col items-start gap-0.5">
              <label htmlFor="trackingId">Tracking ID</label>
              <input
                id="trackingId"
                type="text"
                className="mx-2 bg-gray-100 px-2 p-1 border border-gray-200 rounded-lg focus:outline-none"
                value={trackingId}
                onChange={(event) => setTrackingId(event.target.value)}
              />
            </div>
            <div className="flex flex-col items-start gap-0.5">
              <label htmlFor="trackingId">Shipping Provider</label>
              <input
                id="trackingId"
                type="text"
                className="mx-2 bg-gray-100 px-2 p-1 border border-gray-200 rounded-lg focus:outline-none"
                value={shippingProvider}
                onChange={(event) => setShippingProvider(event.target.value)}
              />
            </div>

            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setTrackingIdModal(false);
                  handleChangeStatus("dispatched");
                }}
                className=" rounded-full bg-gradient-to-b from-purple-200 to-pink-300 w-24 font-semibold text-md text-gray-700 my-1 shadow-md "
              >
                save
              </button>
              <button
                onClick={() => {
                  setTrackingId("");
                  setShippingProvider("");
                  setStatus("status");
                  setTrackingIdModal(false);
                }}
                className=" rounded-full bg-gradient-to-b from-purple-200 to-pink-300 w-24 font-semibold text-md text-gray-700 my-1 shadow-md "
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* refund modal */}
      <Modal
        isOpen={refundModal}
        className=" max-w-lg z-50 mx-5 sm:mx-auto my-5 md:my-10 rounded-md font-lexand shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
      >
        <button
          onClick={setRefundModalIsClosed}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer text-white hover:text-black bg-pink-400 hover:bg-white rounded-md text-sm font-semibold flex-shrink"
        >
          x
        </button>
        <div className="flex items-start justify-center bg-white p-5  rounded-xl">
          <div className="flex flex-col items-end space-y-4">
            <div>
              <label htmlFor="refundAmt">Amount </label>
              <input
                id="refundAmt"
                type="Number"
                placeholder={productDetails?.total_price}
                className="mx-2 bg-gray-100 px-2 p-1 border border-gray-200 rounded-lg focus:outline-none"
                value={refundAmount}
                onChange={(event) => setRefundAmount(event.target.value)}
              />
            </div>

            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setRefundModal(false);
                  var accesstoken = getToken();
                  window.dataLayer.push({
                    order_date: dayjs(Date.now()),
                    order_amount: refundAmount,
                    order_id: productDetails.order_id,
                    event: "refund_click",
                  });
                  fetch(
                    `https://omnistack.org${process.env.REACT_APP_LINK}` +
                      "refund/",
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accesstoken,
                      },
                      body: JSON.stringify({
                        order_id: productDetails.order_id,
                        amount: refundAmount,
                      }),
                    }
                  )
                    .then((response) => response.json())
                    .then((json) => {
                      toast(
                        JSON.stringify(json["Notification-text"]).replace(
                          /"|'/g,
                          ""
                        )
                      );
                    });
                }}
                className=" rounded-full bg-gradient-to-b from-purple-200 to-pink-300 w-24 font-semibold text-md text-gray-700 my-1 shadow-md "
              >
                save
              </button>
              <button
                onClick={() => {
                  setRefundModal(false);
                }}
                className=" rounded-full bg-gradient-to-b from-purple-200 to-pink-300 w-24 font-semibold text-md text-gray-700 my-1 shadow-md "
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* share link modal */}
      <Modal
        isOpen={linkModalIsOpen}
        className="font-lexand  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setLinkModalIsOpen(false)}
      >
        <button
          onClick={setLinkModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-pink-400 rounded-md text-sm font-semibold flex-shrink"
        >
          x
        </button>
        <div className="bg-white  p-6 rounded-md flex flex-col items-center">
          <div className="relative">
            <img src={qrCodeUrl} alt="QR code" className="w-72 h-72" />
            <button
              title="Download QR"
              className="absolute bottom-5 -right-3 shadow-md bg-pink-300 rounded-b-md rounded-l-md"
              onClick={handleDownloadQrCode}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="h-8 w-8"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
              </svg>
            </button>
          </div>
          <div className="w-full flex justify-center">
            <input
              type="text"
              className="border border-pink-300 rounded-l-md text-sm p-2 md:w-80 text-gray-500"
              value={`https://zaplyn.link/cart/${productDetails.order_id}`}
              readOnly
            />
            <button
              className="bg-white px-2 border border-pink-300 rounded-r-md flex items-center space-x-1 text-gray-700 "
              onClick={() => {
                navigator.clipboard.writeText(
                  "https://zaplyn.link/cart/" + productDetails.order_id
                );
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                className="w-4 h-4"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z" />
              </svg>
              <span className="text-xs">Copy</span>
            </button>
          </div>
        </div>
      </Modal>

      {/* notemodal */}
      <Modal
        isOpen={isCustomerNoteModalOpen}
        className="font-lexand mt-10 max-w-xl z-50 mx-5 sm:mx-auto rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setIsCustomerNoteModalOpen(false)}
      >
        <div className="bg-white p-2 rounded-lg">
          <div className="flex flex-col items-center justify-center">
            <textarea
              name="internalnote"
              id="internalnote"
              className={`focus:outline-none p-2 shadow-sm border  border-gray-100 rounded-md text-xs h-20 w-full overflow-hidden `}
              type="Text"
              value={productDetails?.customer_note}
              required
              placeholder="Enter your note here"
              rows="1"
              readOnly
              maxLength={150}
            />
            <div className="flex gap-2 text-xs mt-2">
              <button
                onClick={setIsCustomerNoteModalOpenToFalse}
                className={`bg-gradient-to-b from-purple-200 to-pink-300  px-4 p-1 rounded-full shadow-md font-semibold  `}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Loading Modal*/}
      <Modal
        isOpen={isLoadingModalOpen}
        className="font-lexand  max-w-xl z-50 mx-5 sm:mx-auto rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setIsLoadingModalOpen(false)}
      >
        <div className=" p-6 rounded-md h-screen flex flex-col items-center justify-center">
          <div className="flex items-center justify-center space-x-2">
            <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
          </div>
        </div>
      </Modal>
    </div>

    // </div>
  );
}

export default OrderCards;
