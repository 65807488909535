import React, { useContext, useState } from "react";
import Endpoints from "../../api/endpoints";
import { GlobalContext } from "../GlobalContext";
import { useQueryClient } from "react-query";

function InternalNote({ propsnote, tileRef, shopRef, closeOnClick }) {
  const queryClient = useQueryClient();
  const [internalNote, setInternalNote] = useState(propsnote);
  const [isEditable, setIsEditable] = useState(false);

  //   cancel button func
  const handleCancelClick = () => {
    setInternalNote(propsnote);
    setIsEditable(false);
  };

  //   save button func
  const handleSubmit = async (e) => {
    e.preventDefault(); //prevents refreshing page
    // console.log(internalNote);
    const content = {
      ZaplynTileRef: tileRef || "0",
      ZaplynShopRef: shopRef || "0",
      note: internalNote,
    };

    const res = await Endpoints.EditInternalNote(content);
    if (res) {
      if (tileRef) {
        await queryClient.invalidateQueries(["productsData"]);
      } else {
        await queryClient.invalidateQueries("shopsData");
      }
      closeOnClick();
    }
  };

  return (
    <div>
      <div className="bg-white p-2 rounded-lg">
        <div className="flex flex-col items-center justify-center">
          <textarea
            name="internalnote"
            id="internalnote"
            className={`focus:outline-none p-2 shadow-md border  border-pink-300 rounded-md text-xs h-20 w-full overflow-hidden ${
              isEditable ? "bg-gray-100 " : "bg-gray-200 text-gray-600"
            }`}
            type="Text"
            value={internalNote}
            onChange={(e) => setInternalNote(e.target.value)}
            required
            placeholder="Add Note*"
            rows="1"
            readOnly={!isEditable}
            maxLength={150}
          />

          {!isEditable ? (
            <button
              onClick={() => setIsEditable(true)}
              className="py-1 mt-1 px-4 bg-gradient-to-b from-purple-300 to-pink-500 to-20% rounded-full text-xs font-semibold text-white shadow-md"
            >
              Edit
            </button>
          ) : (
            <div className="flex gap-2 mt-1 text-sm">
              <button
                onClick={handleCancelClick}
                className="px-4 p-1 rounded-full text-white hover:text-gray-700 bg-red-700 hover:bg-red-300"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 p-1 rounded-full text-white hover:text-gray-700 bg-green-700 hover:bg-green-300"
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InternalNote;
