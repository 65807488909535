import React from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getUsername from "../Account_Infomation/Username";
import getToken from "../Token";

function ReddemCoupon() {
  const [code, setCode] = useState("");
  const accesstoken = getToken();

  return (
    <div className=" mt-40 flex items-center justify-center  ">
      <ToastContainer autoClose={2500} />
      <div className="  bg-pink-300 rounded-lg ">
        <div className="container flex flex-col flex-wrap content-center justify-center p-4 py-20 mx-auto md:p-10">
          <p className="pt-2 pb-8 text-xl antialiased text-center text-gray-600">
            Enter your promo code here.
          </p>
          <div className="flex flex-row">
            <input
              type="text"
              placeholder="Enter..."
              className="w-3/5 p-3 rounded-l-lg sm:w-2/3 focus:outline-none"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <button
              type="button"
              className="w-2/5 p-3 font-semibold rounded-r-lg sm:w-1/3 focus:outline-none bg-pink-500 text-gray-900"
              onClick={() => {
                window.dataLayer.push({
                  coupon_id: code,
                  user_id: getUsername(),
                  event: "redeem_coupon",
                });

                fetch(
                  `https://omnistack.org${process.env.REACT_APP_LINK}` +
                    "use-zaplyn-promocode/",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accesstoken,
                    },
                    body: JSON.stringify({
                      user: getUsername(),
                      promocode: code,
                    }),
                  }
                )
                  .then((response) => response.json())
                  .then((json) =>
                    toast(
                      JSON.stringify(json["Notification_text"]).replace(
                        /"|'/g,
                        ""
                      )
                    )
                  );
                setCode("");
              }}
            >
              Claim
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReddemCoupon;
