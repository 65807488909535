import React from "react";

import { RadioGroup } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AttributeComponent({
  attributeName,
  attributeErr,
  selectedAttribute,
  setSelectedAttribute,
  attributeValues,
  availableAttribute,
}) {
  return (
    <div>
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium ">{attributeName}</h3>
          <p className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
            {attributeName === "size" && <span>Size guide</span>}
          </p>
        </div>
        {attributeErr && <p className="text-red-500 text-xxs">please Select</p>}

        <RadioGroup
          value={selectedAttribute}
          onChange={setSelectedAttribute}
          className="mt-4 text-xs"
        >
          <RadioGroup.Label className="sr-only">
            {" "}
            Choose a size{" "}
          </RadioGroup.Label>
          <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 md:grid-cols-4">
            {attributeValues?.split(",").map((size) => (
              <RadioGroup.Option
                key={size}
                value={size}
                disabled={!availableAttribute?.includes(size)}
                className={({ active }) =>
                  classNames(
                    availableAttribute?.includes(size)
                      ? "cursor-pointer bg-white text-gray-900 shadow-sm"
                      : "cursor-not-allowed bg-gray-50 text-gray-200",
                    active ? "ring-2 ring-indigo-500" : "",
                    "group relative flex items-center justify-center rounded-md border p-3  text-xs font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1"
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <RadioGroup.Label as="span">{size}</RadioGroup.Label>
                    {availableAttribute?.includes(size) ? (
                      <span
                        className={classNames(
                          active ? "border" : "border-2",
                          checked ? "border-indigo-500" : "border-transparent",
                          "pointer-events-none absolute -inset-px rounded-md"
                        )}
                        aria-hidden="true"
                      />
                    ) : (
                      <span
                        aria-hidden="true"
                        className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                      >
                        <svg
                          className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                          viewBox="0 0 100 100"
                          preserveAspectRatio="none"
                          stroke="currentColor"
                        >
                          <line
                            x1={0}
                            y1={100}
                            x2={100}
                            y2={0}
                            vectorEffect="non-scaling-stroke"
                          />
                        </svg>
                      </span>
                    )}
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}

export default AttributeComponent;
