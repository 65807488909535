import React, { createContext, useState,useEffect } from "react";
import getUsername from "./Account_Infomation/Username";
import { w3cwebsocket as W3CWebSocket } from "websocket";

export const PayContext = createContext();

export function PayProvider({ children }) {
  
    const [socket, setSocket] = useState([]);
    const [new_order_socket, setNew_order_socket] = useState([]);
    useEffect(() => {
      var ws_scheme =
        window.location.protocol == "https:"
          ? "wss://omnistack.org"
          : "wss://omnistack.org";
      const sock = new W3CWebSocket(ws_scheme + "/ws3/fetch-order-status/");
  
      sock.onopen = () => {
        // console.log("WebSocket Client Connected");
      };
  
      setSocket(sock);

      const new_order_sock = new W3CWebSocket(ws_scheme + "/ws3/fetch-new-order/");
      setNew_order_socket(new_order_sock)
    }, []);
  return (
    <PayContext.Provider
      value={{
        socket,
        new_order_socket,
      }}
    >
      {children}
    </PayContext.Provider>
  );
}
