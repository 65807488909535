import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../config/Api.json";
import getToken from "../../../components/Token";

const UnderProcess = () => {
  let { checkoutsession, tile } = useParams();
  console.log(checkoutsession);
  console.log(tile);
  let accesstoken = getToken();
  let navigate = useNavigate();

  fetch(`https://omnistack.org${process.env.REACT_APP_LINK}`+"create-payment-intent/" + checkoutsession + "/" + tile, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + accesstoken,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      // console.log();

      if (result.status === "succeeded") {
        window.location.href = "https://www.zaplyn-app.com/sucess";
        navigate("/sucess");
      }
      if (result.status === "processing") {
        window.location.href = "https://www.zaplyn-app.com/sucess";
        navigate("/sucess");
      }
    });

  return (
    <>
      <div className=" px-2  mx-2  rounded-lg  justify-items-center overflow-x-auto md:w-28 ">
        <div className="bg-gray-100 h-screen">
          <div className="bg-white p-6  md:mx-auto">
            <svg
              viewBox="0 0 24 24"
              className="text-green-600 w-16 h-16 mx-auto my-6"
            >
              {/* <path fill="currentColor"
                            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                        </path> */}
            </svg>
            <div className="text-center">
              <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                Payment Under Process....
              </h3>
              <p className="text-gray-600 my-2">
                Please do not refresh the payment is under process!
              </p>
              <div className="py-10 text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnderProcess;
