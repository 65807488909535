import React, { useEffect, useState } from "react";
import Endpoints from "../../../../api/endpoints";
import { useQuery } from "react-query";
import { Carousel } from "react-responsive-carousel";
import PreviewShopProductsComponent from "./PreviewShopProductsComponent";

import "../../sharePage/Theme.css";

function PreviewShopModal({ shopDetails }) {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("select");
  // theme
  const [theme, setTheme] = useState("");
  const [themBtn, setThemBtn] = useState("");
  const [themBtnStyle, setThemBtnStyle] = useState("");
  const [themeTileBorder, setThemeTileBorder] = useState("");
  const [themeTileBorderStyle, setThemeTileBorderStyle] = useState("");
  const [themeFont, setThemeFont] = useState("");
  const [themeView, setThemeView] = useState("");

  //fetch shop data
  const {
    isLoading: isShopLoading,
    data: shopData,
    isError: isShopError,
  } = useQuery("shopDetails", () =>
    Endpoints.FetchShopDetails(shopDetails.ZaplynShopRef)
  );

  //fetch shop products
  const {
    isLoading: isShopProductsLoading,
    data: shopProducts,
    isError: isShopProductsError,
  } = useQuery("shopProducts", () =>
    Endpoints.FetchShopProducts(shopDetails.ZaplynShopRef)
  );

  useEffect(() => {
    if (shopData) {
      setSelectedCategory(shopData.category.split(",")[0]);
      setTheme(shopData?.theme?.theme);
      setThemBtn(shopData?.theme?.themeBtn);
      setThemBtnStyle(shopData?.theme?.themeBtnStyle);
      setThemeTileBorder(shopData?.theme?.showBorder);
      setThemeTileBorderStyle(shopData?.theme?.tileStyle);
      setThemeFont(shopData?.theme?.font);
      setThemeView(shopData?.theme?.borderType);
    }
  }, [shopData]);
  // console.log(theme);
  // console.log(themBtn);
  // console.log(themBtnStyle);
  // console.log(themeTileBorder);
  // console.log(themeTileBorderStyle);
  // console.log(themeFont);
  // console.log(shopData);
  return (
    <div>
      {isShopProductsLoading || isShopLoading ? (
        <p className="text-center text-gray-400">Loading...</p>
      ) : (
        <div
          className={`flex flex-col max-w-3xl mx-auto p-2  rounded-lg ${themeFont} ${
            theme === "dark" ? "dark" : "bg-white"
          }
        `}
        >
          <div className="flex w-full ">
            <img
              src={shopData?.image}
              alt=""
              className="w-20 h-20 rounded-full "
            />
            <div className="ml-2 flex flex-grow flex-col cursor-pointer  ">
              <p className="text-2xl md:text-4xl">{shopData.shopname}</p>
              <p className="text-gray-600">{shopData.description}</p>
            </div>
          </div>
          <div className="flex flex-col max-w-xl mx-auto mt-10  ">
            {shopData?.category.split(",").length > 0 && (
              <ul
                className={`flex items-center  divide-x divide-gray-200 pl-2 pr-2 pb-3 w-80 md:w-full md:pl-0 overflow-x-auto ${
                  shopData?.category.split(",").length < 3
                    ? " justify-center"
                    : ""
                }`}
              >
                {shopData?.category.split(",").map((category, index) => (
                  <li key={index}>
                    <button
                      onClick={() => {
                        setSelectedCategory(category);
                        setSelectedSubCategory("select");
                      }}
                      className="px-2 "
                    >
                      {category === selectedCategory ? (
                        <p
                          className={` ${themBtn}  ${themBtnStyle} px-4 py-0.5 font-semibold text-sm text-gray-700 shadow-md`}
                        >
                          {category}
                        </p>
                      ) : (
                        <p className="font-semibold text-sm px-4 py-0.5">
                          {category}
                        </p>
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {selectedCategory !== "" && (
            <div className="">
              <PreviewShopProductsComponent
                theme={theme}
                themBtn={themBtn}
                themBtnStyle={themBtnStyle}
                themeTileBorder={themeTileBorder}
                themeTileBorderStyle={themeTileBorderStyle}
                themeFont={themeFont}
                themeView={themeView}
                selectedSubCategory={selectedSubCategory}
                setSelectedSubCategory={setSelectedSubCategory}
                selectedCategory={selectedCategory}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PreviewShopModal;
