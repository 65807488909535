import { useState, useRef, useEffect, useContext } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PostMessagePopup2 from "../../PostMessagePopup2";
import QRCode from "qrcode";
import Preview from "../../popups/Preview";
import InternalNote from "../../popups/InternalNote";
import Endpoints from "../../../api/endpoints";
import { GlobalContext } from "../../GlobalContext";
import toggleSvgOn from "../../../assets/icons/toggle-line.svg";
import toggleSvgOff from "../../../assets/icons/toggle-fill.svg";
import { useMutation, useQueryClient } from "react-query";
import ErrImage from "../../../assets/imageErr.jpg";
import EditShopModal from "./modal/EditShopModal";
import { createTileRef } from "../../../helper/helper";
import PreviewShopModal from "./modal/PreviewShopModal";

const ShopTile = ({ shopDetails }) => {
  const queryClient = useQueryClient();
  const { productImages, isShopsLoading, shopsData, isShopsError } =
    useContext(GlobalContext);

  const [settingsToggle, setSettingsToggle] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  const buttonRef = useRef(null);
  const settingsWrapperRef = useRef(null);
  const [popupPosition, setPopupPosition] = useState({});

  const [tileref, setTileref] = useState("");

  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);

  // Edit modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  // internal note modal
  const [internalNoteModalIsOpen, setInternalNoteModalIsOpen] = useState(false);
  const setInternalNoteModalIsOpenToTrue = () => {
    setInternalNoteModalIsOpen(true);
  };
  const setInternalNoteModalIsOpenToFalse = () => {
    setInternalNoteModalIsOpen(false);
  };

  // product preview modal
  const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);
  const setPreviewModalIsOpenToTrue = () => {
    setPreviewModalIsOpen(true);
  };
  const setPreviewModalIsOpenToFalse = () => {
    setPreviewModalIsOpen(false);
  };

  //link modal
  const [linkModalIsOpen, setLinkModalIsOpen] = useState(false);
  const setLinkModalIsOpenToTrue = () => {
    setLinkModalIsOpen(true);
  };
  const setLinkModalIsOpenToFalse = () => {
    setLinkModalIsOpen(false);
  };

  const handleShareCardClick = (tileref) => {
    QRCode.toDataURL("https://zaplyn.link/shop/" + tileref)
      .then((url) => {
        setQrCodeUrl(url);
      })
      .catch((err) => console.error(err));
  };

  const handleDownloadQrCode = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = qrCodeUrl;
    downloadLink.download = "qr-code.png";
    downloadLink.click();
  };

  // toggle shop share link
  const handleShareLinkStatus = async () => {
    await Endpoints.EditShopLink(shopDetails.ZaplynShopRef);
    await queryClient.invalidateQueries("shopsData");
  };

  // clone shop
  const cloneMutation = useMutation(Endpoints.CloneShop, {
    onSuccess: () => {
      console.log("Clone Successful");
    },
    onError: (err) => {
      console.log("there was an error", err);
    },
    onSettled: () => {
      setSettingsToggle(false);
      queryClient.invalidateQueries("shopsData");
    },
  });

  const handleClone = async () => {
    const newTileref = createTileRef();
    const content = {
      ZaplynShopRef: newTileref,
      shopname: "copy of " + shopDetails.shopname,
      author: shopDetails.author,
      link_enabled: shopDetails.link_enabled,
      currency: shopDetails.currency,
      created_at: shopDetails.created_at,
      enable_scheduling: shopDetails?.enable_scheduling,
      vat: shopDetails.VatTaxes,
      discount: shopDetails.discount,
      mobile: shopDetails.mobile,
      address: shopDetails.address,
      email: shopDetails.email,
      multiple_bookings: shopDetails.multiple_bookings,
      booking_time: shopDetails.booking_time,
      partial: shopDetails.partial,
      shipping_enabled: shopDetails.shipping_enabled,
      category: shopDetails.category.toString(),
      theme: Object.values(shopDetails.theme).toString(),
      ZaplynTileRef: shopDetails.ZaplynTileRef.toString(),
      description: shopDetails.description || "",
      image: shopDetails.image || "",
    };
    cloneMutation.mutate(content);
  };

  //shop delete
  const handleShopDelete = async (shopref) => {
    window.dataLayer.push({
      shop_id: shopref,
      shop_name: shopDetails?.shopname,
      event: "delete_shop",
    });
    const res = await Endpoints.DeleteShop(shopref);
    if (res) {
      await queryClient.invalidateQueries("shopsData");
      toast(res["Notification-text"]);
    }
  };

  // handling Settings Toggle
  useEffect(() => {
    if (settingsToggle && buttonRef.current) {
      const { top, bottom } = buttonRef.current.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const popupTop = top + scrollTop <= window.innerHeight / 2 ? "top-0" : "";
      const popupBottom = bottom >= window.innerHeight / 2 ? "bottom-0" : "";

      setPopupPosition(`absolute ${popupTop} ${popupBottom} right-8 `);
    }
  }, [settingsToggle]);

  // handle settings popup
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        settingsToggle &&
        buttonRef.current &&
        settingsWrapperRef.current &&
        !buttonRef.current.contains(event.target) &&
        !settingsWrapperRef.current.contains(event.target)
      ) {
        setSettingsToggle(false);
      }
    }

    function handleScroll() {
      if (settingsToggle) {
        setSettingsToggle(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [settingsToggle]);

  // if (cloneImgMutation.isLoading) {
  //   return (
  //     <div>
  //       <LoadingModal />
  //     </div>
  //   );
  // }

  return (
    <>
      {isShopsLoading ? (
        <>Loading...</>
      ) : (
        <div
          className="relative p-2 flex gap-2 m-2 border border-pink-300 rounded-lg
        "
        >
          <p className="absolute right-2 botton-1 text-xxs md:text-xs text-gray-500">
            {shopDetails.ZaplynShopRef}
          </p>
          <img
            src={shopDetails.image || ErrImage}
            alt=""
            className="h-full w-28 rounded-l-lg object-cover"
          />
          <div className="w-full  pt-2">
            <div className="flex w-full justify-between">
              <p className="text-sm md:text-lg">{shopDetails.shopname}</p>
            </div>
            <div className="flex gap-4 mt-4">
              {shopDetails.ZaplynTileRef[0] !== "" && (
                <p className="text-gray-700 text-xs md:text-sm">
                  {shopDetails.ZaplynTileRef.length}{" "}
                  {shopDetails.ZaplynTileRef.length > 1
                    ? "Products"
                    : "Product"}
                </p>
              )}
            </div>
            <div className="flex items-center justify-between md:mt-4 mt-6">
              {/* sharelink button */}
              <button
                className="flex justify-center gap-1 w-1/2 rounded-full bg-gradient-to-t from-pink-300 to-white px-4 py-1 font-semibold text-lg text-gray-700 shadow-md"
                onClick={() => {
                  setLinkModalIsOpenToTrue();
                  handleShareCardClick(shopDetails.ZaplynShopRef);
                  // toast("Sharing link copied!");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  className=""
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.06 8.11l1.415 1.415a7 7 0 0 1 0 9.9l-.354.353a7 7 0 0 1-9.9-9.9l1.415 1.415a5 5 0 1 0 7.071 7.071l.354-.354a5 5 0 0 0 0-7.07l-1.415-1.415 1.415-1.414zm6.718 6.011l-1.414-1.414a5 5 0 1 0-7.071-7.071l-.354.354a5 5 0 0 0 0 7.07l1.415 1.415-1.415 1.414-1.414-1.414a7 7 0 0 1 0-9.9l.354-.353a7 7 0 0 1 9.9 9.9z" />
                </svg>
                <p className="text-sm">Link</p>
              </button>

              {/* settings */}
              <div className="relative inline-block ">
                {/* settingsbutton */}
                <button
                  ref={buttonRef}
                  onClick={() => setSettingsToggle(!settingsToggle)}
                  className="relative block text-gray-700 rounded-md focus:ring-pink-300 dark:focus:ring-pink-300 focus:ring dark:bg-pink-800 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                  </svg>
                </button>

                {/* settings-popup */}
                {settingsToggle && (
                  <div
                    ref={settingsWrapperRef}
                    className={`  absolute z-50 ${popupPosition} right-0  w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl dark:bg-gray-800`}
                  >
                    <button
                      onClick={handleShareLinkStatus}
                      className="flex w-full items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {shopDetails.link_enabled ? (
                        <>
                          <img src={toggleSvgOff} className="h-6 mr-1" alt="" />
                          Turn Off
                        </>
                      ) : (
                        <>
                          <img src={toggleSvgOn} className="h-6  mr-1" alt="" />
                          Turn On
                        </>
                      )}
                    </button>

                    <button
                      onClick={() => setPreviewModalIsOpenToTrue()}
                      className="flex w-full items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                      </svg>

                      <span className="mx-1">Preview</span>
                    </button>

                    <button
                      onClick={() => {
                        setModalIsOpenToTrue();
                        // setTileref(message.ZaplynTileRef);
                      }}
                      className="flex w-full items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M5 19h1.414l9.314-9.314-1.414-1.414L5 17.586V19zm16 2H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L9.243 19H21v2zM15.728 6.858l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414z" />
                      </svg>

                      <span className="mx-1">Edit</span>
                    </button>

                    <button
                      onClick={setInternalNoteModalIsOpenToTrue}
                      className="relative flex w-full items-center cursor-pointer p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M21 15l-6 5.996L4.002 21A.998.998 0 0 1 3 20.007V3.993C3 3.445 3.445 3 3.993 3h16.014c.548 0 .993.456.993 1.002V15zM19 5H5v14h8v-5a1 1 0 0 1 .883-.993L14 13l5-.001V5zm-.829 9.999L15 15v3.169l3.171-3.17z" />
                      </svg>

                      <span className="mx-1">Internal Note</span>
                    </button>

                    <button
                      onClick={handleClone}
                      className="flex w-full items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z" />
                      </svg>

                      <span className="mx-1">Clone</span>
                    </button>

                    <button
                      onClick={() =>
                        handleShopDelete(shopDetails.ZaplynShopRef)
                      }
                      className="flex w-full items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M4 8h16v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8zm2 2v10h12V10H6zm3 2h2v6H9v-6zm4 0h2v6h-2v-6zM7 5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2h5v2H2V5h5zm2-1v1h6V4H9z" />
                      </svg>

                      <span className="mx-1">Delete</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Preview modal */}
      <Modal
        isOpen={previewModalIsOpen}
        className=" max-w-2xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setPreviewModalIsOpen(false)}
      >
        <button
          onClick={setPreviewModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-pink-400 rounded-md text-sm font-semibold flex-shrink"
        >
          x
        </button>
        <PreviewShopModal shopDetails={shopDetails} />
      </Modal>

      {/* share link modal */}
      <Modal
        isOpen={linkModalIsOpen}
        className="font-lexand  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setLinkModalIsOpen(false)}
      >
        <button
          onClick={setLinkModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-pink-400 rounded-md text-sm font-semibold flex-shrink"
        >
          x
        </button>
        <div className="bg-white  p-6 rounded-md flex flex-col items-center">
          <div className="relative">
            <img src={qrCodeUrl} alt="QR code" className="w-72 h-72" />
            <button
              title="Download QR"
              className="absolute bottom-5 -right-3 shadow-md bg-pink-300 rounded-b-md rounded-l-md"
              onClick={handleDownloadQrCode}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="h-8 w-8"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
              </svg>
            </button>
          </div>
          <div className="w-full flex justify-center">
            <input
              type="text"
              className="border border-pink-300 rounded-l-md text-sm p-2 md:w-80 text-gray-500"
              value={`https://zaplyn.link/shop/${shopDetails.ZaplynShopRef}`}
              readOnly
            />
            <button
              className=" px-2 border border-pink-300 rounded-r-md flex active:bg-pink-300 transition duration-200 ease-in-out items-center space-x-1 text-gray-700 focus:outline-none"
              onClick={() => {
                window.dataLayer.push({
                  shop_id: shopDetails.ZaplynShopRef,
                  shop_name: shopDetails.shopname,
                  product_id: "null",
                  product_name: "null",
                  event: "share_click",
                });
                navigator.clipboard.writeText(
                  "https://zaplyn.link/shop/" + shopDetails.ZaplynShopRef
                );
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                className="w-4 h-4"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z" />
              </svg>
              <span className="text-xs">Copy</span>
            </button>
          </div>
        </div>
      </Modal>

      {/* Edit Shop modal */}
      <Modal
        isOpen={modalIsOpen}
        className="font-lexand max-w-2xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setModalIsOpen(false)}
      >
        <button
          onClick={setModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-lg focus:outline-none cursor-pointer bg-pink-300 rounded-lg text-sm font-semibold flex-shrink"
        >
          x
        </button>
        <EditShopModal
          closeOnClick={setModalIsOpenToFalse}
          shopData={shopDetails}
          //   images={images}
        />
      </Modal>

      {/* Internal note Modal */}
      <Modal
        isOpen={internalNoteModalIsOpen}
        className="font-lexand max-w-md z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setInternalNoteModalIsOpen(false)}
      >
        <button
          onClick={setInternalNoteModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-lg focus:outline-none cursor-pointer bg-pink-300 rounded-lg text-sm font-semibold flex-shrink"
        >
          x
        </button>

        <InternalNote
          propsnote={shopDetails.note}
          shopRef={shopDetails.ZaplynShopRef}
          closeOnClick={setInternalNoteModalIsOpenToFalse}
        />
      </Modal>
      {/* Loading Modal*/}
      <Modal
        isOpen={isLoadingModalOpen}
        className="font-lexand  max-w-xl z-50 mx-5 sm:mx-auto rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setIsLoadingModalOpen(false)}
      >
        <div className=" p-6 rounded-md h-screen flex flex-col items-center justify-center">
          <div className="flex items-center justify-center space-x-2">
            <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShopTile;
