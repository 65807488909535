import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import getEmail from "../Account_Infomation/RegEmail";
import getReferralCode from "../Account_Infomation/referralCode";
import getUsername from "../Account_Infomation/Username";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import ChangePassword from "./ChangePassword";

import getToken from "../Token";
import { GlobalContext } from "../GlobalContext";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import PaymentMethods from "../payment-methods";

const Your_Account = () => {
  const queryClient = useQueryClient();
  const customStyles = {
    overlay: {
      background: "rgba(52, 52, 52, 0.8)",
      overflow: "scroll",
      align: "center",
    },

    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFFFFF",
      marginTop: "15%",
    },
  };

  const [showUpgradeTile, setShowUpgradeTile] = useState(false);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  const { userData, userAvatar, setUserAvatar, userAvatarArray } =
    useContext(GlobalContext);
  const [selectedAvatar, setSelectedAvatar] = useState(userAvatar);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  // avatar modal
  const [avatarModalIsOpen, setAvatarModalIsOpen] = useState(false);
  const setAvatarModalIsOpenToTrue = () => {
    setAvatarModalIsOpen(true);
  };

  const setAvatarModalIsOpenToFalse = () => {
    setAvatarModalIsOpen(false);
  };

  const handleAvatarChange = async () => {
    const accesskeytoken = getToken();
    const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
    const response = await fetch(link + "set-user-data/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({
        user: userData.username,
        logo: selectedAvatar,
      }),
    });

    const json = await response.json();

    if (json) {
      toast(json["Notification-text"]);
      await queryClient.invalidateQueries("userDetails");
    }
  };

  const handleSubscribePremium = async () => {
    const accesstoken = getToken();
    const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
    const username = getUsername();
    window.dataLayer.push({
      user_id: username,
      event: "subscription",
    });
    try {
      setSubscriptionLoading(true);
      const response = await fetch(link + "premium-purchase/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          user: username,
        }),
      });

      const json = await response.json();

      if (json) {
        toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
        await queryClient.invalidateQueries("userDetails");
        setShowUpgradeTile(false);
      }
    } catch (error) {
      console.log("error", error);
    }
    setSubscriptionLoading(false);
  };

  const handleRenewSubscription = async () => {
    const accesstoken = getToken();
    const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
    const username = getUsername();
    try {
      const response = await fetch(link + "renew-premium/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          user: username,
        }),
      });

      const json = await response.json();

      if (json) {
        toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
        await queryClient.invalidateQueries("userDetails");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCancelSubscription = async () => {
    const accesstoken = getToken();
    const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
    const username = getUsername();
    try {
      const response = await fetch(link + "cancel-premium/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          user: username,
        }),
      });

      const json = await response.json();

      if (json) {
        toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
        await queryClient.invalidateQueries("userDetails");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  Auth.currentAuthenticatedUser()
    .then((user) => {
      return Auth.changePassword(user, "oldPassword", "newPassword");
    })
    .then((data) => console.log())
    .catch((err) => console.log());
  // Auth.currentAuthenticatedUser()
  //   .then((user) => {
  //     return Auth.changePassword(user, "oldPassword", "newPassword");
  //   })
  //   .then((data) => console.log(data))
  //   .catch((err) => console.log(err));

  useEffect(() => {
    async function invalidateUserDetails() {
      await queryClient.invalidateQueries("userDetails");
    }

    invalidateUserDetails();
  }, []);

  return (
    <div className=" pt-24 min-h-screen pb-12 ">
      <ToastContainer autoClose={3000} />
      <div className=" ">
        <div className="title-container text-center block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2">
          <h1>Your Account Details</h1>
        </div>

        <div className="max-w-lg p-2 mx-auto  text-gray-900 ">
          <div className="sm:flex sm:space-x-2  ">
            <div className="flex flex-col items-center justify-center w-full sm:h-32 mb-6 sm:mb-0">
              <img
                src={
                  userAvatar ||
                  "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_placeholder.svg"
                }
                alt=""
                className="object-cover object-center w-32  rounded-full bg-gray-500"
              />
              <p
                className="text-center cursor-pointer bg-gradient-to-b from-purple-500 to-pink-500 to-20%  text-transparent bg-clip-text"
                onClick={setAvatarModalIsOpenToTrue}
              >
                {" "}
                Edit
              </p>
            </div>
            <div className="flex flex-col space-y-4 w-full">
              <div className=" flex items-center justify-between gap-4">
                <div>
                  <h2 className="text-2xl font-semibold">
                    {userData && userData?.username?.startsWith("google")
                      ? userData?.name
                      : userData?.username}
                  </h2>
                  {userData?.premium ? (
                    <span className="text-xs text-green-400">Premium User</span>
                  ) : (
                    <span className="text-xs text-blue-400">Free User</span>
                  )}
                </div>
              </div>
              <div className="space-y-1">
                <span className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    aria-label="Email address"
                    className="w-4 h-4"
                  >
                    <path
                      fill="currentColor"
                      d="M274.6,25.623a32.006,32.006,0,0,0-37.2,0L16,183.766V496H496V183.766ZM464,402.693,339.97,322.96,464,226.492ZM256,51.662,454.429,193.4,311.434,304.615,256,268.979l-55.434,35.636L57.571,193.4ZM48,226.492,172.03,322.96,48,402.693ZM464,464H48V440.735L256,307.021,464,440.735Z"
                    ></path>
                  </svg>
                  <span className="text-gray-500">{userData?.email}</span>
                </span>
                <span className="flex items-center space-x-2">
                  <span className="text-gray-500">Referral Code: </span>
                  <span>{userData?.["custom:ReferralCode"]}</span>
                </span>
              </div>
            </div>
          </div>
          <div className=" flex items-center justify-around mt-2 gap-5 w-full">
            {/* <Link to="/home">
              <button className="flex justify-center gap-1 py-1 px-4 bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full text-xs font-semibold text-gray-600 shadow-md  my-5">
                Back
              </button>
            </Link> */}
            <button
              className="py-1 px-4 bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full text-xs font-semibold text-gray-600 shadow-md "
              onClick={setModalIsOpenToTrue}
            >
              Change Password
            </button>

            {userData?.premium &&
              (userData.expiry_date == "1111-11-11" ||
                userData.expiry_date == "") && (
                <button
                  onClick={handleCancelSubscription}
                  className="text-xs font-bold bg-gradient-to-br from-purple-300 to-pink-500 text-transparent bg-clip-text rounded-xl shadow-md px-4 p-2"
                >
                  Cancel Subscription
                </button>
              )}
            {userData?.premium &&
              userData.cancelled &&
              userData.expiry_date !== "1111-11-11" && (
                <button
                  onClick={handleRenewSubscription}
                  className="text-xs font-bold bg-gradient-to-br from-purple-300 to-pink-500 text-transparent bg-clip-text rounded-xl shadow-md px-4 p-2"
                >
                  Renew Subscription
                </button>
              )}
          </div>
          {userData?.premium && userData?.purchase_date && (
            <p className="text-xs my-4 mt-6 flex justify-center">
              Purchase Date: {userData?.purchase_date}
            </p>
          )}

          {userData?.premium &&
            (userData?.expiry_date == "1111-11-11" ||
              userData?.expiry_date == "") && (
              <p className="text-xs my-4  flex justify-center">
                Next Billing Date: {userData?.next_billing_date}
              </p>
            )}
          {userData?.premium &&
            userData?.cancelled &&
            userData?.expiry_date !== "1111-11-11" && (
              <p className="text-xs my-4  flex justify-center">
                Expiry Date: {userData?.expiry_date}
              </p>
            )}
        </div>
      </div>

      {userData?.defaultExist === "No" && showUpgradeTile && (
        <div className="py-12 max-w-md md:mx-auto px-3   text-gray-800">
          <ul className="list-disc">
            You haven't added any payment method yet. <br />
            <li className="text-sm ml-8">Add a payment method</li>
            <li className="text-sm ml-8">And Set it to default to Continue!</li>
          </ul>
          <Link to="/payments">
            <button className="mt-4 text-xs md:text-sm lg:text-lg font-bold bg-gradient-to-br from-purple-300 to-pink-500 text-transparent bg-clip-text rounded-xl shadow-md px-6 p-2">
              Add Now
            </button>
          </Link>
        </div>
      )}

      <PaymentMethods />

      {!userData?.premium && (
        <div className="py-5 font-lexand  text-gray-800">
          <div className="container px-4 mx-auto">
            <div className="max-w-2xl mx-auto mb-5 text-center">
              <span className="font-bold tracking-wider uppercase text-violet-400">
                Pricing
              </span>
              <h2 className="text-4xl font-bold lg:text-5xl">
                Choose your best plan
              </h2>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center p-1">
              <div className="flex p-1 w-full mb-2 sm:px-4 md:justify-end  lg:mb-0">
                <div className="flex flex-col items-center  w-full lg:w-3/4 p-6  border border-pink-300  space-y-6 rounded-lg shadow   sm:p-8 ">
                  <div className="space-y-2">
                    <h4 className="text-2xl font-bold">Free</h4>
                    <span className="text-6xl font-bold">Free</span>
                  </div>
                  {/* <p className="mt-3 leading-relaxed text-gray-400">
                      Etiam ac convallis enim, eget euismod dolor.
                    </p> */}
                  <ul className="flex-1 mb-6 text-gray-400">
                    <li className="flex mb-2 space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#70ab77"
                        className="flex-shrink-0 w-6 h-6 text-violet-400"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Upto 5 products</span>
                    </li>
                    <li className="flex mb-2 space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#70ab77"
                        className="flex-shrink-0 w-6 h-6 text-violet-400"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Unlimited Orders</span>
                    </li>
                    <li className="flex mb-2 space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#70ab77"
                        className="flex-shrink-0 w-6 h-6 text-violet-400"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Zaplyn fees: 2.5% + 20p</span>
                    </li>
                  </ul>
                  <button className="mt-4 inline-block w-full py-3 font-bold tracking-wider text-center text-gray-400 rounded-xl shadow-md px-6">
                    Active
                  </button>
                </div>
              </div>
              <div className="flex  p-1 w-full mb-2 sm:px-4   lg:mb-0">
                <div className="flex flex-col items-center border border-pink-300   w-full lg:w-3/4  p-6 space-y-6 rounded-lg shadow sm:p-8   text-gray-900">
                  <div className="space-y-2">
                    <h4 className="text-2xl font-bold">Premium</h4>
                    <span className="text-4xl font-bold">
                      £5.99
                      <span className="text-sm tracking-wide">/month</span>
                    </span>
                  </div>
                  {/* <p className="leading-relaxed">
                      Morbi cursus ut sapien sit amet consectetur.
                    </p> */}
                  <ul className="flex-1 space-y-2">
                    <li className="flex items-center space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#19d732"
                        className="flex-shrink-0 w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Everything in Free</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#19d732"
                        className="flex-shrink-0 w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Unlimited products</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#19d732"
                        className="flex-shrink-0 w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Zaplyn Fees: 2.5% + 20p </span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#19d732"
                        className="flex-shrink-0 w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Unlimited Shops</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#19d732"
                        className="flex-shrink-0 w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Customer support 24/7</span>
                    </li>
                  </ul>
                  {!userData?.premium && userData?.defaultExist === "No" && (
                    <button
                      onClick={() => setShowUpgradeTile(true)}
                      className="mt-4 inline-block w-full py-3 font-bold tracking-wider text-center bg-gradient-to-br from-purple-300 to-pink-500 text-transparent bg-clip-text rounded-xl shadow-md px-6"
                    >
                      Upgrade Now!
                    </button>
                  )}
                  {!userData?.premium && userData?.defaultExist === "Yes" && (
                    <button
                      onClick={handleSubscribePremium}
                      className="mt-4 inline-block w-full py-3 font-bold tracking-wider text-center bg-gradient-to-br from-purple-300 to-pink-500 text-transparent bg-clip-text rounded-xl shadow-md px-6"
                    >
                      {subscriptionLoading ? (
                        <div className="flex items-center justify-between w-8 h-8 border-4 border-pink-400 border-dashed rounded-full animate-spin "></div>
                      ) : (
                        "Get Started"
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {userData?.premium && (
        <div className="flex items-center justify-center p-1 max-w-sm mx-auto sm:px-4 mt-5">
          <div className="flex flex-col items-center border border-pink-300   p-6 space-y-6 rounded-lg shadow sm:p-8   text-gray-900">
            <div className="space-y-2">
              <h4 className="text-2xl font-bold">Premium</h4>
              <span className="text-4xl font-bold">
                £5.99
                <span className="text-sm tracking-wide">/month</span>
              </span>
            </div>
            {/* <p className="leading-relaxed">
                      Morbi cursus ut sapien sit amet consectetur.
                    </p> */}
            <ul className="flex-1 space-y-2">
              <li className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#19d732"
                  className="flex-shrink-0 w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Everything in Free</span>
              </li>
              <li className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#19d732"
                  className="flex-shrink-0 w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Unlimited products</span>
              </li>
              <li className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#19d732"
                  className="flex-shrink-0 w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Zaplyn Fees: 2.5% + 20p </span>
              </li>
              <li className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#19d732"
                  className="flex-shrink-0 w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Unlimited Shops</span>
              </li>
              <li className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#19d732"
                  className="flex-shrink-0 w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Customer support 24/7</span>
              </li>
            </ul>
            <div className="flex items-center space-x-2">
              <p className="font-bold">Subscribed</p>
            </div>
          </div>
        </div>
      )}

      {/* password modal */}
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <button
          onClick={setModalIsOpenToFalse}
          className=" px-2 pb-1 button rounded-lg shadow-lg focus:outline-none cursor-pointer hover:bg-red-800 hover:text-white bg-yellow-400 text-sm font-semibold flex-shrink"
        >
          x
        </button>
        <ChangePassword onClick={setModalIsOpenToFalse} />
      </Modal>

      {/* avatar modal */}
      <Modal
        isOpen={avatarModalIsOpen}
        className=" max-w-md z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setAvatarModalIsOpen(false)}
      >
        <button
          onClick={setAvatarModalIsOpenToFalse}
          className="flex justify-start mb-1  px-2 pb-1 button rounded-lg shadow-lg focus:outline-none cursor-pointer hover:bg-red-800 hover:text-white bg-yellow-400 text-sm font-semibold flex-shrink"
        >
          x
        </button>
        <div className="bg-white p-5 rounded-xl">
          <div className="grid grid-cols-5 gap-2 mb-2 justify-items-center">
            {userAvatarArray.map((item) => (
              <img
                key={item.id}
                onClick={() => setSelectedAvatar(item.url)}
                src={item.url}
                alt=""
                className={`w-16 h-16 rounded-full cursor-pointer p-1 ${
                  selectedAvatar === item.url ? "opacity-30" : ""
                }`}
              />
            ))}
          </div>

          <div className="flex items-center justify-center space-x-4">
            <button
              onClick={() => {
                setAvatarModalIsOpen(false);
                setSelectedAvatar(userAvatar);
              }}
              className=" rounded-full bg-gradient-to-b from-purple-200 to-pink-300 w-24 font-semibold text-md text-gray-700 my-1 shadow-md "
            >
              cancel
            </button>
            <button
              onClick={() => {
                handleAvatarChange();
                setUserAvatar(selectedAvatar);

                setAvatarModalIsOpenToFalse();
              }}
              className=" rounded-full bg-gradient-to-b from-purple-200 to-pink-300 w-24 font-semibold text-md text-gray-700 my-1 shadow-md "
            >
              save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Your_Account;
