import React from "react";
import Support from "../Support/Support";

export default function Support_Page() {
  return (
    <div className="">
      <body>
        <div class="content-wrapper" className="flex justify-center pt-32">
          <div class="questions-wrapper py-10">
            <div class="questions">
              <h2 class="block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2">
                How can we help you?
              </h2>
              <p>If you have any questions or need assistance, please</p>
              <p>
                tell us by filling out the form below and we will get back to
                you!
              </p>
            </div>
          </div>
        </div>
        {/* <section> */}
        <div class="contact-box flex justify-center m-3 pb-10">
          <Support />
        </div>
        {/* </section> */}
      </body>
    </div>
  );
}
