import React, { useContext, useEffect, useMemo, useState } from "react";
import ProductTile from "./ProductTile";
import { useQueryClient } from "react-query";
import { ThemeContext } from "../../ThemeContext";

function ProductsComponent({
  onAddToCart,
  selectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
}) {
  const queryClient = useQueryClient();
  const shopDetails = queryClient.getQueryData("shopDetails");
  const productCategories = queryClient.getQueryData("categoryData");
  const products = queryClient.getQueryData("shopProducts");

  const [modProducts, setModProducts] = useState(null);

  // theme
  const { theme, themBtn, themBtnStyle, themeView, setThemeView } =
    useContext(ThemeContext);

  useEffect(() => {
    const data = Object.values(products)?.filter(
      (item) => item.category === selectedCategory
    );
    setModProducts(data);
  }, [products, selectedCategory]);

  const subCategories = useMemo(() => {
    if (products) {
      return Object.values(products)
        ?.filter((item) => item.category === selectedCategory)
        .map((item) => item.sub_category);
    }
  }, [products, selectedCategory]);

  const uniqueSubCategories = useMemo(() => {
    return [...new Set(subCategories)];
  }, [subCategories]);

  // Find the index of 'select' in the array
  const selectIndex = uniqueSubCategories.indexOf("select");

  // Split the array into two parts
  const beforeSelect = uniqueSubCategories.slice(0, selectIndex);
  const afterSelect = uniqueSubCategories.slice(selectIndex + 1);

  return (
    <div className="p-1 flex flex-col w-full mx-auto">
      <div className="mb-5">
        <div className="">
          {uniqueSubCategories.includes("select") ? (
            <ul className="flex  space-y-2 items-center justify-center flex-wrap  divide-x divide-gray-200 overflow-hidden  ">
              <li>
                <p
                  onClick={() => setSelectedSubCategory("select")}
                  className={` cursor-pointer ${themBtnStyle} capitalize   px-4 py-0.5 font-semibold text-xs text-gray-700
              mx-1  ${selectedSubCategory === "select" ? `${theme} ` : ""}}`}
                >
                  All
                </p>
              </li>
              {beforeSelect.map((item) => (
                <li key={item}>
                  <p
                    onClick={() => setSelectedSubCategory(item)}
                    className={` cursor-pointer ${themBtnStyle} capitalize   px-4 py-0.5 font-semibold text-xs text-gray-700
              mx-1  ${selectedSubCategory === item ? `${theme} ` : ""}}`}
                  >
                    {item}
                  </p>
                </li>
              ))}
              {afterSelect.map((item) => (
                <li key={item}>
                  <p
                    onClick={() => setSelectedSubCategory(item)}
                    className={` cursor-pointer ${themBtnStyle} capitalize   px-4 py-0.5 font-semibold text-xs text-gray-700
              mx-1  ${selectedSubCategory === item ? `${theme} ` : ""}}`}
                  >
                    {item}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <ul className="flex  space-y-2 items-center justify-center flex-wrap  divide-x divide-gray-200 overflow-hidden  ">
              {uniqueSubCategories.map((item) => (
                <li key={item}>
                  <p
                    onClick={() => setSelectedSubCategory(item)}
                    className={` cursor-pointer ${themBtnStyle} capitalize   px-4 py-0.5 font-semibold text-xs text-gray-700
              mx-1  ${selectedSubCategory === item ? `${theme} ` : ""}}`}
                  >
                    {item}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div
        className={`${
          themeView === "grid"
            ? "grid md:grid-cols-2 max-w-xl mx-auto "
            : "flex flex-col md:mx-auto "
        }  mt-2  gap-5 `}
      >
        {selectedSubCategory === "select"
          ? modProducts?.map((item) => (
              <ProductTile
                shopDetails={shopDetails}
                productCategories={productCategories}
                productDetails={item}
                onAddToCart={onAddToCart}
              />
            ))
          : modProducts
              ?.filter((item) => item.sub_category === selectedSubCategory)
              .map((item) => (
                <ProductTile
                  shopDetails={shopDetails}
                  productCategories={productCategories}
                  productDetails={item}
                  onAddToCart={onAddToCart}
                />
              ))}
      </div>
    </div>
  );
}

export default ProductsComponent;
