import getUsername from "../components/Account_Infomation/Username";

const username = getUsername();
const alphabet = "abcdefghijklmnopqrstuvwxyz";

export function createTileRef() {
  var part1 = username.substring(0, 3);
  var part2 = Math.floor(Math.random() * (999 - 100 + 1) + 100);
  var part3 =
    alphabet[Math.floor(Math.random() * alphabet.length)] +
    alphabet[Math.floor(Math.random() * alphabet.length)] +
    alphabet[Math.floor(Math.random() * alphabet.length)];

  var tmp = part1 + part2 + part3;
  // console.log(tmp);

  return tmp;
}

export function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function convertCurrency(fromCurr, toCurr) {
  const exchangeRates = {
    USD: 1.0,
    GBP: 0.8,
    EUR: 0.9,
  };
  return exchangeRates[toCurr] / exchangeRates[fromCurr];
}
