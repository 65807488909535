import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { GlobalContext } from "../../GlobalContext.js";
import useSubject from "../../Subject";
import getToken from "../../Token.js";

const FilterCards = ({ setIsFilterOpen }) => {
  const {
    setShowFilteredData,
    selectedHomeFilterOptions,
    setSelectedHomeFilterOptions,
    setSelectedButtonFilters,
  } = useContext(GlobalContext);
  const [options, setOptions] = useState([]);

  let accesstoken = getToken();

  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const { data: subjectlist } = useSubject(link + "subject-list/", accesstoken);

  const [newselectedOptions, setNewSelectedOptions] = useState([]);

  useEffect(() => {
    if (subjectlist) {
      const data = Object.keys(subjectlist);
      // console.log(data);
      setOptions(data?.slice(1));
    }
    // console.log("fetched topic result ", subjectlist);
  }, [subjectlist]);

  const handleOptionChange = (selectedOptions) => {
    setNewSelectedOptions(selectedOptions);
    // console.log("Selectedoption", selectedOptions);
    if (selectedOptions.length === 0) {
      setShowFilteredData(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Filter the data based on selected options and update the global state with the filtered data
    setSelectedHomeFilterOptions(newselectedOptions);
    setShowFilteredData(true);
    setSelectedButtonFilters([]);
  };

  const handleReset = () => {
    setShowFilteredData(false);
    setSelectedHomeFilterOptions(null);
    setIsFilterOpen(false);
  };

  //custom message for unavailable opton
  const customNoOptionsMessage = () => {
    return (
      <p>
        Can't find the Category you are looking for? Write to us{" "}
        <Link to="/support" className="text-pink-800 font-bold underline">
          here.
        </Link>
      </p>
    );
  };
  // console.log(subjectlist);

  return (
    <div className="">
      <div className="absolute right-10 top-0 z-10 bg-white border border-pink-300 flex flex-col justify-between px-4 leading-tight  rounded-md p-2 text-xs ">
        <form onSubmit={handleSubmit} className=" flex flex-row ">
          <div className="md:w-52 md:mr-4">
            <p className=" text-xs ml-1 mb-1">Category</p>
            <Select
              className="w-44"
              value={newselectedOptions}
              onChange={handleOptionChange}
              options={options}
              placeholder={"select"}
              isMulti
              closeMenuOnSelect={false}
              autosize={false}
              noOptionsMessage={customNoOptionsMessage}
              getOptionValue={(option) => option} //value is what it is
              getOptionLabel={(option) => option} //label is what you see
            />
          </div>
          <button
            type="submit"
            className="py-1 mt-6 px-4 rounded-full bg-gradient-to-b from-purple-200 to-pink-300 to-20%  transform active:from-pink-300 active:to-purple-300 active:scale-95 focus:outline-none text-sm text-gray-600 shadow-md h-8 w-20"
          >
            Search
          </button>
        </form>

        <button
          className="py-1 px-4 bg-gradient-to-b from-purple-200 to-pink-300 to-20%  transform active:from-pink-300 active:to-purple-300 active:scale-95 focus:outline-none rounded-full text-sm text-gray-600 shadow-md w-20 mt-2"
          onClick={handleReset}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default FilterCards;
