import React, { useContext, useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { convertCurrency } from "../../../helper/helper";
import { ThemeContext } from "../../ThemeContext";
import { Discovery } from "aws-sdk";
import Modal from "react-modal";
import { PayContext } from "../../PayContext";
import QRCode from "qrcode";
const ShopCheckoutForm = ({
  orderData,
  shopDetails,
  schedule_delivery,
  partial,
  customerNote,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const {
    theme,
    setTheme,
    themBtn,
    setThemBtn,
    themBtnStyle,
    setThemBtnStyle,
    themeTileBorder,
    setThemeTileBorder,
    themeTileBorderStyle,
    setThemeTileBorderStyle,
    themeFont,
    setThemeFont,
  } = useContext(ThemeContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postcode, setPostcode] = useState("");
  const { socket } = useContext(PayContext);
  const [price, setPrice] = useState([]);
  const [isStripeLoading, setIsStripLoading] = useState(true);

  const [receivedOrid, setReceivedOrId] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const validateEmail = () => {
    setIsEmailValid(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) // regex for email validation
    );
  };

  const validatePhone = () => {
    setIsPhoneValid(
      /^[\d\s()+-]+$/.test(phone) && phone.length >= 10 // regex for phone validation
    );
  };

  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [linkModalIsOpen, setLinkModalIsOpen] = useState(false);
  const setLinkModalIsOpenToTrue = () => {
    setLinkModalIsOpen(true);
  };
  const setLinkModalIsOpenToFalse = () => {
    setLinkModalIsOpen(false);
  };

  const handleShareCardClick = (order_id) => {
    QRCode.toDataURL("https://zaplyn.link/pay/" + order_id)
      .then((url) => {
        setQrCodeUrl(url);
      })
      .catch((err) => console.error(err));
  };

  const handleDownloadQrCode = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = qrCodeUrl;
    downloadLink.download = "qr-code.png";
    downloadLink.click();
  };

  const handleAddress = (e) => {
    e.preventDefault();
    validateEmail();
    validatePhone();

    const datanew = orderData.addon.split("-").map((item) => JSON.parse(item));
    var convertedObject = datanew.reduce((result, obj) => {
      Object.keys(obj).forEach((key) => {
        result[key] = obj[key];
      });
      return result;
    }, {});

    if (isEmailValid && isPhoneValid) {
      fetch(`https://omnistack.org${process.env.REACT_APP_LINK}`+"send-address/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ZaplynTileRef: orderData.ZaplynTileRef,
          shopname: shopDetails.shopname,
          shopref: shopDetails.ZaplynShopRef,
          itemname: orderData.itemname,
          category: orderData.category,
          size: orderData.attribute_1 || "",
          color: orderData.color || "",
          sub_category: orderData.sub_category,
          name: name,
          phone: phone,
          email: email,
          add1: add1,
          add2: add2,
          city: city,
          partial: partial,
          scheduled_time: schedule_delivery || "",
          country: country,
          postcode: postcode,
          customerNote: customerNote,
          price: orderData.newprice,
          qty: orderData.quantity,
          variant: orderData.variantName,
          add_ons: convertedObject,
          attribute_1: orderData.newatrribute_1,
          attribute_2: orderData.newatrribute_2,
          attribute_3: orderData.newatrribute_3,
          attribute_4: orderData.newatrribute_4,
          attribute_5: orderData.newatrribute_5,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          setReceivedOrId(json.Order_id);
          setIsVisible(false);
        });
    } else {
      console.log("invalid credentials");
    }
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url:
          "https://www.zaplyn.link/sucess?" +
          receivedOrid +
          shopDetails.ZaplynShopRef +
          orderData.quantity.split(",")[0],
      },
    });
    console.log();

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  socket.onmessage = (event) => {
    console.log(JSON.parse(event.data)["id"]);
    if (JSON.parse(event.data)["order_id"] === receivedOrid) {
      if (JSON.parse(event.data)["payment_status"] !== "in-progress") {
        window.location.href =
          "https://www.zaplyn.link/sucess?" +
          receivedOrid +
          shopDetails.ZaplynShopRef +
          orderData.quantity.split(",")[0];
      }
    }
  };
  // console.log(orderData);
  useEffect(() => {
    if (elements !== null && !isVisible) {
      const element = elements.getElement("payment");
      element.on("ready", () => {
        setIsStripLoading(false);
      });
    }
  }, [elements, isVisible]);

  useEffect(() => {
    if (receivedOrid) {
      handleShareCardClick(receivedOrid);
    }
  }, [receivedOrid]);

  return (
    <section className="mt-1 flex items-center justify-center max-w-2xl mx-auto ">
      <div className="w-full">
        {isVisible ? (
          <div
            className={`${
              themeTileBorder === "none"
                ? ``
                : themeTileBorder === "light"
                ? ` ${theme}  ${themeTileBorderStyle} shadow-sm p-0.25`
                : ` ${theme}  ${themeTileBorderStyle} shadow-sm p-0.5`
            }  mt-5`}
          >
            <form
              id="address-form"
              onSubmit={handleAddress}
              className={`${themeTileBorderStyle} bg-white  p-2`}
            >
              <div className=" ">
                <p className="text-gray-400 text-xl">
                  Please enter address details:
                </p>

                <div className="container flex flex-col mx-auto space-y-12">
                  <div className="grid grid-cols-6 gap-4 col-span-full ">
                    <div className="col-span-full ">
                      <label name="f-name">Name: </label>
                      <input
                        required
                        name="f-name"
                        placeholder="Name"
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                      />
                    </div>
                    <div className="col-span-full md:col-span-3">
                      <label name="email">Email </label>
                      <input
                        required
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="email"
                        type="text"
                        className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                        onBlur={validateEmail}
                      />
                      {!isEmailValid && (
                        <p className="text-xs text-red-400 ">
                          Invalid email address
                        </p>
                      )}
                    </div>
                    <div className="col-span-full md:col-span-3">
                      <label name="phone number">Phone Number </label>
                      <input
                        required
                        name="phone number"
                        onChange={(event) =>
                          setPhone(event.target.value.replace(/[^0-9+\s]/g, ""))
                        }
                        placeholder="Phone Number"
                        type="text"
                        className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                        onBlur={validatePhone}
                      />
                      {!isPhoneValid && (
                        <p className="text-xs text-red-400 ">
                          Invalid phone number
                        </p>
                      )}
                    </div>
                    {shopDetails.shipping_enabled && (
                      <div className="col-span-full grid grid-cols-6 gap-4 ">
                        <div className="col-span-full">
                          <label name="address line 1">Address line 1 </label>
                          <input
                            required
                            name="address line 1"
                            placeholder="Address line 1"
                            type="text"
                            onChange={(e) => setAdd1(e.target.value)}
                            className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                          />
                        </div>
                        <div className="col-span-full">
                          <label name="address line 1">Address line 2 </label>
                          <input
                            required
                            name="address line 2"
                            placeholder="Address line 2"
                            type="text"
                            onChange={(e) => setAdd2(e.target.value)}
                            className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                          />
                        </div>
                        <div className="col-span-full md:col-span-2 ">
                          <label name="city">City </label>
                          <input
                            required
                            name="city"
                            placeholder="City"
                            type="text"
                            onChange={(e) => setCity(e.target.value)}
                            className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                          />
                        </div>
                        <div className="col-span-full md:col-span-2 ">
                          <label name="country">Country </label>
                          <input
                            required
                            name="country"
                            placeholder="Country"
                            type="text"
                            onChange={(e) => setCountry(e.target.value)}
                            className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                          />
                        </div>
                        <div className="col-span-full md:col-span-2 ">
                          <label name="postcode">ZIP / Postal </label>
                          <input
                            required
                            name="postcode"
                            placeholder="Postal code"
                            type="text"
                            onChange={(e) => setPostcode(e.target.value)}
                            className="w-full rounded-md border p-1 border-gray-200 text-gray-900"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className={`cursor-pointer shadow-md w-full p-2 mt-2 text-xl font-semibold ${theme} ${themBtnStyle}`}
              >
                Submit
              </button>
            </form>
          </div>
        ) : (
          <div className="flex flex-col-reverse items-center md:items-start md:justify-center md:flex-row mx-3  md:mx-0 md:w-full">
            <div className={`flex flex-col items-center p-2 `}>
              <p
                className={`text-left w-full text-gray-500 mb-2 px-2 md:px-6 border border-${theme}-200 shadow-sm  ${themeTileBorderStyle}`}
              >
                Pay with card
              </p>
              <form
                id="payment-form"
                onSubmit={handleSubmit}
                className="flex flex-col"
              >
                {isStripeLoading && (
                  <div className="border rounded p-2 mb-2 text-center">
                    <p className=" text-gray-600">Loading...</p>
                  </div>
                )}
                <PaymentElement />
                <button
                  type="submit"
                  disabled={!stripe}
                  className={`cursor-pointer shadow-md p-2 mt-2 text-xl font-semibold   ${themBtn}  ${themBtnStyle}`}
                >
                  Continue
                </button>
              </form>
            </div>
            <div className="flex  md:flex-col items-center justify-center p-2 ">
              <div
                className={`border-t-2 w-32 md:w-0 md:border-l-2 border-${theme}-100 md:h-40`}
              ></div>
              <div
                className={` font-semibold ${theme} rounded-3xl px-2 mx-1.5 md:mx-0 md:my-2 text-2xl`}
              >
                Or
              </div>
              <div
                className={`border-t-2 w-32 md:w-0 md:border-l-2 border-${theme}-100 md:h-40`}
              ></div>
            </div>
            <div className="  p-2 rounded-md flex flex-col items-center w-full md:w-5/12 ">
              <p
                className={`text-left w-full text-gray-500  px-2 md:px-6 mb-2 border border-${theme}-200 shadow-sm ${themeTileBorderStyle}`}
              >
                Pay with phone
              </p>
              <div className="relative">
                <img
                  src={qrCodeUrl}
                  alt="QR code"
                  className="w-32 h-32 md:h-60 md:w-60 "
                />
                <button
                  title="Download QR"
                  className={`absolute bottom-3 md:bottom-5 -right-3 shadow-md  ${theme}  rounded-b-md rounded-l-md`}
                  onClick={handleDownloadQrCode}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="h-6 w-6"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
        {/* share link modal */}
        {/* <Modal
          isOpen={linkModalIsOpen}
          className="font-lexand  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md shadow-lg text-center"
          overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
          onRequestClose={() => setLinkModalIsOpen(false)}
        >
          <button
            onClick={setLinkModalIsOpenToFalse}
            className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-pink-400 rounded-md text-sm font-semibold flex-shrink"
          >
            x
          </button>
          <div className="bg-white  p-6 rounded-md flex flex-col items-center">
            <div className="relative">
              <img src={qrCodeUrl} alt="QR code" className="w-72 h-72" />
              <button
                title="Download QR"
                className="absolute bottom-5 -right-3 shadow-md bg-pink-300 rounded-b-md rounded-l-md"
                onClick={handleDownloadQrCode}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="h-8 w-8"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
                </svg>
              </button>
            </div>
            <div className="w-full flex justify-center">
              <input
                type="text"
                className="border border-pink-300 rounded-l-md text-sm p-2 md:w-80 text-gray-500"
                value={`https://zaplyn.link/pay/${receivedOrid}`}
                readOnly
              />
              <button
                className="bg-white px-2 border border-pink-300 rounded-r-md flex items-center space-x-1 text-gray-700 "
                onClick={() => {
                  navigator.clipboard.writeText(
                    "https://zaplyn.link/pay/" + receivedOrid
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  className="w-4 h-4"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z" />
                </svg>
                <span className="text-xs">Copy</span>
              </button>
            </div>
          </div>
        </Modal> */}
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </div>
    </section>
  );
};

export default ShopCheckoutForm;
