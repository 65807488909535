import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Outlet } from "react-router-dom";
import TopBar from "../components/TopBar";
import { GlobalProvider } from "../components/GlobalContext";
import LandingPage from "../components/Pages/LandingPage";

function AuthenticatedRoutes() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => setUser(user))
      .catch(() => setUser(null))
      .finally(() => setLoading(false));
  }, []);

  const navigate = useNavigate();
  const { username } = useParams();
  // useEffect(() => {
  //   const checkAuth = async () => {
  //     try {
  //       await Auth.currentAuthenticatedUser();
  //     } catch (error) {
  //       navigate("/login");
  //     }
  //   };

  //   checkAuth();
  // }, [navigate]);

  // console.log(user);

  if (loading) {
    return <div>...</div>;
  }
  return (
    <GlobalProvider>
      <React.Fragment>
        {user ? (
          <div className="font-lexand">
            <TopBar />
            <Outlet />
          </div>
        ) : (
          <LandingPage />
        )}
      </React.Fragment>
    </GlobalProvider>
  );
}

export default AuthenticatedRoutes;
