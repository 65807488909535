import React, { useContext, useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";

import { useQuery } from "react-query";

import Cards from "./Cards";
import getToken from "../../Token";
import getUsername from "../../Account_Infomation/Username";
import { GlobalContext } from "../../GlobalContext";
import LoadingModal from "../../popups/LoadingModal";

function HomeFilterComponent() {
  const accesstoken = getToken();
  const curUser = getUsername();

  const {
    setShowOrdersFilteredData,
    selectedFilterOptions,
    setSelectedFilterOptions,
    setShowFilteredData,
    selectedHomeFilterOptions,
    selectedButtonFilters,
    setSelectedHomeFilterOptions,
  } = useContext(GlobalContext);

  const [page, setPage] = useState(1);

  const FetchFilteredProductsData = async (pageNo) => {
    try {
      const response = await fetch(
        `https://omnistack.org${process.env.REACT_APP_LINK}`+"filter-message-list/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
          },
          body: JSON.stringify({
            user: curUser,
            page: pageNo,
            categories:
              selectedHomeFilterOptions?.map((item) => item).toString() ||
              selectedButtonFilters?.map((item) => item).toString(),
          }),
        }
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log("Error", error);
    }
  };

  // fetch filtered orders
  const {
    isLoading: isFliteredProductsLoading,
    data: fliteredProductsData,
    isError: isFilteredProductsError,
    error: filteredProductsErr,
    isFetching: isFilteredProductsDataFetching,
    isPreviousData: isFilteredPrevProductsData,
    refetch: refetchFilteredData,
  } = useQuery(
    ["filteredProductsData", { page }],
    () => FetchFilteredProductsData(page),
    {
      keepPreviousData: true,
    }
  );

  const lastPage = () => setPage(fliteredProductsData?.pages);

  const firstPage = () => setPage(1);

  const pagesArray = Array(fliteredProductsData?.pages)
    .fill()
    .map((_, index) => index + 1);

  useEffect(() => {
    if (
      selectedButtonFilters?.length > 0 ||
      selectedHomeFilterOptions?.length > 0
    ) {
      refetchFilteredData();
    }
  }, [selectedButtonFilters, selectedHomeFilterOptions]);

  if (isFliteredProductsLoading) {
    return <LoadingModal />;
  }

  if (fliteredProductsData?.pages === 0 || fliteredProductsData?.length === 0) {
    return <p>No data available..</p>;
  }

  return (
    <div className=" flex flex-col gap-1 mx-auto mb-5">
      {/* <div className=" p-2 w-full">
        <div className="flex justify-center space-x-1 text-gray-900">
          <button
            onClick={firstPage}
            disabled={isFilteredPrevProductsData || page === 1}
            title="previous"
            type="button"
            className="inline-flex items-center justify-center w-6 h-6 py-0 border rounded-md shadow-md  border-pink-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-4"
            >
              <path d="M13.9142 12.0001L18.7071 7.20718L17.2929 5.79297L11.0858 12.0001L17.2929 18.2072L18.7071 16.793L13.9142 12.0001ZM7 18.0001V6.00008H9V18.0001H7Z"></path>
            </svg>
          </button>
          {pagesArray.map((pg) => (
            <button
              key={pg}
              type="button"
              disabled={isFilteredPrevProductsData}
              className={` ${
                pg === page ? "bg-pink-300" : ""
              } inline-flex items-center justify-center w-6 h-6 text-xs border rounded shadow-md  border-pink-300`}
              onClick={() => setPage(pg)}
            >
              {pg}
            </button>
          ))}
          <button
            onClick={lastPage}
            disabled={
              isFilteredPrevProductsData || page === fliteredProductsData?.pages
            }
            title="next"
            type="button"
            className="inline-flex items-center justify-center w-6 h-6 py-0 border rounded-md shadow-md  border-pink-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-4"
            >
              <path d="M13.9142 12.0001L18.7071 7.20718L17.2929 5.79297L11.0858 12.0001L17.2929 18.2072L18.7071 16.793L13.9142 12.0001ZM7 18.0001V6.00008H9V18.0001H7Z"></path>
            </svg>
          </button>
        </div>
      </div> */}
      {isFilteredProductsDataFetching && <p>Loading...</p>}
      <p className="flex flex-wrap gap-2 p-1">
        {selectedHomeFilterOptions?.map((item, index) => (
          <span key={index} className="text-xxs">
            {" "}
            {'"'}
            {item}
            {'"'}
          </span>
        ))}
      </p>

      <div className=" grid  md:grid-cols-2 mx-auto gap-5 ">
        {fliteredProductsData?.map((message) => (
          <Cards key={message.id} message={message} />
        ))}
      </div>
    </div>
  );
}

export default HomeFilterComponent;
