import React, { useContext, useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import "../../styles/PostCarousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { GlobalContext } from "../GlobalContext";
import { useParams } from "react-router";
import AttributeComponent from "../Pages/sharePage/AttributeComponent";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Preview = ({ productDetails }) => {
  const { productCategories } = useContext(GlobalContext);
  const [selectedattr1, setSelectedAttr1] = useState("null");
  const [selectedattr2, setSelectedAttr2] = useState("null");
  const [selectedattr3, setSelectedAttr3] = useState("null");
  const [selectedattr4, setSelectedAttr4] = useState("null");
  const [selectedattr5, setSelectedAttr5] = useState("null");
  const [category, setCategory] = useState("");

  //variants
  const [showVariant, setShowVariant] = useState(false);
  const [variants, setVariants] = useState(null);
  const [variantPrice, setVariantPrice] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("null");

  //addons
  const [showAddOn, setShowAddOn] = useState(false);
  const [addOns, setAddOns] = useState(null);
  const [availableAddOn, setAvailableAddOn] = useState(null);
  const [selectedAddOn, setSelectedAddOn] = useState([]);

  // estimated delivery
  const [selectedEtmDelivery, setSelectedEtmDelivery] = useState("");
  const [fromEtmDelivery, setFromEtmDelivery] = useState("");
  const [toEtmDelivery, setToEtmDelivery] = useState("");

  const [itemName, setItemName] = useState("");
  const [qtyOption, setQtyOption] = useState([]);
  const [images, setImages] = useState([]);
  const [body, setBody] = useState("");
  const [body2, setBody2] = useState("");
  const [totalprice, setTotalprice] = useState(0);
  const [qty, setQty] = useState("");
  const [desc, setDesc] = useState("");
  const [fetched, setFetched] = useState("no");
  const [checked, setChecked] = useState(false);
  const [link_enabled, setLink_enabled] = useState("yes");
  const [chosencurrency, setChosencurrency] = useState("GBP");
  const [chosenqty, setChosenqty] = useState("1");
  const [addsent, setAddsent] = useState("no");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postcode, setPostcode] = useState("");
  const [email, setEmail] = useState("");
  const [deleted, setDeleted] = useState("no");
  const [cs, setCs] = useState("");
  const options = {
    clientSecret: cs,

    appearance: {
      /*...*/
    },
  };

  const handlePriceChange = (variant) => {
    setSelectedVariant(variant);
    setVariantPrice(parseInt(variants[variant]));
    setTotalprice(parseInt(variants[variant]) + body2);
  };

  // Addon change Onclick()
  const handleAddOnChange = (addon) => {
    const newSelected = [...selectedAddOn];

    if (newSelected.includes(addon)) {
      newSelected.splice(newSelected.indexOf(addon), 1);
    } else {
      newSelected.push(addon);
    }
    setSelectedAddOn(newSelected);
  };

  // const search = window.location.search;
  // const params = new URLSearchParams(search);
  // const status = params.get("redirect_status");
  const [status, setStatus] = useState("");
  const [tile, setTile] = useState(useParams()["tile"]);
  const [qty2, setQty2] = useState(useParams()["qty"]);

  //error validation
  const [errorMessage, setErrorMessage] = useState("");

  // Category and attribute related Func ---START---
  //*********----------********** */

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const [attributes, setAttributes] = useState(null);

  const [selectedattribute1, setSelectedAttribute1] = useState([]);
  const [selectedattribute2, setSelectedAttribute2] = useState([]);
  const [selectedattribute3, setSelectedAttribute3] = useState([]);
  const [selectedattribute4, setSelectedAttribute4] = useState([]);
  const [selectedattribute5, setSelectedAttribute5] = useState([]);

  const [attribute1, setAttribute1] = useState("null");
  const [attribute2, setAttribute2] = useState("null");
  const [attribute3, setAttribute3] = useState("null");
  const [attribute4, setAttribute4] = useState("null");
  const [attribute5, setAttribute5] = useState("null");

  //*********----------********** */
  // Category and attribute related Func ---END---

  const [errorMsg, setErrorMsg] = useState("");
  // fetch product data
  useEffect(() => {
    if (productCategories) {
      (async () => {
        try {
          const response = await fetch(
            `https://omnistack.org${process.env.REACT_APP_LINK}` +
              "share-tile/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ZaplynTileRef: productDetails.ZaplynTileRef,
              }),
            }
          );

          const json = await response.json();
          if (json) {
            if (json.deleted === "no") {
              setDeleted("no");
              setFetched("yes");
              setSelectedCategory(json.category);
              setSelectedSubCategory(json.sub_category);
              if (json.attribute_1 !== "") {
                setAttribute1(json.attribute_1.split("-")[0]);
                setSelectedAttribute1(
                  json.attribute_1.split("-")[1].split(",")
                );
              }
              if (json.attribute_2 !== "") {
                setAttribute2(json.attribute_2.split("-")[0]);
                setSelectedAttribute2(
                  json.attribute_2.split("-")[1].split(",")
                );
              }
              if (json.attribute_3 !== "") {
                setAttribute3(json.attribute_3.split("-")[0]);
                setSelectedAttribute3(
                  json.attribute_3.split("-")[1].split(",")
                );
              }
              if (json.attribute_4 !== "") {
                setAttribute4(json.attribute_4.split("-")[0]);
                setSelectedAttribute4(
                  json.attribute_4.split("-")[1].split(",")
                );
              }
              if (json.attribute_5 !== "") {
                setAttribute5(json.attribute_5.split("-")[0]);
                setSelectedAttribute5(
                  json.attribute_5.split("-")[1].split(",")
                );
              }
              setItemName(json.itemname);
              setChosencurrency(json.currency);
              setDesc(json.description);
              setLink_enabled(json.link_enabled);
              setImages(json.images);
              setShowVariant(json?.show_variant);
              setVariants(json?.variant);
              setBody(json.retail_price);
              setBody2(json.shipping_price);
              if (json.show_variant === true) {
                setSelectedVariant(Object.keys(json?.variant)[0]);
                setVariantPrice(parseInt(Object.values(json?.variant)[0]));
                setTotalprice(
                  parseInt(Object.values(json?.variant)[0]) +
                    json?.shipping_price
                );
              } else {
                setTotalprice(json.total_price);
              }

              if (json?.add_ons !== null) {
                setShowAddOn(true);
                setAvailableAddOn(json.add_ons[json.ZaplynTileRef]);
              }
              setQty(json.quantity);
              if (json.etm_date !== null) {
                setFromEtmDelivery(json.etm_date?.split(",")[0]);
                setToEtmDelivery(json.etm_date?.split(",")[1]);
                setSelectedEtmDelivery(json.etm_date?.split(",")[2]);
              } else {
                setSelectedEtmDelivery(json.etm_date);
              }
              if (json.link_enabled === "yes") {
                setChecked(true);
              }
            } else {
              setDeleted("yes");
            }
          }
        } catch (error) {
          console.log("something went wrong", error);
          setErrorMsg(error.message);
        }
      })();
    }
  }, [productCategories]);

  useEffect(() => {
    // console.log("running");
    const divs = [];
    for (let i = 0; i < qty; i++) {
      divs.push(i + 1);
    }
    setQtyOption(divs);
  }, [qty]);

  useEffect(() => {
    if (productCategories && selectedCategory && selectedSubCategory) {
      const subcategoryObj = productCategories[selectedCategory];
      let attributes;
      if (selectedSubCategory === "select") {
        attributes = subcategoryObj[selectedCategory];
      } else {
        attributes = subcategoryObj[selectedSubCategory];
      }
      setAttributes(attributes);
    }
  }, [selectedSubCategory]);

  // console.log("categ", selectedCategory);
  // console.log("sub-categ", selectedSubCategory);
  // console.log("ats", attributes);
  // console.log("at1", attribute1);
  // console.log("at2", attribute2);
  // console.log("at3", attribute3);
  // console.log("at4", attribute4);
  // console.log("at5", attribute5);

  // console.log("selectedatrr", selectedattribute1);
  // console.log("selectedatrr", selectedattribute2);
  // console.log("selectedatrr", selectedattribute3);
  // console.log("selectedatrr", selectedattribute4);
  // console.log("selectedatrr", selectedattribute5);

  return (
    <section className="bg-white rounded-lg">
      <div className="font-lexand flex flex-col items-center justify-center  ">
        <div>
          {deleted === "no" && (
            <div>
              {images?.length > 0 ? (
                <div className="mx-auto max-w-xl px-4 pt-2 pb-1 sm:px-6 md:grid md:max-w-3xl md:grid-cols-4 md:grid-rows-[auto,auto,1fr] md:gap-x-8 md:px-8 md:pb-2">
                  <div className="md:col-span-2 md:border-r md:border-gray-200 md:pr-8">
                    <h1 className=" text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                      {itemName}
                    </h1>
                  </div>

                  {/* Description and details */}
                  <div className=" md:col-span-2 md:col-start-1  md:border-r md:border-gray-200 ">
                    <div className="relative p-2 text-5 w-full mb-1 ">
                      <Carousel
                        infiniteLoop={true}
                        showStatus={false}
                        showArrows={false}
                        swipeable={false}
                        thumbWidth={50}
                        className="postproductCarousel"
                      >
                        {images.map((image) => (
                          <div key={image}>
                            <img src={image} alt="" className="" />
                          </div>
                        ))}
                      </Carousel>
                      <p className="hidden absolute top-4 left-4 text-gray-300 bg-yellow-100 text-sm font-serif  rounded-md  p-1 ">
                        Product Details
                      </p>
                    </div>
                    <h2 className="sr-only">Product information</h2>

                    <div className="mt-1">
                      <h2 className="text-sm font-medium text-gray-900">
                        Details
                      </h2>

                      <div className="mt-2 space-y-6">
                        <p className="text-sm text-gray-600">{desc}</p>
                      </div>
                    </div>
                  </div>

                  {/* Options */}
                  <div className=" md:row-span-3 md:col-span-2 md:mt-0 p-2">
                    {/* Price flex-div */}
                    <div className="flex justify-between">
                      <p className="text-2xl tracking-tight text-gray-900">
                        {chosencurrency === "GBP" && (
                          <span className="mr-1">£</span>
                        )}
                        {chosencurrency === "EUR" && (
                          <span className="mr-1">€</span>
                        )}
                        {chosencurrency === "USD" && (
                          <span className="mr-1">$</span>
                        )}
                        {parseInt(totalprice) * parseInt(chosenqty)}
                      </p>
                      <div>
                        {showVariant ? (
                          <p className="text-xs tracking-tight text-gray-500">
                            Price: {variantPrice} {chosencurrency}
                          </p>
                        ) : (
                          <p className="text-xs tracking-tight text-gray-500">
                            Price: {body} {chosencurrency}
                          </p>
                        )}
                        {body2 !== 0 && (
                          <p className="text-xs tracking-tight text-gray-500">
                            Shipping Price: {body2} {chosencurrency}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* product variants */}
                    {showVariant && (
                      <div className="flex flex-wrap gap-2 mt-5 items-center ">
                        {variants !== null &&
                          Object.keys(variants).length > 0 &&
                          Object.keys(variants).map((variantName) => (
                            <div key={variantName}>
                              <div
                                className={`${
                                  variantName === selectedVariant
                                    ? " bg-gradient-to-b from-purple-200 to-pink-300"
                                    : "bg-gray-100"
                                } text-sm p-0.5 rounded-xl`}
                              >
                                <button
                                  onClick={() => handlePriceChange(variantName)}
                                  className={`bg-white p-1 px-4 rounded-xl focus:outline-none`}
                                >
                                  {variantName}
                                </button>
                              </div>
                              <p className="text-center text-xs text-gray-500">
                                {chosencurrency === "GBP" && (
                                  <span className="mr-1">£</span>
                                )}
                                {chosencurrency === "EUR" && (
                                  <span className="mr-1">€</span>
                                )}
                                {chosencurrency === "USD" && (
                                  <span className="mr-1">$</span>
                                )}
                                {parseInt(variants[variantName])}
                              </p>
                            </div>
                          ))}
                      </div>
                    )}

                    <form className="mt-5">
                      {attributes !== null && (
                        <div className="">
                          {/* ----------**********---------- */}
                          {/* attribute-1 */}
                          {/* ----------**********---------- */}
                          {selectedattribute1.length > 0 &&
                          attribute1 === "color" ? (
                            // color
                            <>
                              {selectedattribute1.length > 0 &&
                              selectedattribute1[0] !== "" ? (
                                <div>
                                  <h3 className="text-sm font-medium text-gray-900">
                                    Color
                                  </h3>

                                  <RadioGroup
                                    value={selectedattr1}
                                    onChange={setSelectedAttr1}
                                    className="mt-4 text-xs"
                                  >
                                    <RadioGroup.Label className="sr-only">
                                      {" "}
                                      Choose a color{" "}
                                    </RadioGroup.Label>
                                    <div className="flex items-center space-x-3">
                                      {/* {productDetails.color.split(",").map((color) => ( */}

                                      {selectedattribute1.map((color) => (
                                        <RadioGroup.Option
                                          key={color}
                                          value={color}
                                          className={({ active, checked }) =>
                                            classNames(
                                              color.selectedClass,
                                              active && checked
                                                ? "ring ring-offset-1"
                                                : "",
                                              !active && checked
                                                ? "ring-2"
                                                : "",
                                              "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                                            )
                                          }
                                        >
                                          <RadioGroup.Label
                                            as="span"
                                            className="sr-only"
                                          >
                                            {" "}
                                            {color}{" "}
                                          </RadioGroup.Label>
                                          <span
                                            aria-hidden="true"
                                            style={{
                                              backgroundColor: color,
                                            }}
                                            className={classNames(
                                              color,
                                              "h-8 w-8 rounded-full border border-black border-opacity-10"
                                            )}
                                          />
                                        </RadioGroup.Option>
                                      ))}
                                    </div>
                                  </RadioGroup>
                                </div>
                              ) : (
                                <>
                                  {selectedattribute1.length > 1 && (
                                    <div>
                                      <h3 className="text-sm font-medium text-gray-900">
                                        Color
                                      </h3>

                                      <RadioGroup
                                        value={selectedattr1}
                                        onChange={setSelectedAttr1}
                                        className="mt-4 text-xs"
                                      >
                                        <RadioGroup.Label className="sr-only">
                                          {" "}
                                          Choose a color{" "}
                                        </RadioGroup.Label>
                                        <div className="flex items-center space-x-3">
                                          {/* {productDetails.color.split(",").map((color) => ( */}

                                          <>
                                            {selectedattribute1
                                              .slice(1)
                                              .map((color) => (
                                                <RadioGroup.Option
                                                  key={color}
                                                  value={color}
                                                  className={({
                                                    active,
                                                    checked,
                                                  }) =>
                                                    classNames(
                                                      color.selectedClass,
                                                      active && checked
                                                        ? "ring ring-offset-1"
                                                        : "",
                                                      !active && checked
                                                        ? "ring-2"
                                                        : "",
                                                      "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                                                    )
                                                  }
                                                >
                                                  <RadioGroup.Label
                                                    as="span"
                                                    className="sr-only"
                                                  >
                                                    {" "}
                                                    {color}{" "}
                                                  </RadioGroup.Label>
                                                  <span
                                                    aria-hidden="true"
                                                    style={{
                                                      backgroundColor: color,
                                                    }}
                                                    className={classNames(
                                                      color,
                                                      "h-8 w-8 rounded-full border border-black border-opacity-10"
                                                    )}
                                                  />
                                                </RadioGroup.Option>
                                              ))}
                                          </>
                                        </div>
                                      </RadioGroup>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {selectedattribute1.length > 0 && (
                                <>
                                  {selectedattribute1.length === 1 &&
                                  selectedattribute1[0] === "" ? (
                                    <></>
                                  ) : (
                                    <>
                                      <AttributeComponent
                                        attributeName={attribute1}
                                        selectedAttribute={selectedattr1}
                                        setSelectedAttribute={setSelectedAttr1}
                                        attributeValues={attributes[attribute1]}
                                        availableAttribute={selectedattribute1}
                                      />
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {/* ----------**********---------- */}
                          {/* attribute-2 */}
                          {/* ----------**********---------- */}
                          {selectedattribute2.length > 0 && (
                            <>
                              {selectedattribute2.length === 1 &&
                              selectedattribute2[0] === "" ? (
                                <></>
                              ) : (
                                <>
                                  <AttributeComponent
                                    attributeName={attribute2}
                                    selectedAttribute={selectedattr2}
                                    setSelectedAttribute={setSelectedAttr2}
                                    attributeValues={attributes[attribute2]}
                                    availableAttribute={selectedattribute2}
                                  />
                                </>
                              )}
                            </>
                          )}
                          {/* ----------**********---------- */}
                          {/* attribute-3*/}
                          {/* ----------**********---------- */}
                          {selectedattribute3.length > 0 && (
                            <>
                              {selectedattribute3.length === 1 &&
                              selectedattribute3[0] === "" ? (
                                <></>
                              ) : (
                                <>
                                  <AttributeComponent
                                    attributeName={attribute3}
                                    selectedAttribute={selectedattr3}
                                    setSelectedAttribute={setSelectedAttr3}
                                    attributeValues={attributes[attribute3]}
                                    availableAttribute={selectedattribute3}
                                  />
                                </>
                              )}
                            </>
                          )}
                          {/* ----------**********---------- */}
                          {/* attribute-4 */}
                          {/* ----------**********---------- */}
                          {selectedattribute4.length > 0 && (
                            <>
                              {selectedattribute4.length === 1 &&
                              selectedattribute4[0] === "" ? (
                                <></>
                              ) : (
                                <>
                                  <AttributeComponent
                                    attributeName={attribute4}
                                    selectedAttribute={selectedattr4}
                                    setSelectedAttribute={setSelectedAttr4}
                                    attributeValues={attributes[attribute4]}
                                    availableAttribute={selectedattribute4}
                                  />
                                </>
                              )}
                            </>
                          )}
                          {/* ----------**********---------- */}
                          {/* attribute-5 */}
                          {/* ----------**********---------- */}
                          {selectedattribute5.length > 0 && (
                            <>
                              {selectedattribute5.length === 1 &&
                              selectedattribute5[0] === "" ? (
                                <></>
                              ) : (
                                <>
                                  <AttributeComponent
                                    attributeName={attribute5}
                                    selectedAttribute={selectedattr5}
                                    setSelectedAttribute={setSelectedAttr5}
                                    attributeValues={attributes[attribute5]}
                                    availableAttribute={selectedattribute5}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}

                      {/* quantity */}
                      {status != "succeeded" && (
                        <div>
                          <div className="flex mt-4 justify-between">
                            <p className="text-gray-700">Quantity</p>{" "}
                            {qty === 0 && (
                              <p className="text-gray-400 tex-sm">
                                Currently Unavailable
                              </p>
                            )}
                          </div>
                          <select
                            name="qty"
                            className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 w-full cursor-pointer rounded-lg bg-gray-200"
                            id="qty"
                            required
                            onChange={(e) => setChosenqty(e.target.value)}
                          >
                            {qtyOption.map((qty) => (
                              <option key={qty} value={qty}>
                                {qty}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </form>

                    {/* Add-ons div */}
                    {showAddOn && (
                      <div className=" mt-2 ">
                        {availableAddOn !== null &&
                          !Object.keys(availableAddOn).some(
                            (key) => key === ""
                          ) && (
                            <>
                              <p className="text-gray-600 ">Add-ons -</p>
                              <div className="flex flex-wrap gap-2 mt-1 items-center">
                                {Object.keys(availableAddOn).map((addon) => (
                                  <div key={addon}>
                                    <div
                                      className={`${
                                        selectedAddOn.includes(addon)
                                          ? "bg-gradient-to-b from-purple-200 to-pink-300"
                                          : "bg-gray-100"
                                      }  text-sm p-0.5 rounded-xl`}
                                    >
                                      <button
                                        onClick={() => handleAddOnChange(addon)}
                                        className={` bg-white p-1 px-4 rounded-xl focus:outline-none`}
                                      >
                                        {addon}
                                      </button>
                                    </div>
                                    <p className="text-center text-xs text-gray-500">
                                      {!isNaN(
                                        parseInt(availableAddOn[addon])
                                      ) && <span className="mr-1">£</span>}
                                      {isNaN(parseInt(availableAddOn[addon]))
                                        ? ""
                                        : parseInt(availableAddOn[addon])}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                      </div>
                    )}

                    {selectedEtmDelivery !== null &&
                      selectedEtmDelivery !== "" &&
                      selectedEtmDelivery !== "select" && (
                        <div className=" mt-8">
                          <p className="flex gap-2 text-sm items-center justify-end text-gray-700">
                            Estimated Delivery:{" "}
                            <span className="text-xs text-gray-500">
                              {fromEtmDelivery}
                            </span>
                            -
                            <span className="text-xs text-gray-500">
                              {toEtmDelivery}
                            </span>
                            <span className="text-xs text-gray-500">
                              {selectedEtmDelivery}
                            </span>
                          </p>
                        </div>
                      )}

                    <button
                      className="cursor-not-allowed mt-10 flex w-full items-center justify-center rounded-full py-3 px-8 text-base font-medium text-gray-400 bg-gradient-to-b from-gray-200 to-gray-300"
                      onClick={() => {
                        window.dataLayer.push({
                          order_date: "null",
                          order_amount:
                            parseInt(totalprice) * parseInt(chosenqty),
                          product_id: tile.slice(0, 9),
                          product_name: itemName,
                          event: "checkout",
                        });
                      }}
                    >
                      Continue to Checkout
                    </button>
                    <p className="text-red-600 text-xs text-center mt-2 animate-pulse">
                      {errorMessage}
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* info */}
              <p className="md:col-span-4 text-gray-800 rounded-md text-xs mt-6 mb-5">
                [ This is how the product is shown on Zaplyn Link ]
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Preview;
