export default function LoadingModal() {
  return (
    <div
      className="absolute mt-24 inset-0  z-100  flex justify-center items-center"
      id="wrapper"
    >
      <div className="w-[600px] flex  gap-2">
        <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
      </div>
    </div>
  );
}
