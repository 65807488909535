import React, { Component } from "react";

class IconComponent extends Component {
  constructor(props) {
    super(props);
    console.log("IconCom", props);
  }
  render() {
    return (
      <div>
        <img
          className="rounded-md "
          src={`https://omnistack.org/media/${this.props.subject}.png`}
          alt="display image"
        />
        <p className="">{this.props.subject}</p>
      </div>
    );
  }
}

export default IconComponent;
