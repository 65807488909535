import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import Endpoints from "../../../api/endpoints";

import "./Theme.css";
import React, { useEffect, useState } from "react";

import { Carousel } from "react-responsive-carousel";
import "./ProductTile.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ZaplynLogo from "../../../assets/ZaplynLogo.svg";
import PartialPaymentCheckoutForm from "./partialPaymentCheckout";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY2);

function OrderCart() {
  // theme

  const [status, setStatus] = useState("");
  const [tile, setTile] = useState(useParams()["tile"]);
  if (tile.slice(9, 10) && status !== "succeeded") {
    setStatus("succeeded");
  }
  const [cs, setCs] = useState("");

  const options = {
    clientSecret: cs,

    appearance: {
      /*...*/
    },
  };

  const [isCheckoutClicked, setIsCheckoutClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const getOrderData = async () => {
    try {
      const response = await fetch(
        `https://omnistack.org${process.env.REACT_APP_LINK}`+"fetch-partial-order/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order_id: tile.slice(0, 10),
          }),
        }
      );

      const json = await response.json();
      return json;
    } catch (error) {
      console.log("err", error);
    }
  };

  // fetch payData
  const {
    isLoading: isOrderDataLoading,
    data: orderData,
    isError: isOrderError,
    error: orderErr,
  } = useQuery("partailOrderDetails", getOrderData, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // checkout
  const handlePurchase = async () => {
    setIsCheckoutClicked(true);
    setLoading(true);
    try {
      const response = await fetch(
        `https://omnistack.org${process.env.REACT_APP_LINK}`+"partial-payment-intent/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            totalprice: orderData?.remaining_amount,
          }),
        }
      );
      const json = await response.json();
      if (json) {
        setCs(json);
      }
    } catch (error) {
      console.log("err", error);
    }
    setLoading(false);
  };

  if (isOrderDataLoading) {
    return <p>Loading...</p>;
  }

  if (orderErr) {
    return <p>Please try again Later...</p>;
  }

  return (
    <div className={` ${orderData?.theme?.font}  min-h-screen`}>
      {orderData ? (
        <>
          {" "}
          {orderData?.remaining_amount === 0 ? (
            <p>No data Available ..</p>
          ) : (
            <div className="flex flex-col max-w-3xl mx-auto p-2 ">
              <div className="flex items-center justify-end w-full max-w-3xl mr-2 md:mr-10">
                <p className="text-xxs">powered by</p>
                <img src={ZaplynLogo} alt="" className=" h-4" />
              </div>
              <div className="flex w-full ">
                <img
                  src={orderData?.image}
                  alt=""
                  className="w-20 h-20 rounded-full "
                />
                <div className="ml-2 flex flex-grow flex-col cursor-pointer  ">
                  <p className="text-2xl md:text-4xl">{orderData?.shopname}</p>
                  <p className={`text-gray-500`}>
                    {orderData?.shop_description}
                  </p>
                </div>
              </div>

              <div>
                {" "}
                <section>
                  {isCheckoutClicked ? (
                    <>
                      <div className=" flex items-center justify-end mt-10">
                        <button
                          onClick={() => setIsCheckoutClicked(false)}
                          className="text-gray-500 font-semibold cursor-pointer"
                        >
                          back to cart
                        </button>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-5 h-5 ml-1"
                        >
                          <path
                            d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                            fill="rgba(173,184,194,1)"
                          ></path>
                        </svg>
                      </div>
                      {/* {loading && (
                        <div className="border rounded p-2 mb-1 text-center">
                          <p className=" text-gray-600">Loading...</p>
                        </div>
                      )} */}
                      {cs !== "" && (
                        <Elements stripe={stripePromise} options={options}>
                          <PartialPaymentCheckoutForm
                            orderData={orderData}
                            tileref={Object.keys(orderData.Products).toString()}
                            qty={Object.keys(orderData.Products)
                              .map((item) => orderData.Products[item].quantity)
                              .toString()}
                          />
                        </Elements>
                      )}
                    </>
                  ) : (
                    <div className="">
                      <div className="flex flex-col max-w-3xl p-2 space-y-4 sm:p-10 ">
                        <>
                          <h2 className="text-xl font-semibold">Your cart</h2>
                          <ul
                            className={` flex flex-col divide-y gap-4 divide-gray-100 `}
                          >
                            {orderData &&
                              Object.keys(orderData?.Products).map(
                                (productref) => (
                                  <li
                                    className={`
                                        ${
                                          orderData?.theme?.showBorder ===
                                          "none"
                                            ? ``
                                            : orderData?.theme?.showBorder ===
                                              "light"
                                            ? ` ${orderData?.theme?.theme} ${orderData?.theme?.tileStyle} shadow-sm p-0.25`
                                            : ` ${orderData?.theme?.theme} ${orderData?.theme?.tileStyle} shadow-sm p-0.5`
                                        }
                  
                  flex flex-col sm:flex-row sm:justify-between  `}
                                  >
                                    <div
                                      className={`bg-white  flex flex-col w-full space-x-2 ${orderData?.theme?.tileStyle} py-3 sm:space-x-4  p-1`}
                                    >
                                      {/* product image */}
                                      <div
                                        className={` flex p-0.5 rounded-md `}
                                      >
                                        <Carousel
                                          infiniteLoop={true}
                                          showStatus={false}
                                          showArrows={false}
                                          showIndicators={false}
                                          swipeable={false}
                                          // thumbWidth={30}
                                          showThumbs={false}
                                          className="shopProductCarousel"
                                        >
                                          <div>
                                            <img
                                              src={
                                                orderData?.Products[productref]
                                                  .images
                                              }
                                              alt=""
                                              className=" flex-shrink-0 object-cover w-12 h-12 border-transparent rounded outline-none sm:w-32 sm:h-32 bg-gray-500"
                                            />
                                          </div>
                                        </Carousel>

                                        <div className="flex justify-between w-full pb-2 space-x-2 pl-3">
                                          <div className="space-y-1">
                                            <h3 className="text-lg font-semibold leading-snug sm:pr-8">
                                              {
                                                orderData?.Products[productref]
                                                  .itemname
                                              }
                                            </h3>
                                            <p className="text-xs w-40 md:w-52 text-justify pb-2 truncate ">
                                              {/* {orderData?.Products[productref].description} */}
                                            </p>
                                          </div>
                                          <div className="text-right ">
                                            <p className="text-lg font-semibold">
                                              {(
                                                orderData?.Products[productref]
                                                  .retail_price *
                                                orderData?.Products[productref]
                                                  .quantity
                                              ).toFixed(1)}
                                              <span className="ml-0.5">£</span>
                                            </p>

                                            {orderData?.Products[productref]
                                              .shipping_price !== 0 && (
                                              <p className="text-xs tracking-tight ">
                                                Shipping Price:{" "}
                                                <span>
                                                  {(
                                                    orderData?.Products[
                                                      productref
                                                    ].shipping_price *
                                                    orderData?.Products[
                                                      productref
                                                    ].quantity
                                                  ).toFixed(1)}
                                                  <span className="ml-0.5">
                                                    £
                                                  </span>
                                                </span>
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex flex-col pb-4 gap-2">
                                        {orderData?.Products[productref]
                                          .etm_date !== null &&
                                          orderData?.Products[
                                            productref
                                          ].etm_date.split(",")[2] !== "" &&
                                          orderData?.Products[
                                            productref
                                          ].etm_date.split(",")[2] !==
                                            "select" && (
                                            <div className={` text-xs mt-1 `}>
                                              <p className="flex gap-2  items-center justify-end ">
                                                Estimated Delivery:{" "}
                                                <span className=" ">
                                                  {
                                                    orderData?.Products[
                                                      productref
                                                    ].etm_date.split(",")[0]
                                                  }
                                                </span>
                                                -
                                                <span className=" ">
                                                  {
                                                    orderData?.Products[
                                                      productref
                                                    ].etm_date.split(",")[1]
                                                  }
                                                </span>
                                                <span className=" ">
                                                  {
                                                    orderData?.Products[
                                                      productref
                                                    ].etm_date.split(",")[2]
                                                  }
                                                </span>
                                              </p>
                                            </div>
                                          )}
                                        <div className="flex w-full ">
                                          <div className="flex space-x-2 text-sm divide-x divide-gray-200 w-60 md:w-full overflow-x-auto">
                                            <div className="flex items-start text-xs  justify-start ml-2 pt-1 w-full  space-x-1 divide-x  ">
                                              {Object.keys(
                                                orderData?.Products[productref]
                                                  ?.variant
                                              ).length > 0 &&
                                                Object.values(
                                                  orderData?.Products[
                                                    productref
                                                  ]?.variant
                                                ).toString() !== "null" && (
                                                  <div
                                                    className={`text-gray-700`}
                                                  >
                                                    <p className="  capitalize ">
                                                      {Object.keys(
                                                        orderData?.Products[
                                                          productref
                                                        ]?.variant
                                                      )}
                                                    </p>
                                                    <p className=" text-gray-500">
                                                      <span className="mr-1">
                                                        £
                                                      </span>

                                                      {Object.values(
                                                        orderData?.Products[
                                                          productref
                                                        ]?.variant
                                                      )}
                                                    </p>
                                                  </div>
                                                )}

                                              {orderData?.Products[
                                                productref
                                              ]?.attribute_1.split("-")[0] ===
                                                "color" &&
                                                orderData?.Products[
                                                  productref
                                                ]?.attribute_1.split("-")[1] !==
                                                  "null" && (
                                                  <div
                                                    className={`text-gray-700`}
                                                  >
                                                    <p className="  capitalize ">
                                                      color
                                                    </p>
                                                    <button
                                                      className="rounded-full"
                                                      style={{
                                                        backgroundColor: `${
                                                          orderData?.Products[
                                                            productref
                                                          ]?.attribute_1.split(
                                                            "-"
                                                          )[1]
                                                        }`,
                                                        width: "18px",
                                                        height: "18px",
                                                        marginLeft: "5px",
                                                        marginTop: "3px",
                                                      }}
                                                    ></button>
                                                  </div>
                                                )}
                                              {orderData?.Products[
                                                productref
                                              ]?.attribute_1.split("-")[0] !==
                                                "color" &&
                                                orderData?.Products[
                                                  productref
                                                ]?.attribute_1.split("-")[1] !==
                                                  "null" && (
                                                  <div className="ml-2">
                                                    <div
                                                      className={`text-gray-700
                                                        `}
                                                    >
                                                      <p className="  capitalize ">
                                                        {
                                                          orderData?.Products[
                                                            productref
                                                          ]?.attribute_1.split(
                                                            "-"
                                                          )[0]
                                                        }
                                                      </p>
                                                      <p className=" text-gray-500">
                                                        {
                                                          orderData?.Products[
                                                            productref
                                                          ]?.attribute_1.split(
                                                            "-"
                                                          )[1]
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                )}
                                              {orderData?.Products[
                                                productref
                                              ]?.attribute_2.split("-")[0] !==
                                                "null" &&
                                                orderData?.Products[
                                                  productref
                                                ]?.attribute_2.split("-")[1] !==
                                                  "null" && (
                                                  <div
                                                    className={`text-gray-700
                                                        `}
                                                  >
                                                    <p className="  capitalize ">
                                                      {
                                                        orderData?.Products[
                                                          productref
                                                        ]?.attribute_2.split(
                                                          "-"
                                                        )[0]
                                                      }
                                                    </p>
                                                    <p className=" text-gray-500">
                                                      {
                                                        orderData?.Products[
                                                          productref
                                                        ]?.attribute_2.split(
                                                          "-"
                                                        )[1]
                                                      }
                                                    </p>
                                                  </div>
                                                )}
                                              {orderData?.Products[
                                                productref
                                              ]?.attribute_3.split("-")[0] !==
                                                "null" &&
                                                orderData?.Products[
                                                  productref
                                                ]?.attribute_3.split("-")[1] !==
                                                  "null" && (
                                                  <div
                                                    className={`text-gray-700
                                                        `}
                                                  >
                                                    <p className="  capitalize ">
                                                      {
                                                        orderData?.Products[
                                                          productref
                                                        ]?.attribute_3.split(
                                                          "-"
                                                        )[0]
                                                      }
                                                    </p>
                                                    <p className=" text-gray-500">
                                                      {
                                                        orderData?.Products[
                                                          productref
                                                        ]?.attribute_3.split(
                                                          "-"
                                                        )[1]
                                                      }
                                                    </p>
                                                  </div>
                                                )}
                                              {orderData?.Products[
                                                productref
                                              ]?.attribute_4.split("-")[0] !==
                                                "null" &&
                                                orderData?.Products[
                                                  productref
                                                ]?.attribute_4.split("-")[1] !==
                                                  "null" && (
                                                  <div
                                                    className={`text-gray-700
                                                        `}
                                                  >
                                                    <p className="  capitalize ">
                                                      {
                                                        orderData?.Products[
                                                          productref
                                                        ]?.attribute_4.split(
                                                          "-"
                                                        )[0]
                                                      }
                                                    </p>
                                                    <p className="text-gray-500">
                                                      {
                                                        orderData?.Products[
                                                          productref
                                                        ]?.attribute_4.split(
                                                          "-"
                                                        )[1]
                                                      }
                                                    </p>
                                                  </div>
                                                )}
                                              {orderData?.Products[
                                                productref
                                              ]?.attribute_5.split("-")[0] !==
                                                "null" &&
                                                orderData?.Products[
                                                  productref
                                                ]?.attribute_5.split("-")[1] !==
                                                  "null" && (
                                                  <div
                                                    className={`text-gray-700
                                                        `}
                                                  >
                                                    <p className="  capitalize ">
                                                      {
                                                        orderData?.Products[
                                                          productref
                                                        ]?.attribute_5.split(
                                                          "-"
                                                        )[0]
                                                      }
                                                    </p>
                                                    <p className=" text-gray-500">
                                                      {
                                                        orderData?.Products[
                                                          productref
                                                        ]?.attribute_5.split(
                                                          "-"
                                                        )[1]
                                                      }
                                                    </p>
                                                  </div>
                                                )}
                                            </div>
                                          </div>

                                          {/* quantity div */}
                                          <div className="pl-3 flex w-28   items-center gap-1 md:gap-3">
                                            <p className="flex text-sm">
                                              Quantity :{" "}
                                              {
                                                orderData?.Products[productref]
                                                  .quantity
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )
                              )}
                          </ul>

                          <div className="space-y-1 text-right">
                            <p>
                              Total Amount: &nbsp;
                              <span className="font-semibold">
                                {orderData?.total_amount.toFixed(1)}
                                <span className="ml-0.5">£</span>
                              </span>
                            </p>
                            <p className="text-sm ">
                              Amount Paid: &nbsp;
                              <span
                                className={`text-sm  text-gray-600
                                    `}
                              >
                                {orderData?.already_paid_amount.toFixed(1)}{" "}
                                <span className="ml-0.5">£</span>
                              </span>
                            </p>
                            <p className="  font-semibold">
                              Remaining Amount:{" "}
                              {orderData?.remaining_amount.toFixed(1)}
                              <span className="ml-0.5">£</span>
                            </p>
                          </div>
                        </>

                        <div>
                          <div className="flex justify-end space-x-4">
                            <button
                              onClick={() => {
                                handlePurchase();
                              }}
                              type="button"
                              className={`flex justify-center gap-1 w-1/2 ${orderData?.theme?.themeBtn} ${orderData?.theme?.themeBtnStyle} px-4 py-1 font-semibold text-lg text-gray-700 shadow-md`}
                            >
                              <span className="sr-only sm:not-sr-only">
                                Continue to
                              </span>
                              Checkout
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </section>
              </div>
            </div>
          )}
        </>
      ) : (
        <> </>
      )}
    </div>
  );
}

export default OrderCart;
