import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { convertCurrency } from "../../../helper/helper";
import { ThemeContext } from "../../ThemeContext";
import { Discovery } from "aws-sdk";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MobileComponent from "./MobileComponent";
import ZaplynLogo from "../../../assets/ZaplynLogo.svg";
import { useQuery } from "react-query";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY2);
const MobileCheckoutForm = () => {
  const [cs, setCs] = useState("");
  const options = {
    clientSecret: cs,

    appearance: {
      /*...*/
    },
  };
  const [tile, setTile] = useState(useParams()["tile"]);
  const [shopImg, setShopImg] = useState("");
  const [name, setName] = useState("");
  const [shopName, setShopName] = useState("");
  const [shopDesc, setShopDesc] = useState("");
  const [amount, setAmount] = useState();
  const [theme, setTheme] = useState();

  const [receivedOrid, setReceivedOrId] = useState(tile.slice(0, 10));

  const getPayData = async () => {
    try {
      const response = await fetch(
        `https://omnistack.org${process.env.REACT_APP_LINK}`+"payment-intent-mobile/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order_id: receivedOrid,
          }),
        }
      );

      const json = await response.json();
      return json;
    } catch (error) {
      console.log("err", error);
    }
  };

  // fetch payData
  const {
    isLoading: isPayDataLoading,
    data: payData,
    isError: isPayError,
    error: payErr,
  } = useQuery("payDetails", getPayData, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (payData) {
      setCs(payData?.cs);
      setName(payData?.customername);
      setShopName(payData?.shopname);
      setShopImg(payData?.shopimage);
      setShopDesc(payData?.shopdesc);
      setAmount(payData?.amount);
      setTheme(payData?.theme);
    }
  }, [payData]);

  return (
    <section className={`mt-1 flex flex-col max-w-xl mx-auto ${theme?.font}`}>
      <div className="flex items-center justify-end w-full max-w-3xl mr-2 md:mr-10">
        <p className="text-xxs">powered by</p>
        <img src={ZaplynLogo} alt="" className=" h-4" />
      </div>
      {isPayDataLoading && <p>Loading...</p>}
      {shopImg && shopImg !== "null" && (
        <div className="flex w-full ">
          {shopImg && (
            <img src={shopImg} alt="" className="w-20 h-20 rounded-full "></img>
          )}
          {shopName && (
            <div className="ml-2 flex flex-grow flex-col cursor-pointer  ">
              <p className="text-2xl md:text-4xl">{shopName}</p>
              <p
                className={`text-gray-500
              `}
              >
                {shopDesc}
              </p>
            </div>
          )}
        </div>
      )}

      {name && (
        <div className="max-w-md flex flex-col items-center justify-center mx-auto">
          <div className="flex flex-col items-start w-full p-2 ">
            <p>
              Hi <span className="capitalize">{name},</span>
            </p>
            <p>Continue with your payment here.</p>
          </div>

          {cs === "null" && <p>Payment already done for this order</p>}

          {cs !== "" && cs !== "null" && (
            <Elements stripe={stripePromise} options={options}>
              <MobileComponent theme={theme} />
            </Elements>
          )}

          {/* Show error message to your customers */}
        </div>
      )}
    </section>
  );
};

export default MobileCheckoutForm;
