import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const getUsername = () => {
  var base = "stupidbug";

  const poolData = {
    UserPoolId: "eu-west-2_PLEKK7M02",
    ClientId: "3a4ot1guf8mf43f43ousl7noq0",
    // UserPoolId: "eu-west-2_UXJYfjlKr",
    // ClientId: "5k305rgnr1t5g9btchop69qfv8",
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser == null) {
    return base;
  }

  return cognitoUser.username;
};

export default getUsername;
