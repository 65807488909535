import React, { useState, useEffect,useContext } from "react";
import ProgressBar from "@badrap/bar-of-progress";
import ZaplynLogo from "../../../assets/ZaplynLogo.svg";
import { w3cwebsocket as W3CWebSocket } from "websocket";


const Sucess = () => {
  const progress = new ProgressBar();
  const search = window.location.search;
  const [tile, setTile] = useState(search.slice(1, 11));
  const queryParameters = new URLSearchParams(window.location.search);
  const payment_intent = queryParameters.get("payment_intent");
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  useEffect(() => {
    if (tile?.length > 0) {
      fetch(link+"order-confirm/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order_id: tile.slice(0, 10),
          discount: "5",
          payment_intent: payment_intent || "",
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          // toast(JSON.stringify( json["Notification-text"]).replace(/"|'/g, ""));
        });
      
        var ws_scheme =
          window.location.protocol == "https:"
            ? "wss://omnistack.org"
            : "wss://omnistack.org";
        const sock = new W3CWebSocket(ws_scheme + "/ws3/fetch-order-status/");
    
        sock.onopen = () => {
          console.log("WebSocket Client Connected");
          sock.send(
            JSON.stringify({
              order_id: tile.slice(0, 10),
            })
          );
        };

        const sock2 = new W3CWebSocket(ws_scheme + "/ws3/fetch-new-order/");
        sock2.onopen = () => {
          sock2.send(
            JSON.stringify({
              order_id: tile.slice(0, 10),
            })
          );
        };
        
    }
  }, [tile]);

  useEffect(() => {
    const interval = setInterval(() => {
      progress.finish();
      fetch(link+"go-back/", {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({ order_id: tile.slice(0, 10) }),
          })
            .then((response) => response.json())
            .then((json) => {
              if (json["Status"] == "success") {
                window.location.href = json["url"];
              }
            });
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="flex flex-col px-2 mx-2  justify-center items-center gap-2 font-lexand">
      <div className="flex items-center justify-end w-full max-w-md mr-2 mt-2 md:mr-10">
          <p className="text-xxs">powered by</p>
          <img src={ZaplynLogo} alt="" className=" h-4" />
        </div>
      <div className="bg-white p-8 shadow-md rounded-lg md:w-96   md:mx-auto">
        <svg
          viewBox="0 0 24 24"
          className="text-green-600 w-16 h-16 mx-auto my-6"
        >
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          ></path>
        </svg>
        <div className="text-center">
        
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
            Payment Done!
          </h3>
          <p className="text-gray-600 my-2">
            You will receive a confirmation email soon.
          </p>
          <p className="text-gray-600 my-2">Order Id: {tile.slice(0, 10)}</p>
          <p> Have a great day! </p>
          <button
            className="text-xs md:text-sm p-1 px-4 md:px-6 mt-2 focus:outline-none text-center  cursor-pointer  bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full  font-semibold text-gray-600 shadow-md"
            onClick={() => {
              fetch( `https://omnistack.org${process.env.REACT_APP_LINK}`+"go-back/", {
                method: "POST",
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify({ order_id: tile.slice(0, 10) }),
              })
                .then((response) => response.json())
                .then((json) => {
                  if (json["Status"] == "success") {
                    window.location.href = json["url"];
                  }
                });
            }}
          >
            Go Back
          </button>
          <p className="text-gray-600 my-2">
          You will be automatically redirected to products page in 10 seconds.
          </p>
          {progress.start()}
        </div>
      </div>
    </div>
  );
};

export default Sucess;
