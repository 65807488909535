import React, { useContext, useState } from "react";
import { ThemeContext } from "../ThemeContext";

function CustomerNote({ setUserNote, customerNote, closeOnClick }) {
  const [internalNote, setInternalNote] = useState(customerNote);
  const [isEditable, setIsEditable] = useState(false);

  const {
    theme,
    setTheme,
    themBtn,
    setThemBtn,
    themBtnStyle,
    themeTileBorder,
    setThemeTileBorder,
    themeTileBorderStyle,
    setThemeTileBorderStyle,
    themeFont,
    setThemeFont,
  } = useContext(ThemeContext);

  //   cancel button func
  const handleCancelClick = () => {
    closeOnClick();
  };

  //   save button func
  const handleSubmit = async (e) => {
    e.preventDefault(); //prevents refreshing page
    // console.log(internalNote);
    setUserNote(internalNote);
    closeOnClick();
  };

  return (
    <div>
      <div className="bg-white p-2 rounded-lg">
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs text-gray-700 text-left w-full">Add Note:</p>
          <textarea
            name="internalnote"
            id="internalnote"
            className={`focus:outline-none p-2 shadow-sm border  border-gray-100 rounded-md text-xs h-20 w-full overflow-hidden `}
            type="Text"
            value={internalNote}
            onChange={(e) => setInternalNote(e.target.value)}
            required
            placeholder="Enter your note here"
            rows="1"
            maxLength={150}
          />
          <div className="flex gap-2 text-xs mt-2">
            <button
              onClick={handleCancelClick}
              className={`${
                themBtn
                  ? themBtn
                  : "bg-gradient-to-b from-purple-200 to-pink-300 rounded-lg"
              } ${
                themBtnStyle
                  ? themBtnStyle
                  : "bg-gradient-to-b from-purple-200 to-pink-300 rounded-lg"
              } px-4 p-1  `}
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className={`${
                themBtn
                  ? themBtn
                  : "bg-gradient-to-b from-purple-200 to-pink-300 rounded-lg"
              } ${
                themBtnStyle
                  ? themBtnStyle
                  : "bg-gradient-to-b from-purple-200 to-pink-300 rounded-lg"
              } px-4 p-1  `}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerNote;
