import React, { useContext } from "react";
import ReactSwitch from "react-switch";
import { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import getToken from "./Token";
import getUsername from "./Account_Infomation/Username";
import useSubject from "./Subject";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Buffer } from "buffer";
import Modal from "react-modal";
import "../styles/PostCarousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ColorComponent from "./popups/ColorComponent";
import { CompactPicker } from "react-color";
import SizeComponent from "./popups/SizeComponent";
import { useQueryClient } from "react-query";
import { GlobalContext } from "./GlobalContext";
import { S3 } from "aws-sdk";
import { convertToBase64 } from "../helper/helper";
import { Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";
const s3 = new S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: "v4",
});

window.Buffer = Buffer;

const PostMessagePopup2 = (props) => {
  const queryClient = useQueryClient();

  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const [images, setImages] = useState([]);
  const [editImages, setEditImages] = useState([]);
  const { productCategories } = useContext(GlobalContext);

  const [files, setFiles] = useState([]);
  const [posting, setPosting] = useState(false);
  const [justposted, setJustposted] = useState("no");
  const accesstoken = getToken();
  const username = getUsername();
  //const [isPending, setIsPending] = useState(false);

  const [body, setBody] = useState("");
  const [body2, setBody2] = useState("");
  const [totalprice, setTotalprice] = useState(0);
  const [size, setSize] = useState([]);
  const [color, setColor] = useState([]);
  const [attribute, setAttribute] = useState("Size");
  const [colorattribute, setColorattribute] = useState("no");
  const [sizeattribute, setSizeattribute] = useState("no");
  const [qtyattribute, setQtyattribute] = useState("no");
  const [qty, setQty] = useState("");
  const [desc, setDesc] = useState("");
  const [fetched, setFetched] = useState("no");
  const [ZaplynTileRef, setZaplynTileRef] = useState(props.currenttileref);

  const [author, setAuthor] = useState(username);

  const [chosencurrency, setChosencurrency] = useState("GBP");

  const [itemTitle, setItemTitle] = useState("");

  // shipping checkbox
  const [isShippingChecked, setIsShippingChecked] = useState(false);

  const handleShippingPrice = () => {
    setIsShippingChecked(!isShippingChecked);
    setBody2(0);
  };

  // variants checkbox
  const [isVariantChecked, setVariantChecked] = useState(false);

  const handleVariantCheck = () => {
    setVariantChecked(!isVariantChecked);
  };

  // variant items

  const [variantItems, setVariantItems] = useState([
    { variantName: "", price: "" },
  ]);
  const [variantCount, setVariantCount] = useState(variantItems?.length);

  const handleAddVariantItem = () => {
    if (variantCount < 10) {
      setVariantItems([...variantItems, { variantName: "", price: "" }]);
      setVariantCount(variantCount + 1);
    }
  };

  // Addon checkbox
  const [isAddOnChecked, setAddOnChecked] = useState(false);

  const handleAddOnCheck = () => {
    setAddOnChecked(!isAddOnChecked);
  };

  // Addon items

  const [addOnItems, setAddOnItems] = useState([{ addOnName: "", price: "" }]);
  const [addOnCount, setAddOnCount] = useState(1);

  const handleAddAddOnItem = () => {
    if (addOnCount < 10) {
      setAddOnItems([...addOnItems, { addOnName: "", price: "" }]);
      setAddOnCount(addOnCount + 1);
    }
  };

  // estimated delivery
  const [selectedEtmDelivery, setSelectedEtmDelivery] = useState("");
  const [fromEtmDelivery, setFromEtmDelivery] = useState("");
  const [toEtmDelivery, setToEtmDelivery] = useState("");

  // loading modal
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);

  // Category and attribute related Func ---START---
  //*********----------********** */
  const [categoryList, setCategoryList] = useState(null);
  const [subCategoryList, setSubCategoryList] = useState(null);
  //   const { productCategories } = useContext(GlobalContext);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const [attributes, setAttributes] = useState(null);

  // color-picker
  const [showColorPicker, setShowColorPicker] = useState(false);
  const handleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };
  const handleColorSelection = (color) => {
    setSelectedAttribute1([...selectedattribute1, color.hex]);
  };

  const onColorDelete = (index) => {
    const newColors = selectedattribute1.filter((color, i) => i !== index);
    setSelectedAttribute1(newColors);
  };

  //size picker
  const [selectedattribute1, setSelectedAttribute1] = useState([]);
  const [selectedattribute2, setSelectedAttribute2] = useState([]);
  const [selectedattribute3, setSelectedAttribute3] = useState([]);
  const [selectedattribute4, setSelectedAttribute4] = useState([]);
  const [selectedattribute5, setSelectedAttribute5] = useState([]);

  const handleAttribute1Click = (name) => {
    const newSelectedSizes = [...selectedattribute1];

    if (newSelectedSizes.includes(name)) {
      newSelectedSizes.splice(newSelectedSizes.indexOf(name), 1);
    } else {
      newSelectedSizes.push(name);
    }

    setSelectedAttribute1(newSelectedSizes);
  };

  const handleAttribute2Click = (name) => {
    const newSelectedSizes = [...selectedattribute2];

    if (newSelectedSizes.includes(name)) {
      newSelectedSizes.splice(newSelectedSizes.indexOf(name), 1);
    } else {
      newSelectedSizes.push(name);
    }

    setSelectedAttribute2(newSelectedSizes);
  };
  const handleAttribute3Click = (name) => {
    const newSelectedSizes = [...selectedattribute3];

    if (newSelectedSizes.includes(name)) {
      newSelectedSizes.splice(newSelectedSizes.indexOf(name), 1);
    } else {
      newSelectedSizes.push(name);
    }

    setSelectedAttribute3(newSelectedSizes);
  };
  const handleAttribute4Click = (name) => {
    const newSelectedSizes = [...selectedattribute4];

    if (newSelectedSizes.includes(name)) {
      newSelectedSizes.splice(newSelectedSizes.indexOf(name), 1);
    } else {
      newSelectedSizes.push(name);
    }

    setSelectedAttribute4(newSelectedSizes);
  };
  const handleAttribute5Click = (name) => {
    const newSelectedSizes = [...selectedattribute5];

    if (newSelectedSizes.includes(name)) {
      newSelectedSizes.splice(newSelectedSizes.indexOf(name), 1);
    } else {
      newSelectedSizes.push(name);
    }

    setSelectedAttribute5(newSelectedSizes);
  };

  const [attribute1, setAttribute1] = useState("");
  const [attribute2, setAttribute2] = useState("");
  const [attribute3, setAttribute3] = useState("");
  const [attribute4, setAttribute4] = useState("");
  const [attribute5, setAttribute5] = useState("");

  //   categorylist
  useEffect(() => {
    if (productCategories) {
      const categories = Object.keys(productCategories);
      setCategoryList(categories);
    }
  }, [productCategories]);

  //*********----------********** */
  // Category and attribute related Func ---END---

  useEffect(() => {
    if (fetched == "no") {
      fetch(
        `https://omnistack.org${process.env.REACT_APP_LINK}` + "fetch-message/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
          },
          body: JSON.stringify({
            user: author,
            ZaplynTileRef: ZaplynTileRef,
          }),
        }
      )
        .then((response) => response.json())
        .then((json) => {
          // console.log("fetcheddata", json);

          setSelectedCategory(json.category);
          setSelectedSubCategory(json.sub_category);
          if (json.attribute_1 !== "") {
            setAttribute1(json.attribute_1.split("-")[0]);
            setSelectedAttribute1(json.attribute_1.split("-")[1].split(","));
          }
          if (json.attribute_2 !== "") {
            setAttribute2(json.attribute_2.split("-")[0]);
            setSelectedAttribute2(json.attribute_2.split("-")[1].split(","));
          }
          if (json.attribute_3 !== "") {
            setAttribute3(json.attribute_3.split("-")[0]);
            setSelectedAttribute3(json.attribute_3.split("-")[1].split(","));
          }
          if (json.attribute_4 !== "") {
            setAttribute4(json.attribute_4.split("-")[0]);
            setSelectedAttribute4(json.attribute_4.split("-")[1].split(","));
          }
          if (json.attribute_5 !== "") {
            setAttribute5(json.attribute_5.split("-")[0]);
            setSelectedAttribute5(json.attribute_5.split("-")[1].split(","));
          }

          setChosencurrency(json.currency);
          setDesc(json.description);
          setLink_enabled(json.link_enabled);
          setBody(json.retail_price);
          setImages(json.images);
          if (json.etm_date !== null) {
            setFromEtmDelivery(json.etm_date?.split(",")[0]);
            setToEtmDelivery(json.etm_date?.split(",")[1]);
            setSelectedEtmDelivery(json.etm_date?.split(",")[2]);
          }
          setVariantChecked(json.show_variant);
          if (json.show_variant === true) {
            let items = [];
            setVariantCount(Object.keys(json.variant).length);
            for (let i = 0; i < Object.keys(json.variant).length; i++) {
              const updatedItems = [...items, { variantName: "", price: "" }];
              updatedItems[i].variantName = Object.keys(json.variant)[i];
              updatedItems[i].price = Object.values(json.variant)[i];
              items = [...updatedItems];
            }
            setVariantItems(items);
          }

          if (
            json?.add_ons !== null &&
            json?.add_ons !== undefined &&
            json.add_ons[json.ZaplynTileRef] !== null &&
            json.add_ons[json.ZaplynTileRef] !== undefined &&
            Object.keys(json.add_ons[json.ZaplynTileRef]).length > 0
          ) {
            setAddOnChecked(true);
            let items = [];
            setAddOnCount(Object.keys(json.add_ons[json.ZaplynTileRef]).length);
            for (
              let i = 0;
              i < Object.keys(json.add_ons[json.ZaplynTileRef]).length;
              i++
            ) {
              const updatedItems = [...items, { addOnName: "", price: "" }];
              updatedItems[i].addOnName = Object.keys(
                json.add_ons[json.ZaplynTileRef]
              )[i];
              updatedItems[i].price = Object.values(
                json.add_ons[json.ZaplynTileRef]
              )[i];
              items = [...updatedItems];
            }
            setAddOnItems(items);
          }
          setItemTitle(json.itemname);
          setBody2(json.shipping_price);
          setTotalprice(json.total_price);
          setQty(json.quantity);
          if (json.link_enabled === "yes") {
            setChecked(true);
          }
          setFetched("yes");
        });
    }
  }, []);

  //   subCategoryList
  useEffect(() => {
    if (productCategories && selectedCategory && fetched === "yes") {
      const subcategories = Object.keys(productCategories[selectedCategory]);
      if (
        subcategories?.length === 1 &&
        subcategories[0] === selectedCategory
      ) {
        setSubCategoryList(null);
        const subcategoryObj = productCategories[selectedCategory];
        // console.log(productCategories[selectedCategory]);
        const attributes = subcategoryObj[selectedCategory];
        // console.log(subcategoryObj[selectedCategory]);
        setAttributes(attributes);
      } else {
        setSubCategoryList(
          subcategories.filter((item) => item !== selectedCategory)
        );
        const subcategoryObj = productCategories[selectedCategory];
        // console.log(productCategories[selectedCategory]);
        const attributes = subcategoryObj[selectedCategory];
        // console.log(subcategoryObj[selectedCategory]);
        setAttributes(attributes);
      }
    }
  }, [selectedCategory, fetched]);

  //   attributes
  useEffect(() => {
    if (
      productCategories &&
      selectedSubCategory !== "" &&
      selectedSubCategory !== "select" &&
      fetched === "yes"
    ) {
      const subcategoryObj = productCategories[selectedCategory];
      const attributes = subcategoryObj[selectedSubCategory];
      setAttributes(attributes);
    } else if (
      productCategories &&
      selectedSubCategory !== "" &&
      selectedSubCategory === "select" &&
      fetched === "yes"
    ) {
      const subcategoryObj = productCategories[selectedCategory];
      const attributes = subcategoryObj[selectedCategory];
      setAttributes(attributes);
    }
  }, [selectedSubCategory, fetched]);

  //   attributes values
  useEffect(() => {
    if (productCategories && attributes && fetched === "yes") {
      for (let i = 0; i < Object.keys(attributes).length; i++) {
        if (i === 0) {
          setAttribute1(Object.keys(attributes)[i]);
        } else if (i === 1) {
          setAttribute2(Object.keys(attributes)[i]);
        } else if (i === 2) {
          setAttribute3(Object.keys(attributes)[i]);
        } else if (i === 3) {
          setAttribute4(Object.keys(attributes)[i]);
        } else if (i === 4) {
          setAttribute5(Object.keys(attributes)[i]);
        }
      }
    }
  }, [attributes, fetched]);
  const [baseImage, setBaseImage] = useState([]);

  const handleSumbit = async (e) => {
    e.preventDefault(); //prevents refreshing page

    const imageUrls = [];
    for (let i = 0; i < files.length; i++) {
      const fileName = `${Date.now()}-${files[i].name}`;
      const s3Params = {
        Bucket: "zaplynimages",
        Key: fileName,
        ContentType: files[i].type,
        Body: files[i],
      };
      try {
        const response = await fetch(link + "zaplyn-image-upload/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
          },
          body: JSON.stringify({
            image: editImages[i],
            filename: fileName,
          }),
        });

        const json = await response.json();
        imageUrls.push(json.url);
      } catch (err) {
        console.error(`Error uploading image ${i}:`, err);
      }
    }

    let attr1 = "";
    let attr2 = "";
    let attr3 = "";
    let attr4 = "";
    let attr5 = "";

    if (attribute1 !== "") {
      attr1 = attribute1 + "-" + selectedattribute1.toString();
    }
    if (attribute2 !== "") {
      attr2 = attribute2 + "-" + selectedattribute2.toString();
    }
    if (attribute3 !== "") {
      attr3 = attribute3 + "-" + selectedattribute3.toString();
    }

    if (attribute4 !== "") {
      attr4 = attribute4 + "-" + selectedattribute4.toString();
    }
    if (attribute5 !== "") {
      attr5 = attribute5 + "-" + selectedattribute5.toString();
    }
    const content = {
      author,
      attribute,
      totalprice,
      itemname: itemTitle,
      desc,
      body: body || 0,
      body2,
      color: "",
      size: "",
      qty,
      images: imageUrls.toString() || images.toString(),
      subject: selectedCategory,
      sub_category: selectedSubCategory,
      ZaplynTileRef,
      link_enabled,
      variant: variantItems
        ?.map((item) => [item.variantName, item.price].toString())
        .toString(),
      show_variant: isVariantChecked,
      add_ons: addOnItems.reduce((result, obj) => {
        result[obj.addOnName] = obj.price;
        return result;
      }, {}),
      etm_date:
        fromEtmDelivery + "," + toEtmDelivery + "," + selectedEtmDelivery,
      currency: chosencurrency,
      attribute_1: attr1 || "",
      attribute_2: attr2 || "",
      attribute_3: attr3 || "",
      attribute_4: attr4 || "",
      attribute_5: attr5 || "",
    };

    const response = await fetch(link + "message-edit/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify(content),
    });

    const json = await response.json();

    if (json) {
      setDesc("");
      setJustposted("yes");
      document.getElementById("messagesubject").reset();
      setBody("");
      setBody2("");

      setIsLoadingModalOpen(true);
      await queryClient.invalidateQueries("productsData");
      props.onClick();

      // await queryClient.invalidateQueries("productImages");
      setIsLoadingModalOpen(false);
    }

    // const options = {
    //   maxSizeMB: 0.2,
    //   maxWidthOrHeight: 1280,
    // };

    // var count = Object.keys(files).length;
    // // console.log(count);
    // setBaseImage([]);
    // if (count > 0) {
    //   for (let i = 0; i < count; i++) {
    //     const compressedFile = await imageCompression(files[i], options);
    //     const base64 = await convertBase64(compressedFile);

    //     await fetch(link+"photo-upload/", {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + accesstoken,
    //       },
    //       body: JSON.stringify({
    //         user: author,
    //         name: ZaplynTileRef + "-img-" + i,
    //         image: base64,
    //         ZaplynTileRef: ZaplynTileRef,
    //       }),
    //     })
    //       .then((response) => response.json())
    //       .then((json) => setBaseImage(json["image_data"]));
    //   }
    // }
  };

  const uploadImage = async (e) => {
    const newFiles = [];
    //frontend display
    const newImages = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1280,
      };
      const compressedImg = await imageCompression(e.target.files[0], options);
      const compressedbase64 = await convertToBase64(compressedImg);
      const base64 = await convertToBase64(e.target.files[i]);
      newImages.push(compressedbase64);
      newFiles.push(e.target.files[i]);
      // newImages.push(e.target.files[i]);
    }
    setEditImages(newImages);
    setFiles(newFiles);
  };

  const [checked, setChecked] = useState(false);
  const [link_enabled, setLink_enabled] = useState("no");
  const handleChange = (val) => {
    setChecked(val);
    if (val) {
      setLink_enabled("yes");
    } else {
      setLink_enabled("no");
    }
  };

  useEffect(() => {
    if (body2 !== "" && body2 !== 0) {
      setIsShippingChecked(true);
    }
  }, [body2]);

  // console.log(files);
  // console.log("categ", selectedCategory);
  // console.log("sub-categ", selectedSubCategory);
  // console.log("sub-categ", subCategoryList);
  // console.log("ats", attributes);
  // console.log("at1", attribute1);
  // console.log("at2", attribute2);
  // console.log("at3", attribute3);
  // console.log("at4", attribute4);
  // console.log("at5", attribute5);

  // console.log("selectedatrr", selectedattribute1);
  // console.log("selectedatrr", selectedattribute2);
  // console.log("selectedatrr", selectedattribute3);
  // console.log("selectedatrr", selectedattribute4);
  // console.log("selectedatrr", selectedattribute5);

  return (
    <div className="z-40 bg-white rounded-lg">
      <div id="containerbox" className="flex flex-col gap-2 p-2">
        <p className="text-lg ">Edit your Product</p>

        <div className="md:flex md:mt-2 md:gap-4 md:w-full">
          {/* details section */}
          <div className="flex flex-col gap-2 md:gap-4 md:w-1/2">
            {/* category div */}
            <div className="relative flex flex-col justify-center items-end gap-2">
              <div className=" p-1 w-full ">
                <select
                  class="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 md:p-2 w-full cursor-pointer bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-md"
                  required
                  value={selectedCategory}
                  onChange={(e) => {
                    if (e.target.value !== "Select Category") {
                      setAttributes(null);
                      setAttribute1("");
                      setAttribute2("");
                      setAttribute3("");
                      setAttribute4("");
                      setAttribute5("");
                      setSelectedAttribute1([]);
                      setSelectedAttribute2([]);
                      setSelectedAttribute3([]);
                      setSelectedAttribute4([]);
                      setSelectedAttribute5([]);
                      setSelectedCategory(e.target.value);
                    } else if (e.target.value === "Select Category") {
                      setSubCategoryList(null);
                      setAttributes(null);
                      setAttribute1("");
                      setAttribute2("");
                      setAttribute3("");
                      setAttribute4("");
                      setAttribute5("");
                      setSelectedAttribute1([]);
                      setSelectedAttribute2([]);
                      setSelectedAttribute3([]);
                      setSelectedAttribute4([]);
                      setSelectedAttribute5([]);
                    }
                  }}
                >
                  {categoryList == null && <div>loading</div>}
                  {categoryList != null &&
                    categoryList.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                </select>
              </div>
              {subCategoryList !== null && (
                <div className=" p-1 w-full ">
                  <select
                    class="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 md:p-2 w-full cursor-pointer bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-md"
                    required
                    defaultValue={selectedSubCategory}
                    value={selectedSubCategory}
                    onChange={(e) => {
                      if (e.target.value !== "Select") {
                        setAttribute1("");
                        setAttribute2("");
                        setAttribute3("");
                        setAttribute4("");
                        setAttribute5("");
                        setSelectedAttribute1([]);
                        setSelectedAttribute2([]);
                        setSelectedAttribute3([]);
                        setSelectedAttribute4([]);
                        setSelectedAttribute5([]);
                        setShowColorPicker(false);
                        setSelectedSubCategory(e.target.value);
                      }
                    }}
                  >
                    {subCategoryList == null && <div>loading</div>}
                    {subCategoryList != null &&
                      subCategoryList.map((subcategory, index) => (
                        <option key={index} value={subcategory}>
                          {subcategory}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              {/* {subjectError && (
                <p className=" text-xs text-red-700">{subjectError}</p>
              )} */}
            </div>
            <input
              id="title"
              className="focus:outline-none p-2  shadow-md border border-pink-300 rounded-md text-sm w-full"
              type="Text"
              required
              value={itemTitle || ""}
              onChange={(e) => setItemTitle(e.target.value)}
              placeholder="Add Title*"
              rows="1"
              maxLength={50}
            />
            <textarea
              id="description"
              className="focus:outline-none p-2 shadow-md border border-pink-300 rounded-md text-sm w-full h-24 resize-none"
              type="Text"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              required
              placeholder="Add Description*"
              rows="1"
              maxLength={150}
            />
            <div className="space-y-2 md:space-y-4">
              {/* variants div */}
              <div className="flex flex-col items-start  gap-2 ">
                <div className="flex items-center gap-3">
                  {/* <select
                    className="focus:outline-none w-2/7  shadow-md bg-white border border-pink-300 rounded-md text-xs p-2 cursor-pointer "
                    required
                    onChange={(e) => setChosencurrency(e.target.value)}
                  >
                    <option value="GBP">GBP</option>
                    <option value="EUR">EUR</option>
                    <option value="USD">USD</option>
                  </select> */}
                  <p className="text-xs">
                    {chosencurrency} {`(£)`}
                  </p>
                  <p className="flex text-xs md:text-sm text-gray-600 my-2">
                    Product Variants?{" "}
                    <Tooltip
                      enterTouchDelay={0}
                      TransitionComponent={Zoom}
                      placement="top"
                      arrow
                      title="Add variants to your product/service each with its own price."
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"
                          fill="rgba(235,117,253,1)"
                        ></path>
                      </svg>
                    </Tooltip>
                  </p>

                  <input
                    type="checkbox"
                    id="variant"
                    name="variant"
                    className="h-4 w-4 mx-2 my-2 bg-red-100 border-red-300 text-red-500 focus:ring-red-200"
                    checked={isVariantChecked}
                    onChange={handleVariantCheck}
                  />
                  {isVariantChecked && (
                    <div
                      onClick={handleAddVariantItem}
                      className="flex items-center gap-2 bg-gradient-to-br from-purple-300 to-pink-500 px-2 p-1 rounded-lg shadow-sm "
                    >
                      <p className="text-white text-xs ">Add</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                  )}
                </div>

                {isVariantChecked && (
                  <>
                    {variantItems?.map((item, index) => (
                      <div key={index} className="flex gap-2 items-center ">
                        <input
                          type="text"
                          className={`focus:outline-none p-2 text-sm shadow-md border  border-pink-300 rounded-md h-8  w-full  `}
                          required
                          value={item.variantName}
                          placeholder="Variant Name"
                          onChange={(e) => {
                            const updatedItems = [...variantItems];
                            updatedItems[index].variantName = e.target.value;
                            setVariantItems(updatedItems);
                          }}
                        />
                        <input
                          type="number"
                          className={`focus:outline-none p-2 text-sm shadow-md border  border-pink-300 rounded-md h-8  w-full 
                             `}
                          value={item.price}
                          placeholder="Variant Price"
                          onChange={(e) => {
                            const updatedItems = [...variantItems];
                            updatedItems[index].price = e.target.value;
                            setVariantItems(updatedItems);
                          }}
                        />
                        <button
                          onClick={() => {
                            const updatedItems = [...variantItems];
                            updatedItems.splice(index, 1);
                            setVariantItems(updatedItems);
                            setVariantCount(variantCount - 1);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="w-5 h-5"
                          >
                            <path
                              d="M17 4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"
                              fill="red"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    ))}
                  </>
                )}
              </div>

              {/* price-div */}
              <div className="flex flex-col gap-1">
                {!isVariantChecked && (
                  <div className="flex flex-row justify-evenly w-full  ">
                    <div className="flex flex-row-reverse  w-full rounded-sm text-sm">
                      <input
                        id="messagecontent"
                        className="focus:outline-none placeholder-gray-400 shadow-md  border-pink-300 rounded-md text-xs p-2 border mr-3 "
                        type="number"
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        required
                        placeholder="Retail Price"
                        rows="1"
                      />
                      <p className="text-sm m-2 w-1/4 justify-center font-semibold text-gray-600 ">
                        Retail Price
                      </p>
                    </div>
                  </div>
                )}
                <div className="flex mt-1 w-full justify-start ">
                  {!isShippingChecked && (
                    <p className="flex text-xs text-gray-600 my-2">
                      Shipping Charges?
                      <Tooltip
                        enterTouchDelay={0}
                        TransitionComponent={Zoom}
                        placement="top"
                        arrow
                        title="Use this to add shipping charge to each item."
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-4 h-4"
                        >
                          <path
                            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"
                            fill="rgba(235,117,253,1)"
                          ></path>
                        </svg>
                      </Tooltip>
                    </p>
                  )}
                  <input
                    type="checkbox"
                    id="shippingPrice"
                    name="shippingPrice"
                    className="h-4 w-4 mx-2 my-2"
                    checked={isShippingChecked}
                    onChange={handleShippingPrice}
                  />

                  {isShippingChecked && (
                    <div className="flex items-end  w-full ">
                      <div className="flex flex-row w-full justify-evenly rounded-md text-sm">
                        <p className="text-sm w-1/3 m-1 font-semibold text-gray-600 ">
                          Shipping Price
                        </p>
                        <input
                          id="messagecontent"
                          className="focus:outline-none placeholder-gray-400 mr-1 shadow-md border border-pink-300 rounded-md text-xs p-2 "
                          type="number"
                          value={body2}
                          onChange={(e) => {
                            setBody2(e.target.value);
                            setTotalprice(parseInt(body) + parseInt(body2));
                          }}
                          required
                          placeholder="Shipping Price/item"
                          rows="1"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Add ons div */}
              <div className="flex flex-col items-start  gap-2 ">
                <div className="flex items-center gap-4">
                  <p className="flex text-sm text-gray-600 my-2 ">
                    Add-ons?
                    <Tooltip
                      enterTouchDelay={0}
                      TransitionComponent={Zoom}
                      placement="top"
                      arrow
                      leaveTouchDelay={5000}
                      title="This allows users to choose additional items on top of the product (for example, extra cheese on their burger!)."
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"
                          fill="rgba(235,117,253,1)"
                        ></path>
                      </svg>
                    </Tooltip>
                  </p>

                  <input
                    type="checkbox"
                    id="addon"
                    name="addon"
                    className="h-4 w-4 mx-2 my-2 bg-red-100 border-red-300 text-red-500 focus:ring-red-200"
                    checked={isAddOnChecked}
                    onChange={handleAddOnCheck}
                  />
                  {isAddOnChecked && (
                    <div
                      onClick={handleAddAddOnItem}
                      className="flex items-center gap-2 bg-gradient-to-br from-purple-300 to-pink-500 px-2 p-1 rounded-lg shadow-sm "
                    >
                      <p className="text-white text-xs ">Add</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                  )}
                </div>

                {isAddOnChecked && (
                  <>
                    {addOnItems.map((item, index) => (
                      <div key={index} className="flex gap-2 items-center ">
                        <input
                          type="text"
                          className={`focus:outline-none p-2 text-sm shadow-md border  border-pink-300 rounded-md h-8  w-full  `}
                          required
                          value={item.addOnName}
                          placeholder="Add-on Name"
                          onChange={(e) => {
                            const updatedItems = [...addOnItems];
                            updatedItems[index].addOnName = e.target.value;
                            setAddOnItems(updatedItems);
                          }}
                        />
                        <input
                          type="number"
                          className={`focus:outline-none p-2 text-sm shadow-md border  border-pink-300 rounded-md h-8  w-full 
                             `}
                          value={item.price}
                          placeholder="addon Price"
                          onChange={(e) => {
                            const updatedItems = [...addOnItems];
                            updatedItems[index].price = e.target.value;
                            setAddOnItems(updatedItems);
                          }}
                        />
                        <button
                          onClick={() => {
                            const updatedItems = [...addOnItems];
                            updatedItems.splice(index, 1);
                            setAddOnItems(updatedItems);
                            setAddOnCount(addOnCount - 1);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="w-5 h-5"
                          >
                            <path
                              d="M17 4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"
                              fill="red"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    ))}
                  </>
                )}
              </div>

              {/* Estimated Delivery */}
              <div>
                <div className="flex gap-2 items-center justify-between ">
                  <p className="flex text-sm">
                    Estimated Delivery Time{" "}
                    <Tooltip
                      enterTouchDelay={0}
                      TransitionComponent={Zoom}
                      leaveTouchDelay={5000}
                      placement="top"
                      arrow
                      title="Set this time to explain the delivery time of your product or service."
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"
                          fill="rgba(235,117,253,1)"
                        ></path>
                      </svg>
                    </Tooltip>
                  </p>
                  <select
                    className="cursor-pointer w-1/3 mr-4 shadow-md bg-white justify-end focus:outline-none border border-pink-300 rounded-md text-xs p-2"
                    required
                    value={selectedEtmDelivery}
                    onChange={(e) => setSelectedEtmDelivery(e.target.value)}
                  >
                    <option value="select">Select </option>
                    <option value="minutes">Minutes</option>
                    <option value="hours">Hours</option>
                    <option value="days">Days</option>
                  </select>
                </div>

                {selectedEtmDelivery !== "" &&
                  selectedEtmDelivery !== "select" && (
                    <div className="flex items-center gap-3 mt-2">
                      <input
                        className="focus:outline-none p-1  shadow-md border placehol border-pink-300 rounded-md h-8 w-full text-left text-sm"
                        type="text"
                        onChange={(e) => setFromEtmDelivery(e.target.value)}
                        value={fromEtmDelivery}
                        placeholder={`${
                          selectedEtmDelivery === "minutes" ? "20" : ""
                        } ${selectedEtmDelivery === "hours" ? "4" : ""}${
                          selectedEtmDelivery === "days" ? "3" : ""
                        } `}
                      />
                      <p>To</p>
                      <input
                        className="focus:outline-none p-1  shadow-md border placehol border-pink-300 rounded-md h-8  w-full text-left text-sm"
                        type="text"
                        onChange={(e) => setToEtmDelivery(e.target.value)}
                        value={toEtmDelivery}
                        placeholder={`${
                          selectedEtmDelivery === "minutes" ? "30" : ""
                        } ${selectedEtmDelivery === "hours" ? "8" : ""}${
                          selectedEtmDelivery === "days" ? "5" : ""
                        } `}
                      />

                      <p className=" text-gray-500 text-sm">
                        {selectedEtmDelivery}
                      </p>
                    </div>
                  )}
              </div>

              <div className="flex my-2 gap-4 items-center w-full justify-between pr-5">
                {/* share-link div */}
                <div className="text-sm py-2 px-1 border border-pink-300 shadow-md  rounded-md w-1/2 flex flex-row justify-start">
                  <p className=" mr-2 my-1 font-semibold text-gray-600 text-sm">
                    Enable Link
                  </p>
                  <ReactSwitch checked={checked} onChange={handleChange} />
                </div>

                {/* total-price */}
                {/* {body && body2 && (
                  <p className="flex flex-col items-end">
                    <span className="text-gray-500"> Total Price:</span>
                    <span className="text-xl text-gray-600">
                      {chosencurrency === "GBP" && (
                        <span className="mr-1">£</span>
                      )}
                      {chosencurrency === "EUR" && (
                        <span className="mr-1">€</span>
                      )}
                      {chosencurrency === "USD" && (
                        <span className="mr-1">$</span>
                      )}
                      {parseInt(body) + parseInt(body2)}
                    </span>
                  </p>
                )} */}
              </div>

              <p className=" flex items-start text-sm  text-gray-500">
                Quantity
                <Tooltip
                  enterTouchDelay={0}
                  TransitionComponent={Zoom}
                  placement="top"
                  arrow
                  title="Maximum amount that can be ordered by customer. Default value is 1."
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-4 h-4"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"
                      fill="rgba(235,117,253,1)"
                    ></path>
                  </svg>
                </Tooltip>
              </p>

              <input
                id="messagecontent"
                className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 w-full cursor-pointer rounded-lg bg-gray-200"
                type="number"
                value={qty}
                onChange={(e) => setQty(e.target.value)}
                required
                placeholder="Qty"
                rows="1"
              />

              <div className="p-1 ">
                {attributes !== null &&
                  attributes !== undefined &&
                  Object.keys(attributes).length !== 0 && (
                    <>
                      {attribute1 !== "" && attribute1 === "color" ? (
                        <>
                          <p className="flex items-center justify-start">
                            {attribute1}
                          </p>
                          <div className="relative flex items-center w-full justify-between  p-0.5 ">
                            <div className="  rounded-md grid grid-cols-6 w-9/12">
                              {selectedattribute1?.map((color, index) => (
                                <ColorComponent
                                  key={index}
                                  color={color}
                                  index={index}
                                  onDelete={onColorDelete}
                                />
                              ))}
                            </div>
                            <button
                              className="flex items-center rounded-md shadow-md px-2 p-1 w-3/12  text-xs"
                              onClick={handleColorPicker}
                            >
                              {showColorPicker ? (
                                <p className="flex pl-2">
                                  Close{" "}
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      width="20"
                                      height="20"
                                      className="text-red-400 w-4 h-4"
                                    >
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                                    </svg>
                                  </span>{" "}
                                </p>
                              ) : (
                                <button className="flex items-center ">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="20"
                                    height="20"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M19.228 18.732l1.768-1.768 1.767 1.768a2.5 2.5 0 1 1-3.535 0zM8.878 1.08l11.314 11.313a1 1 0 0 1 0 1.415l-8.485 8.485a1 1 0 0 1-1.414 0l-8.485-8.485a1 1 0 0 1 0-1.415l7.778-7.778-2.122-2.121L8.88 1.08zM11 6.03L3.929 13.1H18.07L11 6.03z" />
                                  </svg>
                                  <p className="text-xs">Chose Color</p>
                                </button>
                              )}
                            </button>

                            {/* color-picker absolute */}
                            {showColorPicker && (
                              <div className="absolute -top-24 right-0">
                                <button
                                  onClick={() => setShowColorPicker(false)}
                                >
                                  x
                                </button>
                                <CompactPicker
                                  onChangeComplete={handleColorSelection}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="">
                          {attribute1 !== "" && (
                            <>
                              <p className="flex items-center justify-start">
                                {attribute1}
                              </p>
                              <div className=" flex flex-wrap gap-3 p-1">
                                {attributes[attribute1]
                                  .split(",")
                                  .map((size) => (
                                    <SizeComponent
                                      key={size}
                                      name={size}
                                      selectedSizes={selectedattribute1}
                                      onSelect={() =>
                                        handleAttribute1Click(size)
                                      }
                                    />
                                  ))}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {attribute2 !== "" && (
                        <>
                          <p className="flex items-center justify-start">
                            {attribute2}
                          </p>
                          <div className=" flex flex-wrap gap-3  p-2">
                            {attributes[attribute2].split(",").map((size) => (
                              <SizeComponent
                                key={size}
                                name={size}
                                selectedSizes={selectedattribute2}
                                onSelect={() => handleAttribute2Click(size)}
                              />
                            ))}
                          </div>
                        </>
                      )}
                      {attribute3 !== "" && (
                        <>
                          <p className="flex items-center justify-start">
                            {attribute3}
                          </p>
                          <div className=" flex flex-wrap gap-3  p-2">
                            {attributes[attribute3].split(",").map((size) => (
                              <SizeComponent
                                key={size}
                                name={size}
                                selectedSizes={selectedattribute3}
                                onSelect={() => handleAttribute3Click(size)}
                              />
                            ))}
                          </div>
                        </>
                      )}
                      {attribute4 !== "" && (
                        <>
                          <p className="flex items-center justify-start">
                            {attribute4}
                          </p>
                          <div className=" flex flex-wrap gap-3  p-2">
                            {attributes[attribute4].split(",").map((size) => (
                              <SizeComponent
                                key={size}
                                name={size}
                                selectedSizes={selectedattribute4}
                                onSelect={() => handleAttribute4Click(size)}
                              />
                            ))}
                          </div>
                        </>
                      )}
                      {attribute5 !== "" && (
                        <>
                          <p className="flex items-center justify-start">
                            {attribute5}
                          </p>
                          <div className=" flex flex-wrap gap-3  p-2">
                            {attributes[attribute5].split(",").map((size) => (
                              <SizeComponent
                                key={size}
                                name={size}
                                selectedSizes={selectedattribute5}
                                onSelect={() => handleAttribute5Click(size)}
                              />
                            ))}
                          </div>
                        </>
                      )}
                    </>
                  )}
              </div>
            </div>
          </div>
          {/* image-section */}
          <div className=" py-2 md:py-0 my-auto md:w-1/2">
            {images?.length > 0 ? (
              <>
                <div className=" w-full mb-14  " style={{ height: "100%" }}>
                  {editImages.length > 0 ? (
                    <Carousel
                      infiniteLoop={true}
                      showStatus={false}
                      showArrows={false}
                      swipeable={false}
                      thumbWidth={50}
                      className="postproductCarousel"
                    >
                      {editImages.map((image) => (
                        <img src={image} alt="" className="  " />
                      ))}
                    </Carousel>
                  ) : (
                    <Carousel
                      infiniteLoop={true}
                      showStatus={false}
                      showArrows={false}
                      swipeable={false}
                      thumbWidth={50}
                      className="postproductCarousel"
                    >
                      {images.map((image) => (
                        <img src={image} alt="" className="" />
                      ))}
                    </Carousel>
                  )}
                </div>
                <label
                  htmlFor="image_uploads"
                  className=" text-xs align-middle cursor-pointer shadow-md px-4 py-1 w-40  bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
                >
                  Change Images
                </label>
                <input
                  type="file"
                  id="image_uploads"
                  name="image_uploads"
                  style={{ display: "none" }}
                  multiple
                  accept="image/*"
                  onChange={(e) => {
                    uploadImage(e);
                  }}
                />
              </>
            ) : (
              <>
                <label
                  htmlFor="img_uploads"
                  className="flex flex-col items-center justify-center text-gray-400 cursor-pointer md:w-72"
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="120"
                    height="120"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 12.586l4.243 4.242-1.415 1.415L13 16.415V22h-2v-5.587l-1.828 1.83-1.415-1.415L12 12.586zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784L18 17a6 6 0 0 0-11.996-.225L6 17v1.978a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z"
                      fill="rgba(50,152,219,1)"
                    />
                  </svg>
                  <p>Click to Upload Image(s)</p>
                </label>
                <input
                  id="img_uploads"
                  name="img_uploads"
                  type="file"
                  style={{ display: "none" }}
                  multiple
                  accept="image/*"
                  onChange={(e) => {
                    uploadImage(e);
                  }}
                />
              </>
            )}
          </div>
        </div>

        <form
          id="messagesubject"
          onSubmit={handleSumbit}
          className=" md:pt-2 pb-2"
        >
          <div className=" flex flex-col text-center ">
            <div className=" pl-2 text-center">
              <button className=" focus:outline-none text-center">
                <div className="focus:border-0 ">
                  <div className=" cursor-pointer w-40 text-md md:text-md shadow-md px-6 py-1 md:p-2  bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full">
                    Save
                  </div>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* Loading Modal*/}
      <Modal
        isOpen={isLoadingModalOpen}
        className="font-lexand  max-w-xl z-50 mx-5 sm:mx-auto rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setIsLoadingModalOpen(false)}
      >
        <div className=" p-6 rounded-md h-screen flex flex-col items-center justify-center">
          <div className="flex items-center justify-center space-x-2">
            <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PostMessagePopup2;
