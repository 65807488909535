import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
  useLocation,
} from "react-router-dom";

import "./components/burgercss.css";
import Sucess from "./components/Pages/payment/Sucess";
import UnderProcess from "./components/Pages/payment/UnderProcess";
import ShareFeed from "./components/Pages/Share.js";

import Test from "./Test.js";
import { QueryClient, QueryClientProvider } from "react-query";
import ShopFeed from "./components/Pages/sharePage/ShopFeed";
import { ThemeProvider } from "./components/ThemeContext";
import { PayProvider } from "./components/PayContext";
import OrderCart from "./components/Pages/sharePage/OrderCart";
import MobileCheckoutForm from "./components/Pages/sharePage/MobileCheckoutForm";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes";
import Login from "./components/Pages/auth/Login";
import Register from "./components/Pages/auth/Register";
import ResetPassword from "./components/Pages/auth/ResetPassword";
import ConfirmOtp from "./components/Pages/auth/ConfirmOtp";
import NewPassword from "./components/Pages/auth/NewPassword";
import LandingPage from "./components/Pages/LandingPage";
import { Auth, Hub } from "aws-amplify";
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const queryClient = new QueryClient();
function App() {
  document.title = "Zaplyn App";
  const location = useLocation();

  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const checkUser = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setUser(authUser);
    } catch (error) {
      setUser(null);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    const listener = (data) => {
      if (data.payload.event === "signIn" || data.payload.event === "signOut") {
        checkUser();
      }
    };
    Hub.listen("auth", listener);
    return () => Hub.remove("auth", listener);
  }, []);

  useEffect(() => {
    if (user) {
      ReactGA.set({ userId: user?.username });
      window.dataLayer.push({
        userId: user?.username,
        event: "userIdSet",
      });
    } else {
      ReactGA.set({ userId: null });
    }
  }, [user]);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);

  if (user === undefined) {
    return <p>...</p>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <PayProvider>
          <Routes>
            {user ? (
              <Route path="/" element={<AuthenticatedRoutes />}>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/*" element={<Test />} />
              </Route>
            ) : (
              <>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/verifyemail" element={<ConfirmOtp />} />
                <Route path="/newpassword" element={<NewPassword />} />
              </>
            )}

            <Route path="/share/:tile" element={<ShareFeed />} />

            <Route path="/cart/:tile" element={<OrderCart />} />

            <Route path="/pay/:tile" element={<MobileCheckoutForm />} />

            <Route path="/shop/:tile" element={<ShopFeed />} />
            <Route
              path="/underprocess/:checkoutsession/:tile"
              element={<UnderProcess />}
            />

            <Route path="/sucess" element={<Sucess />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </PayProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
// export default withAuthenticator(App, false, (document.title = "zaplyn App"));
