import React, { useState } from "react";

const How_It_Works = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [closeAccordian, setCloseAccordian] = useState(false);

  const handleAccordianClose = () => {
    setTimeout(() => setIsOpen(false), 350);
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);
  const [isOpen11, setIsOpen11] = useState(false);
  const [isOpen12, setIsOpen12] = useState(false);
  const [isOpen13, setIsOpen13] = useState(false);
  const [isOpen14, setIsOpen14] = useState(false);
  const [isOpen15, setIsOpen15] = useState(false);
  const [isOpen16, setIsOpen16] = useState(false);
  const [isOpen17, setIsOpen17] = useState(false);
  const [isOpen18, setIsOpen18] = useState(false);
  const [isOpen19, setIsOpen19] = useState(false);
  const [isOpen20, setIsOpen20] = useState(false);

  return (
    <div className="pt-20 pb-40  ">
      <div className="flex flex-col items-start md:max-w-xl mx-auto gap-2 ">
        {/* top section */}
        <div className="flex flex-col items-center w-full mt-2 md:mt-0  gap-2 mb-2">
          <p className="px-2 text-sm font-medium tracking-wider text-center uppercase">
            How it works
          </p>
          <h2 className="mb-2 text-xl font-bold leading-none text-center sm:text-3xl">
            Frequently Asked Questions
          </h2>
        </div>

        {/* first */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen1(!isOpen1)} className=" w-6 h-6">
              {isOpen1 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3
              className={`  max-w-3/4 ${
                isOpen1 ? "text-gray-900" : "text-gray-500"
              }`}
            >
              What is a Zaplyn link?
            </h3>
          </div>

          {isOpen1 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* second */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen2(!isOpen2)} className=" w-6 h-6">
              {isOpen2 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen2 ? "text-gray-900" : "text-gray-500"}`}>
              Why should I use Zaplyn?
            </h3>
          </div>
          {isOpen2 && <div>data</div>}
        </div>

        {/* third */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen3(!isOpen3)} className=" w-6 h-6">
              {isOpen3 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen3 ? "text-gray-900" : "text-gray-500"}`}>
              How do I create a product?
            </h3>
          </div>
          {isOpen3 && (
            <div className="flex flex-col items-start gap-3"> data</div>
          )}
        </div>

        {/* fourth */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen4(!isOpen4)} className=" w-6 h-6">
              {isOpen4 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen4 ? "text-gray-900" : "text-gray-500"}`}>
              How do I create a shop?
            </h3>
          </div>
          {isOpen4 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* fifth */}

        <div>
          <div className="flex items-center gap-4">
            <button onClick={() => setIsOpen5(!isOpen5)} className=" w-6 h-6">
              {isOpen5 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen5 ? "text-gray-900" : "text-gray-500"}`}>
              Can I create unlimited products and shops?
            </h3>
          </div>
          {isOpen5 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* sixth */}
        <div>
          <div className="flex items-center gap-4  ">
            <button onClick={() => setIsOpen6(!isOpen6)} className=" w-6 h-6">
              {isOpen6 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`  ${isOpen6 ? "text-gray-900" : "text-gray-500"}`}>
              What are 'Categories' and why do I need it?
            </h3>
          </div>
          {isOpen6 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* seventh */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen7(!isOpen7)} className=" w-6 h-6">
              {isOpen7 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen7 ? "text-gray-900" : "text-gray-500"}`}>
              What is the wallet section?
            </h3>
          </div>
          {isOpen7 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* eighth */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen8(!isOpen8)} className=" w-6 h-6">
              {isOpen8 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen8 ? "text-gray-900" : "text-gray-500"}`}>
              How do I share the Zaplyn link for my shop?
            </h3>
          </div>
          {isOpen8 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* ninth */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen9(!isOpen9)} className=" w-6 h-6">
              {isOpen9 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen9 ? "text-gray-900" : "text-gray-500"}`}>
              Do products also have Zaplyn links?
            </h3>
          </div>
          {isOpen9 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* tenth */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen10(!isOpen10)} className=" w-6 h-6">
              {isOpen10 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen10 ? "text-gray-900" : "text-gray-500"}`}>
              What are 'credits'?
            </h3>
          </div>
          {isOpen10 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* eleventh  */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen11(!isOpen11)} className=" w-6 h-6">
              {isOpen11 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen11 ? "text-gray-900" : "text-gray-500"}`}>
              How do I cash out?
            </h3>
          </div>
          {isOpen11 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* 12th */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen12(!isOpen12)} className=" w-6 h-6">
              {isOpen12 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen12 ? "text-gray-900" : "text-gray-500"}`}>
              Why do I need to add a payment method in my wallet?
            </h3>
          </div>
          {isOpen12 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* 13th */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen13(!isOpen13)} className=" w-6 h-6">
              {isOpen13 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen13 ? "text-gray-900" : "text-gray-500"}`}>
              What is a referral code?
            </h3>
          </div>
          {isOpen13 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* 14th */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen14(!isOpen14)} className=" w-6 h-6">
              {isOpen14 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen14 ? "text-gray-900" : "text-gray-500"}`}>
              How can customers pay using their own phone?
            </h3>
          </div>
          {isOpen14 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* 15th */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen15(!isOpen15)} className=" w-6 h-6">
              {isOpen15 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen15 ? "text-gray-900" : "text-gray-500"}`}>
              How do I find latest orders that I received?
            </h3>
          </div>
          {isOpen15 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* 16th */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen16(!isOpen16)} className=" w-6 h-6">
              {isOpen16 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen16 ? "text-gray-900" : "text-gray-500"}`}>
              Can I disable my shop or product?
            </h3>
          </div>
          {isOpen16 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* 17th */}

        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen17(!isOpen17)} className=" w-6 h-6">
              {isOpen17 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen17 ? "text-gray-900" : "text-gray-500"}`}>
              Can I change theme of my shop?
            </h3>
          </div>
          {isOpen17 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* 18th */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen18(!isOpen18)} className=" w-6 h-6">
              {isOpen18 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen18 ? "text-gray-900" : "text-gray-500"}`}>
              I need help in creating my shop. What can I do?
            </h3>
          </div>
          {isOpen18 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* 19th */}

        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen19(!isOpen19)} className=" w-6 h-6">
              {isOpen19 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen19 ? "text-gray-900" : "text-gray-500"}`}>
              Can I do business in other currencies such as Euro or USD?
            </h3>
          </div>
          {isOpen19 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>

        {/* 20th */}

        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen20(!isOpen20)} className=" w-6 h-6">
              {isOpen20 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen20 ? "text-gray-900" : "text-gray-500"}`}>
              Do you have a mobile app?
            </h3>
          </div>
          {isOpen20 && (
            <div className="flex flex-col items-start gap-3">data</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default How_It_Works;
