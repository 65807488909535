import React, { useState } from "react";
import { useQueryClient } from "react-query";
import getToken from "../Token";
import { createTileRef } from "../../helper/helper";

function TransferProductPopup({ tile, closeOnClick }) {
  const queryClient = useQueryClient();
  const [transferTo, setTransferTo] = useState("");
  const [isEditable, setIsEditable] = useState(false);

  //   cancel button func
  const handleCancelClick = () => {
    setTransferTo("");
    setIsEditable(false);
  };

  //   save button func
  const handleSubmit = async (e) => {
    e.preventDefault(); //prevents refreshing page
    // console.log(internalNote);
    const newTileref = createTileRef();

    const content = {
      author: transferTo,
      attribute: tile.attribute,
      totalprice: tile.total_price,
      itemname: tile.itemname,
      desc: tile.description,
      body: tile.retail_price,
      body2: tile.shipping_price,
      color: tile.color,
      size: tile.size,
      qty: tile.quantity,
      images: tile.images.toString(),
      subject: tile.category,
      sub_category: tile.sub_category,
      ZaplynTileRef: newTileref,
      link_enabled: tile.link_enabled,
      variant: tile.variant,
      show_variant: tile.show_variant,
      add_ons: tile.add_ons,
      etm_date: tile.etm_date,
      currency: tile.currency,
      attribute_1: tile.attribute_1,
      attribute_2: tile.attribute_2,
      attribute_3: tile.attribute_3,
      attribute_4: tile.attribute_4,
      attribute_5: tile.attribute_5,
    };
    const accesstoken = getToken();
    const response = await fetch(
      "https://omnistack.org/zaplyn/message-create/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify(content),
      }
    );

    const res = await response.json();

    if (res) {
      await queryClient.invalidateQueries(["productsData"]);
      await queryClient.invalidateQueries(["filteredProductsData"]);
      await queryClient.invalidateQueries("categoryFilterButtonsData");
    }

    closeOnClick();
  };

  return (
    <div>
      <div className="bg-white p-2 rounded-lg">
        <div className="flex flex-col items-center justify-center">
          <textarea
            name="internalnote"
            id="internalnote"
            className={`focus:outline-none p-2 shadow-md border  border-pink-300 rounded-md text-xs h-20 w-full overflow-hidden ${
              isEditable ? "bg-gray-100 " : "bg-gray-200 text-gray-600"
            }`}
            type="Text"
            value={transferTo}
            onChange={(e) => setTransferTo(e.target.value)}
            required
            placeholder="Add Username*"
            rows="1"
            readOnly={!isEditable}
            maxLength={150}
          />

          {!isEditable ? (
            <button
              onClick={() => setIsEditable(true)}
              className="py-1 mt-1 px-4 bg-gradient-to-b from-purple-300 to-pink-500 to-20% rounded-full text-xs font-semibold text-white shadow-md"
            >
              Edit
            </button>
          ) : (
            <div className="flex gap-2 mt-1 text-sm">
              <button
                onClick={handleCancelClick}
                className="px-4 p-1 rounded-full text-white hover:text-gray-700 bg-red-700 hover:bg-red-300"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 p-1 rounded-full text-white hover:text-gray-700 bg-green-700 hover:bg-green-300"
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TransferProductPopup;
