import React from "react";
import ReactDOM from "react-dom";
import CheckoutForm from "./Pages/CheckoutForm";
import getToken from "./Token";
import { useState, useEffect, useContext } from "react";
import Api from "../config/Api.json";
import fernet from "fernet";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Buffer } from "buffer";
import { GlobalContext } from "./GlobalContext";
import { useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import ReactPaginate from "react-paginate";
import getUsername from "./Account_Infomation/Username";
import Cashout_Page from "./Pages/Cashout_Page";

window.Buffer = Buffer;
dayjs.extend(utc);
dayjs.extend(tz);

const PaymentMethods = (props) => {
  const queryClient = useQueryClient();
  const accesstoken = getToken();
  const currentusername = getUsername();
  const [data, setData] = useState([]);
  const [de, setDe] = useState("");
  const [type, setType] = useState("");
  const [last4, setLast4] = useState("");
  const [exp_month, setExp_month] = useState("");
  const [exp_year, setExp_year] = useState("");
  const [code, setCode] = useState("");
  const [saved, setSaved] = useState("no");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userData } = useContext(GlobalContext);
  const [isPaymentTabOpen, setIsPaymentTabOpen] = useState(true);
  const timeZone = dayjs.tz.guess();
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;

  const handleAddPaymentMethod = async () => {
    try {
      const response = await fetch(link + "save-card/", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({ user: currentusername }),
      });
      const json = await response.json();
      if (json) {
        window.location.href = json["url"];
        window.dataLayer.push({
          userId: currentusername,
          event: "add_payment_method",
        });
        console.log("added");
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const getPaymentMethods = async () => {
    const acstoken = getToken();
    try {
      const response = await fetch(link + "get-payment-methods/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + acstoken,
        },
        body: JSON.stringify({ user: currentusername }),
      });

      const json = await response.json();
      return json;
    } catch (error) {
      console.log("Error", error);
    }
  };

  // payment methods
  const {
    isLoading: isPaymentMethodsLoading,
    data: paymentMehodsData,
    isError: isPaymentMethodsError,
    error: paymentMethodsErr,
  } = useQuery("paymentMehodsData", getPaymentMethods, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // payment history/records

  const [page, setPage] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);

  const getPaymentRecords = async (pageNo = 1) => {
    try {
      const response = await fetch(
        `https://omnistack.org${process.env.REACT_APP_LINK}` +
          "payment-records/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
          },
          body: JSON.stringify({
            user: currentusername,
            page: pageNo + 1,
          }),
        }
      );

      const json = await response.json();
      // console.log(json);
      if (json) {
        return json;
      }
    } catch (error) {}
  };
  // payment records
  const {
    isLoading: isPaymentRecordsLoading,
    data: paymentRecordsData,
    isError: isPaymentRecordsError,
    error: paymentRecordsErr,
    isFetching: isPaymentRecordsDataFetching,
    isPreviousData: isPrevPaymentRecordsData,
  } = useQuery(
    ["paymentRecordsData", { pageOffset }],
    () => getPaymentRecords(pageOffset),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const handlePageChange = (e) => {
    setPageOffset(e.selected);
  };

  const pagesArray = Array(
    paymentRecordsData?.find((item) => item.hasOwnProperty("pages")).pages
  )
    .fill()
    .map((_, index) => index + 1);

  const submitHandler2 = () => {
    setIsPaymentTabOpen(true);
  };

  const removeItem = (index) => {
    setData([...data.slice(0, index - 1), ...data.slice(index)]);
  };

  const handleListPaymentButton = async () => {
    setIsPaymentTabOpen(false);
  };

  const handleRemovePaymentMethod = async () => {
    removeItem(data.id);
    let accesstoken = getToken();
    if (data.sort_code === "undefined") {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });

      window.dataLayer.push({
        user_id: currentusername,
        event: "remove_payment_method",
      });

      const response = await fetch(link + "delete-payment-method/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(currentusername),
          type: token.encode(data.type),
          last4: token.encode(data.last4),
          exp_month: token.encode(data.exp_month),
          exp_year: token.encode(data.exp_year),
          sort_code: token.encode("null"),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      });
      const json = await response.json();
      if (json) {
        toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
      }
    } else {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });
      const response = await fetch(link + "delete-payment-method/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(currentusername),
          type: token.encode(data.type),
          last4: token.encode(data.last4),
          exp_month: token.encode("null"),
          exp_year: token.encode("null"),
          sort_code: token.encode(data.sort_code),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      });
      const json = await response.json();
      if (json) {
        toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
      }
    }
  };

  const handleSetToDefault = async () => {
    let accesstoken = getToken();
    if (data.sort_code === "undefined") {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });
      await fetch(link + "set-default-payment/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(currentusername),
          type: token.encode(data.type),
          last4: token.encode(data.last4),
          exp_month: token.encode(data.exp_month),
          exp_year: token.encode(data.exp_year),
          sort_code: token.encode("null"),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      })
        .then((response) => response.json())
        .then((json) =>
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""))
        );

      setDe("Default");
      setType(data.type);
      setLast4(data.last4);
      setCode("null");
      setExp_month(data.exp_month);
      setExp_year(data.exp_year);
    } else {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });
      await fetch(link + "set-default-payment/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(currentusername),
          type: token.encode(data.type),
          last4: token.encode(data.last4),
          exp_month: token.encode("null"),
          exp_year: token.encode("null"),
          sort_code: token.encode(data.sort_code),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      })
        .then((response) => response.json())
        .then((json) =>
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""))
        );
      setDe("Default");
      setType(data.type);
      setLast4(data.last4);
      setCode(data.sort_code);
      setExp_month("null");
      setExp_year("null");
    }
  };

  const handleDownload = () => {
    // Convert JSON to CSV
    const columnsToInclude = [
      "order_id",
      "transaction_id",
      "created_at",
      "amount",
      "currency",
      "is_success",
      "mode",
      "refund_id",
      "estimated_date",
      "fees",
      "Discount",
    ];
    // Convert JSON to CSV
    const csvRows = [];
    csvRows.push(columnsToInclude.join(","));

    paymentRecordsData.forEach((item) => {
      const values = columnsToInclude.map((header) => {
        const escaped = ("" + item[header]).replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    });

    const csvData = csvRows.join("\n");

    // Create a Blob from the CSV data
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // Create a link and trigger download
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", "data.csv");
    a.click();
  };

  useEffect(() => {
    let accesstoken = getToken();

    fetch(link + "set-default-payment2/", {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify({ user: currentusername }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (
          json["Notification-text"] ===
          "Added payment method is now setup for future transactions."
        ) {
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
        }
      });

    handleListPaymentButton();
  }, []);

  useEffect(() => {
    if (paymentMehodsData) {
      if (paymentMehodsData.status) {
        return;
      }
      // console.log(json);
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
        token: String(paymentMehodsData),
        ttl: 0,
      });
      try {
        var result = token.decode();
        const resObj = JSON.parse(result);
        var count = Object.keys(resObj).length;
        setData([]);
        for (let i = 1; i < count + 1; i++) {
          setData((data) => [
            ...data,
            {
              id: i,
              type: resObj[i]["type"],
              last4: String(resObj[i]["last4"]),
              exp_month: String(resObj[i]["exp_month"]),
              exp_year: String(resObj[i]["exp_year"]),
              sort_code: String(resObj[i]["sort_code"]),
              default: resObj[i]["default"],
            },
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [paymentMehodsData]);

  return (
    <section className="py-5 flex flex-col items-center justify-between max-w-4xl mx-auto">
      <h2 className=" text-xl font-medium">Paymenet Methods</h2>
      <div className="flex flex-col mt-5 w-full p-2 items-center">
        <button
          onClick={handleAddPaymentMethod}
          className="py-1 px-4 bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full text-xs font-semibold text-gray-600 shadow-md "
        >
          <p>Add Payment Method</p>
        </button>
        <div className="flex flex-wrap gap-4 mt-2 w-full ">
          {isPaymentMethodsLoading && (
            <p className="text-center">Loading ...</p>
          )}
          {data?.map((data) => (
            <div key={data.id} className=" max-w-max mt-5  p-4 mx-auto">
              <div className=" flex flex-col gap-2 items-center border border-pink-300 rounded-md p-2 ">
                <p className="text-lg pt-2">
                  {data.type} ***{data.last4}{" "}
                  {data.exp_year === "undefined" ? "" : data.exp_year + "/"}
                  {data.exp_month === "undefined" ? "" : data.exp_month}{" "}
                  {data.sort_code === "undefined"
                    ? ""
                    : "Sort Code: " + data.sort_code + " "}{" "}
                </p>
                <div className="flex gap-2 items-center p-2 ">
                  <button
                    className="px-2 bg-gradient-to-b from-purple-200 to-pink-300 to-20%  rounded-md text-xs md:text-sm   font-semibold text-gray-600 shadow-md "
                    onClick={() => {
                      removeItem(data.id);

                      let accesstoken = getToken();
                      if (data.sort_code === "undefined") {
                        var secret = new fernet.Secret(
                          process.env.REACT_APP_FERNET_KEY
                        );
                        var token = new fernet.Token({
                          secret: secret,
                        });

                        fetch(link + "delete-payment-method/", {
                          method: "POST",
                          body: JSON.stringify({
                            user: token.encode(currentusername),
                            type: token.encode(data.type),
                            last4: token.encode(data.last4),
                            exp_month: token.encode(data.exp_month),
                            exp_year: token.encode(data.exp_year),
                            sort_code: token.encode("null"),
                          }),
                          headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            Authorization: "Bearer " + accesstoken,
                          },
                        })
                          .then((response) => response.json())
                          .then(async (json) => {
                            toast(
                              JSON.stringify(json["Notification-text"]).replace(
                                /"|'/g,
                                ""
                              )
                            );
                            await queryClient.invalidateQueries(
                              "paymentMehodsData"
                            );
                          });
                      } else {
                        var secret = new fernet.Secret(
                          process.env.REACT_APP_FERNET_KEY
                        );
                        var token = new fernet.Token({
                          secret: secret,
                        });
                        fetch(link + "delete-payment-method/", {
                          method: "POST",
                          body: JSON.stringify({
                            user: token.encode(currentusername),
                            type: token.encode(data.type),
                            last4: token.encode(data.last4),
                            exp_month: token.encode("null"),
                            exp_year: token.encode("null"),
                            sort_code: token.encode(data.sort_code),
                          }),
                          headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            Authorization: "Bearer " + accesstoken,
                          },
                        })
                          .then((response) => response.json())
                          .then(async (json) => {
                            toast(
                              JSON.stringify(json["Notification-text"]).replace(
                                /"|'/g,
                                ""
                              )
                            );
                            await queryClient.invalidateQueries(
                              "paymentMehodsData"
                            );
                          });
                      }
                    }}
                  >
                    Remove
                  </button>
                  <button
                    className="px-2 bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-md  text-xs md:text-sm  font-semibold text-gray-600 shadow-md "
                    onClick={(e) => {
                      let accesstoken = getToken();
                      if (data.sort_code === "undefined") {
                        var secret = new fernet.Secret(
                          process.env.REACT_APP_FERNET_KEY
                        );
                        var token = new fernet.Token({
                          secret: secret,
                        });
                        fetch(link + "set-default-payment/", {
                          method: "POST",
                          body: JSON.stringify({
                            user: token.encode(currentusername),
                            type: token.encode(data.type),
                            last4: token.encode(data.last4),
                            exp_month: token.encode(data.exp_month),
                            exp_year: token.encode(data.exp_year),
                            sort_code: token.encode("null"),
                          }),
                          headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            Authorization: "Bearer " + accesstoken,
                          },
                        })
                          .then((response) => response.json())
                          .then((json) =>
                            toast(
                              JSON.stringify(json["Notification-text"]).replace(
                                /"|'/g,
                                ""
                              )
                            )
                          );

                        setDe("Default");
                        setType(data.type);
                        setLast4(data.last4);
                        setCode("null");
                        setExp_month(data.exp_month);
                        setExp_year(data.exp_year);
                      } else {
                        var secret = new fernet.Secret(
                          process.env.REACT_APP_FERNET_KEY
                        );
                        var token = new fernet.Token({
                          secret: secret,
                        });
                        fetch(link + "set-default-payment/", {
                          method: "POST",
                          body: JSON.stringify({
                            user: token.encode(currentusername),
                            type: token.encode(data.type),
                            last4: token.encode(data.last4),
                            exp_month: token.encode("null"),
                            exp_year: token.encode("null"),
                            sort_code: token.encode(data.sort_code),
                          }),
                          headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            Authorization: "Bearer " + accesstoken,
                          },
                        })
                          .then((response) => response.json())
                          .then(async (json) => {
                            toast(
                              JSON.stringify(json["Notification-text"]).replace(
                                /"|'/g,
                                ""
                              )
                            );
                            await queryClient.invalidateQueries(
                              "paymentMehodsData"
                            );
                          });
                        setDe("Default");
                        setType(data.type);
                        setLast4(data.last4);
                        setCode(data.sort_code);
                        setExp_month("null");
                        setExp_year("null");
                      }
                    }}
                  >
                    {data.default === "Default" ? "Default" : "Set As Default"}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PaymentMethods;
