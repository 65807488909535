import React, { useState, useContext } from "react";
import getToken from "./Token";

import getUsername from "./Account_Infomation/Username";
import { Auth, Hub } from "aws-amplify";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "./GlobalContext";
const Card_popout = (props) => {
  const [opened, setOpened] = useState(false);
  const { userData } = useContext(GlobalContext);
  const navigate = useNavigate();

  const handleSignOutButtonClick = async () => {
    try {
      await Auth.signOut();
      Hub.dispatch("UI Auth", {
        // channel must be 'UI Auth'
        event: "AuthStateChange", // event must be 'AuthStateChange'
        message: "signedout", // message must be 'signedout'
      });

      navigate("/login");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const toggleBox = () => {
    setOpened(!opened);
  };

  const handlePaymentMethodsButtonClick = () => {
    setOpened(!opened);
  };

  const handleSupportButtonClick = () => {
    // this.props.clickpayments("supportpage");
    setOpened(!opened);
  };

  const handleAccountButtonClick = () => {
    setOpened(!opened);
  };

  const handleCashoutButtonClick = () => {
    setOpened(!opened);
  };

  return (
    <div>
      <button class="focus:outline-none" onClick={toggleBox}>
        {props.children}
      </button>

      {opened && (
        <div class="z-50 absolute right-0 rounded-md mb-2 shadow-md bg-white p-1 w-48 max-w-md flex-col flex-shrink">
          <p className="hover:bg-indigo-300 mb-1 hover:text-white rounded-md text-left text-xs text-bold pl-2 pt-1 pb-1">
            User:{" "}
            {userData && userData.username.startsWith("google")
              ? userData.name
              : userData.username}
          </p>
          <p
            onClick={handleAccountButtonClick}
            className="hover:bg-indigo-300 mb-1 hover:text-white rounded-md text-left text-xs text-bold pl-2 pt-1 pb-1"
          >
            <Link to="/account">Your Account</Link>{" "}
          </p>

          <p
            onClick={handlePaymentMethodsButtonClick}
            className="hover:bg-indigo-300 mb-1 hover:text-white rounded-md text-left text-xs text-bold pl-2 pt-1 pb-1"
          >
            <Link to="/payments">Wallet</Link>{" "}
          </p>

          <p
            onClick={handleSupportButtonClick}
            className="hover:bg-indigo-300 mb-1 hover:text-white rounded-md text-left text-xs text-bold pl-2 pt-1 pb-1"
          >
            {" "}
            <Link to="/support">Support</Link>{" "}
          </p>

          {/* <p
            onClick={handleCashoutButtonClick}
            className="hover:bg-indigo-300 mb-1 hover:text-white rounded-md text-left text-xs text-bold pl-2 pt-1 pb-1"
          >
            {" "}
            <Link to="/cashout">Cash out</Link>{" "}
          </p> */}
          <p
            onClick={handleCashoutButtonClick}
            className="hover:bg-indigo-300 mb-1 hover:text-white rounded-md text-left text-xs text-bold pl-2 pt-1 pb-1"
          >
            {" "}
            <Link to="/redeem-coupon">Reedem Coupon</Link>{" "}
          </p>
          <p
            onClick={handleCashoutButtonClick}
            className="hover:bg-indigo-300 mb-1 hover:text-white rounded-md text-left text-xs text-bold pl-2 pt-1 pb-1"
          >
            {" "}
            <Link to="/how-it-works">How It Works</Link>{" "}
          </p>

          <p
            onClick={handleSignOutButtonClick}
            className="hover:bg-indigo-300 mb-1 hover:text-white rounded-md text-left text-xs text-bold pl-2 pt-1 pb-1"
          >
            Sign Out{" "}
          </p>
          {/*
						<p onClick={handleSignOutButtonClick} className= "hover:bg-blue-900 hover:text-white rounded-sm text-left text-sm pl-2 pt-1 pb-1">
					 	<button onClick={handleSignOutButtonClick} className="signOutButton">SignOut</button>
					 	</p>
						*/}
        </div>
      )}
    </div>
  );
};

export default Card_popout;
