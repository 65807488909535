import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const getReferralCode = () => {
  // console.log("dsafasdf passes");
  var ReferralCode = "Error Getting Referral Code";

  const poolData = {
    UserPoolId: "eu-west-2_PLEKK7M02",
    ClientId: "3a4ot1guf8mf43f43ousl7noq0",
    // UserPoolId: "eu-west-2_UXJYfjlKr",
    // ClientId: "5k305rgnr1t5g9btchop69qfv8",
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  const cognitoUser = userPool.getCurrentUser();

  // Code to get users referral code

  for (var key in cognitoUser.storage) {
    var tmp = key.split(".");
    if (tmp[tmp.length - 1] !== "userData") continue;
    var res = JSON.parse(cognitoUser.storage[key]);
    for (var i = 0; i < res.UserAttributes.length; i++) {
      // console.log(res.UserAttributes[i], i);
      if (res.UserAttributes[i]["Name"] === "custom:ReferralCode") {
        // console.log("referral");
        // console.log(res.UserAttributes[i]["Value"]);
        ReferralCode = res.UserAttributes[i]["Value"];
      }
    }
  }

  return ReferralCode;
};

export default getReferralCode;
