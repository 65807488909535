import React from "react";
import ReactDOM from "react-dom";
import CheckoutForm from "./Pages/CheckoutForm";
import getToken from "./Token";
import { useState, useEffect, useContext } from "react";
import Api from "../config/Api.json";
import fernet from "fernet";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Buffer } from "buffer";
import { GlobalContext } from "./GlobalContext";
import { useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import ReactPaginate from "react-paginate";
import getUsername from "./Account_Infomation/Username";
import Cashout_Page from "./Pages/Cashout_Page";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
window.Buffer = Buffer;
dayjs.extend(utc);
dayjs.extend(tz);

const Payment = (props) => {
  const queryClient = useQueryClient();
  const accesstoken = getToken();
  const [data, setData] = useState([]);
  const [de, setDe] = useState("");
  const [type, setType] = useState("");
  const [last4, setLast4] = useState("");
  const [exp_month, setExp_month] = useState("");
  const [exp_year, setExp_year] = useState("");
  const [code, setCode] = useState("");
  const [saved, setSaved] = useState("no");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userData } = useContext(GlobalContext);
  const [isPaymentTabOpen, setIsPaymentTabOpen] = useState(true);
  const timeZone = dayjs.tz.guess();
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;

  const [fromDate, setFromDate] = React.useState(dayjs().subtract(1, "month"));
  const [toDate, setToDate] = React.useState(dayjs());

  const getCredits = async () => {
    try {
      const response = await fetch(link + "credits/", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          user: getUsername(),
        }),
      });
      const json = await response.json();
      if (json) {
        return json;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const {
    isLoading: isCreditsLoading,
    data: credits,
    isError: isCreditsError,
    error: creditsErr,
  } = useQuery("userCredits", getCredits, {
    refetchOnMount: false,
  });

  const handleAddPaymentMethod = async () => {
    try {
      const response = await fetch(link + "save-card/", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({ user: props.currentusername }),
      });
      const json = await response.json();
      if (json) {
        window.location.href = json["url"];
        window.dataLayer.push({
          userId: props.currentusername,
          event: "add_payment_method",
        });
        console.log("added");
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const getPaymentMethods = async () => {
    try {
      const response = await fetch(link + "get-payment-methods/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({ user: props.currentusername }),
      });

      const json = await response.json();
      return json;
    } catch (error) {
      console.log("Error", error);
    }
  };

  // payment methods
  const {
    isLoading: isPaymentMethodsLoading,
    data: paymentMehodsData,
    isError: isPaymentMethodsError,
    error: paymentMethodsErr,
  } = useQuery("paymentMehodsData", getPaymentMethods, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // payment history/records

  const [page, setPage] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);

  const getPaymentRecords = async (pageNo = 1) => {
    try {
      const response = await fetch(
        `https://omnistack.org${process.env.REACT_APP_LINK}` +
          "payment-records/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
          },
          body: JSON.stringify({
            user: props.currentusername,
            page: pageNo + 1,
          }),
        }
      );

      const json = await response.json();
      // console.log(json);
      if (json) {
        return json;
      }
    } catch (error) {}
  };
  // payment records
  const {
    isLoading: isPaymentRecordsLoading,
    data: paymentRecordsData,
    isError: isPaymentRecordsError,
    error: paymentRecordsErr,
    isFetching: isPaymentRecordsDataFetching,
    isPreviousData: isPrevPaymentRecordsData,
  } = useQuery(
    ["paymentRecordsData", { pageOffset }],
    () => getPaymentRecords(pageOffset),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const handlePageChange = (e) => {
    setPageOffset(e.selected);
  };

  const pagesArray = Array(
    paymentRecordsData?.find((item) => item.hasOwnProperty("pages")).pages
  )
    .fill()
    .map((_, index) => index + 1);

  const submitHandler2 = () => {
    setIsPaymentTabOpen(true);
  };

  const removeItem = (index) => {
    setData([...data.slice(0, index - 1), ...data.slice(index)]);
  };

  const handleListPaymentButton = async () => {
    setIsPaymentTabOpen(false);
  };

  const handleRemovePaymentMethod = async () => {
    removeItem(data.id);
    let accesstoken = getToken();
    if (data.sort_code === "undefined") {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });

      window.dataLayer.push({
        user_id: props.currentusername,
        event: "remove_payment_method",
      });

      const response = await fetch(link + "delete-payment-method/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(props.currentusername),
          type: token.encode(data.type),
          last4: token.encode(data.last4),
          exp_month: token.encode(data.exp_month),
          exp_year: token.encode(data.exp_year),
          sort_code: token.encode("null"),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      });
      const json = await response.json();
      if (json) {
        toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
      }
    } else {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });
      const response = await fetch(link + "delete-payment-method/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(props.currentusername),
          type: token.encode(data.type),
          last4: token.encode(data.last4),
          exp_month: token.encode("null"),
          exp_year: token.encode("null"),
          sort_code: token.encode(data.sort_code),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      });
      const json = await response.json();
      if (json) {
        toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
      }
    }
  };

  const handleSetToDefault = async () => {
    let accesstoken = getToken();
    console.log(code, type);
    if (data.sort_code === "undefined") {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });
      console.log(data.type, data.last4, data.exp_month, data.exp_year);
      await fetch(link + "set-default-payment/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(props.currentusername),
          type: token.encode(data.type),
          last4: token.encode(data.last4),
          exp_month: token.encode(data.exp_month),
          exp_year: token.encode(data.exp_year),
          sort_code: token.encode("null"),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      })
        .then((response) => response.json())
        .then((json) =>
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""))
        );

      setDe("Default");
      setType(data.type);
      setLast4(data.last4);
      setCode("null");
      setExp_month(data.exp_month);
      setExp_year(data.exp_year);
    } else {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });
      console.log(data.type, data.last4);
      await fetch(link + "set-default-payment/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(props.currentusername),
          type: token.encode(data.type),
          last4: token.encode(data.last4),
          exp_month: token.encode("null"),
          exp_year: token.encode("null"),
          sort_code: token.encode(data.sort_code),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      })
        .then((response) => response.json())
        .then((json) =>
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""))
        );
      setDe("Default");
      setType(data.type);
      setLast4(data.last4);
      setCode(data.sort_code);
      setExp_month("null");
      setExp_year("null");
    }
  };
  const handleDownload = async () => {
    try {
      const accesstoken = getToken();
      const response = await fetch(
        `https://omnistack.org${process.env.REACT_APP_LINK}` +
          "payment-records-date/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
          },
          body: JSON.stringify({
            user: props.currentusername,
            start_date: fromDate.format("YYYY-MM-DD"),
            end_date: toDate.format("YYYY-MM-DD"),
          }),
        }
      );
      const json = await response.json();
      // Convert JSON to CSV
      const columnsToInclude = [
        "order_id",
        "transaction_id",
        "created_at",
        "amount",
        "currency",
        "is_success",
        "mode",
        "refund_id",
        "estimated_date",
        "fees",
        "Discount",
      ];
      // Convert JSON to CSV
      const csvRows = [];
      csvRows.push(columnsToInclude.join(","));

      json.forEach((item) => {
        const values = columnsToInclude.map((header) => {
          const escaped = ("" + item[header]).replace(/"/g, '""');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      });

      const csvData = csvRows.join("\n");

      // Create a Blob from the CSV data
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      // Create a link and trigger download
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", "data.csv");
      a.click();
    } catch (error) {
      toast("Something went wrong!");
    }
  };

  useEffect(() => {
    let accesstoken = getToken();

    fetch(link + "set-default-payment2/", {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify({ user: props.currentusername }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (
          json["Notification-text"] ===
          "Added payment method is now setup for future transactions."
        ) {
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
        }
      });

    handleListPaymentButton();
  }, []);

  useEffect(() => {
    if (paymentMehodsData) {
      // console.log(json);
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
        token: String(paymentMehodsData),
        ttl: 0,
      });
      var result = token.decode();
      const resObj = JSON.parse(result);
      var count = Object.keys(resObj).length;
      setData([]);
      for (let i = 1; i < count + 1; i++) {
        setData((data) => [
          ...data,
          {
            id: i,
            type: resObj[i]["type"],
            last4: String(resObj[i]["last4"]),
            exp_month: String(resObj[i]["exp_month"]),
            exp_year: String(resObj[i]["exp_year"]),
            sort_code: String(resObj[i]["sort_code"]),
            default: resObj[i]["default"],
          },
        ]);
      }
    }
  }, [paymentMehodsData]);

  return (
    <section className="pt-20  pb-40 flex flex-col items-center justify-between max-w-2xl mx-auto">
      <ToastContainer autoClose={3000} />
      {credits && credits?.Credits !== "" && (
        <p className=" mb-1 rounded-sm text-center py-5 md:!py-2  ">
          Available Credits: {parseFloat(credits?.Credits).toFixed(1)}{" "}
        </p>
      )}
      <div className="flex w-full items-center justify-around ">
        <button
          onClick={handleListPaymentButton}
          className={`py-1 px-4 rounded-full text-xs font-semibold  cursor-pointer  ${
            !isPaymentTabOpen
              ? "  bg-gradient-to-b text-gray-900 from-purple-200 to-pink-300 to-20% shadow-md"
              : "bg-gradient-to-b text-gray-700 from-purple-100 to-pink-100 to-20% "
          }`}
        >
          <p>List Payment</p>
        </button>
        <button
          onClick={submitHandler2}
          className={`py-1 px-4 rounded-full text-xs font-semibold  cursor-pointer  ${
            isPaymentTabOpen
              ? "  bg-gradient-to-b text-gray-900 from-purple-200 to-pink-300 to-20% shadow-md"
              : "bg-gradient-to-b text-gray-700 from-purple-100 to-pink-100 to-20% "
          }`}
        >
          <p>Cashout</p>
        </button>
      </div>

      {isPaymentTabOpen ? (
        <Cashout_Page />
      ) : (
        <>
          <div id="column layout" className="flex ">
            {isPaymentRecordsLoading && <p>Loading ...</p>}
            {paymentRecordsData != null && (
              //   /* <p>Payment History</p> */
              <div className="flex flex-col gap-2 items-center mt-5">
                <ReactPaginate
                  previousLabel={
                    <span className="flex items-center justify-center w-6 h-6 border rounded-md shadow-md  border-pink-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4"
                      >
                        <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                      </svg>
                    </span>
                  }
                  nextLabel={
                    <span className="flex items-center justify-center w-6 h-6 border rounded-md shadow-md  border-pink-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4"
                      >
                        <path d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"></path>
                      </svg>
                    </span>
                  }
                  pageClassName=" flex items-center justify-center w-6 h-6 text-xs border rounded shadow-md  border-pink-300 md:hover:bg-pink-100 mx-1"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageRangeDisplayed={3}
                  pageCount={
                    paymentRecordsData?.find((item) =>
                      item.hasOwnProperty("pages")
                    ).pages
                  }
                  marginPagesDisplayed={1}
                  onPageChange={handlePageChange}
                  containerClassName="flex items-center justify-center mt-2"
                  activeClassName="bg-pink-400 text-white"
                  forcePage={pageOffset}
                />

                {isPaymentRecordsDataFetching && <p>Loading ...</p>}

                <div className="container p-2 mx-auto sm:p-4 font-lexand">
                  <div className="flex  gap-2 justify-end pb-3 ">
                    <div className="flex  gap-2">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          className={`bg-white  border-none w-28 h-8 text-xs `}
                          onChange={setFromDate}
                          value={fromDate}
                          slotProps={{ textField: { size: "small" } }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      -
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          className={`bg-white  border-none w-28 h-8 text-xs `}
                          onChange={setToDate}
                          value={toDate}
                          slotProps={{ textField: { size: "small" } }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                    <button
                      onClick={handleDownload}
                      disabled={!paymentRecordsData}
                      className="border rounded-md shadow p-1.5 px-2 flex items-center gap-2 h-full text-sm self-end"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="lucide lucide-file-down w-7 h-7"
                      >
                        <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" />
                        <path d="M14 2v4a2 2 0 0 0 2 2h4" />
                        <path d="M12 18v-6" />
                        <path d="m9 15 3 3 3-3" />
                      </svg>{" "}
                    </button>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="min-w-full text-xxs md:text-xs ">
                      <thead className="">
                        <tr className="text-center md:text-left">
                          <th className="p-1 md:p-3">Created at</th>
                          <th className="p-1 md:p-3">Order Id</th>
                          <th className="p-1 md:p-3">Transaction Id</th>
                          <th className="p-1 md:p-3 text-right">Amount</th>
                          <th className="p-1 md:p-3 ">mode</th>

                          <th className="p-1 md:p-3">Status</th>
                        </tr>
                      </thead>
                      {paymentRecordsData
                        ?.filter((item) => !item.hasOwnProperty("pages"))
                        .map((item) => (
                          <tbody key={item.created_at}>
                            <tr className="border-b border-opacity-20 border-gray-700 text-xxs md:text-xs ">
                              <td className="p-1 md:p-3">
                                <p>
                                  {dayjs
                                    .utc(item.created_at)
                                    .tz(timeZone)
                                    .format("YYYY-MM-DD HH:mm:ss A")}
                                </p>
                              </td>
                              <td className="p-1 md:p-3">
                                <p>{item.order_id}</p>
                              </td>
                              <td className="p-1 md:p-3">
                                {item.transaction_id === null && (
                                  <p className="text-center">-</p>
                                )}
                                {item.transaction_id && (
                                  <p>{item.transaction_id}</p>
                                )}
                              </td>
                              <td className="p-1 md:p-3 text-right">
                                <p>
                                  {item.currency === "null" && (
                                    <span className="mr-0.25"></span>
                                  )}
                                  {item.currency === "GBP" && (
                                    <span className="mr-0.25">£</span>
                                  )}
                                  {item.currency === "EUR" && (
                                    <span className="mr-0.25">€</span>
                                  )}
                                  {item.currency === "USD" && (
                                    <span className="mr-0.25">$</span>
                                  )}{" "}
                                  {item.amount.toFixed(2)}
                                </p>
                              </td>

                              <td className="p-1 md:p-3">
                                <p>{item.mode}</p>
                              </td>

                              <td className="p-2 md:p-3 text-right">
                                <p className="font-semibold  text-gray-100">
                                  {item.is_success ? (
                                    <span className="bg-green-600 rounded-full px-4 py-1 ">
                                      Success
                                    </span>
                                  ) : (
                                    <span className="bg-red-600 rounded-full px-4 py-1 ">
                                      Failed
                                    </span>
                                  )}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default Payment;
