import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ZaplynLogo from "../../../assets/ZaplynLogo.svg";
import { Auth } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import GoogleButton from "./GoogleButton";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await Auth.signIn(username, password);
      navigate("/home");
    } catch (error) {
      toast(error.message);
    }
    setLoading(false);
  };

  return (
    <div className="flex bg-authBg h-screen font-lexand ">
      <ToastContainer autoClose={2500} />
      <div className="flex flex-col justify-center items-center w-full max-w-md   mx-auto p-2">
        <div className=" border border-pink-200 bg-white p-5 w-full shadow-md rounded-md">
          <div className=" flex flex-col items-center mb-5">
            <img src={ZaplynLogo} alt="" className="w-40" />
            {/* <h4 className="text-3xl font-bold">Hello Again!</h4>
            <span className="py-4 text-xl text-center text-gray-500">
              Explore More by connecting with us.
            </span> */}
          </div>
          <div className="py-2">
            <h4 className="text-2xl font-semibold text-center">
              Sign in to your account
            </h4>
          </div>

          <form className="py-4" onSubmit={handleSubmit}>
            <div className=" flex flex-col items-center gap-5">
              <div className="w-3/4">
                <p className="text-left text-sm">Username *</p>
                <input
                  className="border border-pink-100  px-5 p-2 w-full rounded-xl shadow-sm text-sm focus:outline-none"
                  type="text"
                  value={username}
                  required
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Enter your username"
                />
              </div>
              <div className="w-3/4">
                <p className="text-left text-sm">Password *</p>
                <div className="flex items-center justify-between border border-pink-100 pr-2  w-full rounded-xl shadow-sm text-sm">
                  <input
                    className="flex pl-5 p-2 flex-grow focus:outline-none bg-transparent bg-none"
                    type={passwordType}
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                  />

                  <div
                    className="flex  items-center justify-end"
                    onClick={togglePassword}
                  >
                    {passwordType === "password" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 "
                      >
                        <path d="M4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62282 2.81932 7.5129 4.52047 5.93457ZM14.7577 16.1718L13.2937 14.7078C12.902 14.8952 12.4634 15.0002 12.0003 15.0002C10.3434 15.0002 9.00026 13.657 9.00026 12.0002C9.00026 11.537 9.10522 11.0984 9.29263 10.7067L7.82866 9.24277C7.30514 10.0332 7.00026 10.9811 7.00026 12.0002C7.00026 14.7616 9.23884 17.0002 12.0003 17.0002C13.0193 17.0002 13.9672 16.6953 14.7577 16.1718ZM7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87992 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925L16.947 12.7327C16.9821 12.4936 17.0003 12.249 17.0003 12.0002C17.0003 9.23873 14.7617 7.00016 12.0003 7.00016C11.7514 7.00016 11.5068 7.01833 11.2677 7.05343L7.97446 3.76015Z"></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4"
                      >
                        <path d="M1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12ZM12.0003 17C14.7617 17 17.0003 14.7614 17.0003 12C17.0003 9.23858 14.7617 7 12.0003 7C9.23884 7 7.00026 9.23858 7.00026 12C7.00026 14.7614 9.23884 17 12.0003 17ZM12.0003 15C10.3434 15 9.00026 13.6569 9.00026 12C9.00026 10.3431 10.3434 9 12.0003 9C13.6571 9 15.0003 10.3431 15.0003 12C15.0003 13.6569 13.6571 15 12.0003 15Z"></path>
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <button
                className="rounded-lg px-4 py-1 mt-2 w-3/4 bg-gradient-to-b from-purple-200 to-pink-300 transform active:from-pink-300 active:to-purple-300 active:scale-95 text-gray-50 text-xl shadow-sm text-center"
                type="submit"
              >
                {loading ? "loading.." : "Sign In"}
              </button>
              <div className="relative w-3/4">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t" />
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-white px-2 text-muted-foreground">
                    Or
                  </span>
                </div>
              </div>
              <GoogleButton />
            </div>

            <div className="flex flex-col gap-2 text-center text-xs md:text-sm py-4">
              <span className="text-gray-500">
                <Link className="text-red-500" to="/resetpassword">
                  Forgot Password ?
                </Link>
              </span>
              <span className="text-gray-500">
                Don't have an account?{" "}
                <Link className="text-red-500" to="/register">
                  Register Now
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
