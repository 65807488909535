import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import Endpoints from "../../../api/endpoints";
import Cart from "./Cart";
import ProductsComponent from "./ProductsComponent";
import { ThemeContext } from "../../ThemeContext";
import "./Theme.css";
import ZaplynLogo from "../../../assets/ZaplynLogo.svg";
import usePageSEO from "../../../lib/use-page-seo";
import { Helmet } from "react-helmet";
const ShopFeed = () => {
  const queryClient = useQueryClient();
  // const search = window.location.search;
  // const params = new URLSearchParams(search);
  // const status = params.get("redirect_status");
  const [status, setStatus] = useState("");
  const [tile, setTile] = useState(useParams()["tile"]);
  if (tile.slice(9, 10) && status !== "succeeded") {
    setStatus("succeeded");
  }

  // theme
  const {
    theme,
    setTheme,
    themBtn,
    setThemBtn,
    themBtnStyle,
    setThemBtnStyle,
    setThemeTileBorder,
    setThemeTileBorderStyle,
    themeFont,
    setThemeFont,
    themeView,
    setThemeView,
  } = useContext(ThemeContext);

  const [showCart, setShowCart] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("select");

  const [updatePopup, setUpdatePopup] = useState(false);
  const setShowCartToFalse = () => {
    setShowCart(false);
  };

  //fetch shop data
  const {
    isLoading: isShopLoading,
    data: shopData,
    isError: isShopError,
  } = useQuery("shopDetails", () => Endpoints.FetchShopDetails(tile), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  //fetch shop products
  const {
    isLoading: isShopProductsLoading,
    data: shopProducts,
    isError: isShopProductsError,
  } = useQuery("shopProducts", () => Endpoints.FetchShopProducts(tile), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // console.log(shopData);

  // usePageSEO({
  //   title: shopData?.shopname || "Zaplyn",
  //   description:
  //     shopData?.description || "Zaplyn, digital commerce for everyone",
  //   keywords: ["zaplyn", "online", "shop"] || [],
  //   ogTitle: shopData?.shopname || "Zaplyn",
  //   ogDescription:
  //     shopData?.description || "Zaplyn, digital commerce for everyone",
  //   ogImage: shopData?.image || "",
  //   orUrl: `https://www.zaplyn.link/shop/${tile}` || window.location.href,
  // });

  //fetch categories
  const {
    isLoading: isCategoryLoading,
    data: productCategories,
    isError: isCategoryError,
  } = useQuery("categoryData", Endpoints.GetCategories, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const [cartUpdated, setCartUpdated] = useState(false);

  const handleAddToCart = (
    id,
    product,
    quantity,
    newatrribute_1,
    newatrribute_2,
    newatrribute_3,
    newatrribute_4,
    newatrribute_5,
    newprice,
    newtotal,
    variantName,
    addon,
    addonPrice
  ) => {
    setCartData((prevCart) => {
      const existingCartItem = prevCart.find(
        (cartItem) =>
          cartItem.ZaplynTileRef === product.ZaplynTileRef &&
          cartItem.newatrribute_1 === newatrribute_1 &&
          cartItem.newatrribute_2 === newatrribute_2 &&
          cartItem.newatrribute_3 === newatrribute_3 &&
          cartItem.newatrribute_4 === newatrribute_4 &&
          cartItem.newatrribute_5 === newatrribute_5
      );
      if (existingCartItem) {
        return prevCart.map((cartItem) =>
          cartItem.newZaplynTileRef === product.ZaplynTileRef &&
          cartItem.newatrribute_1 === newatrribute_1 &&
          cartItem.newatrribute_2 === newatrribute_2 &&
          cartItem.newatrribute_3 === newatrribute_3 &&
          cartItem.newatrribute_4 === newatrribute_4 &&
          cartItem.newatrribute_5 === newatrribute_5
            ? {
                ...cartItem,
                quantity: parseInt(cartItem.quantity) + parseInt(quantity),
              }
            : cartItem
        );
      } else {
        return [
          ...prevCart,
          {
            ...product,
            id,
            quantity: quantity,
            newatrribute_1,
            newatrribute_2,
            newatrribute_3,
            newatrribute_4,
            newatrribute_5,
            newprice,
            newtotal,
            variantName,
            addon,
            addonPrice,
          },
        ];
      }
    });
    setUpdatePopup(true);
    setTimeout(() => setUpdatePopup(false), 1000);
  };

  const handleRemoveFromCart = (id) => {
    const updatedCart = cartData.filter((item) => item.id !== id);
    setCartData(updatedCart);
  };

  const handleDecrease = (id) => {
    const updatedCart = cartData.map((item) =>
      item.id === id
        ? {
            ...item,
            quantity: parseInt(item.quantity) - 1,
            newprice: item.newtotal * (parseInt(item.quantity) - 1),
          }
        : item
    );
    setCartData(updatedCart);
  };

  const handleIncrease = (id) => {
    const updatedCart = cartData.map((item) =>
      item.id === id
        ? {
            ...item,
            quantity: parseInt(item.quantity) + 1,
            newprice: item.newtotal * (parseInt(item.quantity) + 1),
          }
        : item
    );
    setCartData(updatedCart);
  };

  useEffect(() => {
    setCartUpdated(true);

    // Reset the cart update status after a delay (e.g., 2 seconds)
    setTimeout(() => {
      setCartUpdated(false);
    }, 900);
  }, [cartData]);

  useEffect(() => {
    if (shopData) {
      if (shopData?.deleted !== "yes") {
        setSelectedCategory(shopData?.category.split(",")[0]);
        setTheme(shopData?.theme?.theme);
        setThemBtn(shopData?.theme?.themeBtn);
        setThemBtnStyle(shopData?.theme?.themeBtnStyle);

        setThemeTileBorder(shopData?.theme?.showBorder);

        setThemeTileBorderStyle(shopData?.theme?.tileStyle);

        setThemeFont(shopData?.theme?.font);
        setThemeView(shopData?.theme?.borderType);
      }
    }
  }, [shopData]);

  if (isShopLoading || isShopProductsLoading) {
    return <p>Loading data ...</p>;
  }

  return (
    <section className={`relative ${themeFont} `}>
      {/* {updatePopup && (
        <div
          className="absolute inset-0 z-100 w-full flex justify-center items-center"
          id="wrapper"
        >
          <div className=" flex flex-col items-center justify-center ">
            <p
              className={`${theme} font-bold text-transparent bg-clip-text text-xl`}
            >
              Added to Cart.🎉
            </p>
          </div>
        </div>
      )} */}

      <Helmet>
        <title>{shopData?.shopname}</title>
        <meta name="description" content={shopData?.description} />
        <meta name="keywords" content={shopData?.description} />
        <meta property="og:title" content={shopData?.shopname} />
        <meta property="og:description" content={shopData?.description} />
        <meta property="og:image" content={shopData?.image} />
        <meta
          property="og:url"
          content={`https://www.zaplyn.link/shop/${tile}`}
        />
        <link rel="canonical" href={`https://www.zaplyn.link/shop/${tile}`} />
      </Helmet>

      {shopData?.link_enabled ? (
        <div className="flex flex-col max-w-3xl mx-auto p-2 ">
          <div className="flex items-center justify-end w-full max-w-3xl mr-2 md:mr-10">
            <p className="text-xxs">powered by</p>
            <img src={ZaplynLogo} alt="" className=" h-4" />
          </div>
          <div className="flex w-full ">
            {shopData.image && (
              <img
                src={shopData.image}
                alt=""
                className="w-20 h-20 rounded-full "
              />
            )}
            <div
              onClick={() => setShowCart(false)}
              className="ml-2 flex flex-grow flex-col cursor-pointer  "
            >
              <p className="text-2xl md:text-4xl">{shopData.shopname}</p>
              <p
                className={`text-gray-500
                `}
              >
                {shopData.description}
              </p>
            </div>
            <div className={`md:flex relative items-center justify-end `}>
              <button
                onClick={() => setShowCart(true)}
                className="  items-end pt-2 md:pt-0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path
                    d="M4.00488 16V4H2.00488V2H5.00488C5.55717 2 6.00488 2.44772 6.00488 3V15H18.4433L20.4433 7H8.00488V5H21.7241C22.2764 5 22.7241 5.44772 22.7241 6C22.7241 6.08176 22.7141 6.16322 22.6942 6.24254L20.1942 16.2425C20.083 16.6877 19.683 17 19.2241 17H5.00488C4.4526 17 4.00488 16.5523 4.00488 16ZM6.00488 23C4.90031 23 4.00488 22.1046 4.00488 21C4.00488 19.8954 4.90031 19 6.00488 19C7.10945 19 8.00488 19.8954 8.00488 21C8.00488 22.1046 7.10945 23 6.00488 23ZM18.0049 23C16.9003 23 16.0049 22.1046 16.0049 21C16.0049 19.8954 16.9003 19 18.0049 19C19.1095 19 20.0049 19.8954 20.0049 21C20.0049 22.1046 19.1095 23 18.0049 23Z"
                    fill={`#000`}
                  ></path>
                </svg>

                <span>Cart</span>
              </button>
              <span
                className={` z-20 absolute top-0 right-0 md:top-2 md:right-2 ${theme} h-4 w-4 text-center rounded-full text-xs`}
              >
                {cartData.length}
              </span>
              <span
                class={` ${
                  cartUpdated
                    ? "animate-ping h-32 w-32 absolute -top-12 -left-8  md:-left-10"
                    : // : " h-32 w-32 absolute -top-12 -left-8  md:-left-10"
                      "h-4 w-4 absolute inline-flex  top-0 right-0 md:top-2 md:right-2"
                } rounded-full ${theme}  `}
              ></span>
            </div>
          </div>

          {showCart ? (
            <div>
              <Cart
                shopDetails={shopData}
                cartData={cartData}
                backToShop={setShowCartToFalse}
                onRemoveFromCart={handleRemoveFromCart}
                onDecrease={handleDecrease}
                onIncrease={handleIncrease}
              />
            </div>
          ) : (
            <>
              <div className="flex flex-col max-w-xl mx-auto mt-10  ">
                {shopData?.category.split(",").length > 0 && (
                  <ul
                    className={`flex items-center  divide-x divide-gray-200 pl-2 pr-2 pb-3 w-80 md:w-full md:pl-0 overflow-x-auto ${
                      shopData?.category.split(",").length < 3
                        ? " justify-center"
                        : ""
                    }`}
                  >
                    {shopData?.category.split(",").map((category, index) => (
                      <li key={index} className="">
                        <button
                          onClick={() => {
                            setSelectedCategory(category);
                            setSelectedSubCategory("select");
                          }}
                          className="px-2 "
                        >
                          {category === selectedCategory ? (
                            <p
                              className={` ${themBtnStyle} ${themBtn} px-4 py-0.5 font-semibold text-sm text-gray-700 `}
                            >
                              {category}
                            </p>
                          ) : (
                            <p className="font-semibold text-sm px-4 py-0.5">
                              {category}
                            </p>
                          )}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {selectedCategory !== "" && (
                <div className={`p-1 flex flex-col max-w-xl mx-auto`}>
                  <ProductsComponent
                    selectedSubCategory={selectedSubCategory}
                    setSelectedSubCategory={setSelectedSubCategory}
                    onAddToCart={handleAddToCart}
                    selectedCategory={selectedCategory}
                  />
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <p className="text-center text-gray-600 mt-20 font-lexand">
            The Shop you're looking for isn't available. Please contact shop
            Owner!
          </p>
        </>
      )}
    </section>
  );
};

export default ShopFeed;
