import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const getToken = () => {
  const poolData = {
    UserPoolId: "eu-west-2_PLEKK7M02",
    ClientId: "3a4ot1guf8mf43f43ousl7noq0",
    // UserPoolId: "eu-west-2_UXJYfjlKr",
    // ClientId: "5k305rgnr1t5g9btchop69qfv8",
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  let token = "";

  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser != null) {
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else if (!session.isValid()) {
        console.log("Invalid session.");
      } else {
        //console.log("AccessToken: " + session.getAccessToken().getJwtToken());
        token = session.getAccessToken().getJwtToken();
      }
    });
  } else {
    console.log("User not found.");
  }

  return token;
};

export default getToken;
