import React, { useContext, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "./ProductTile.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Modal from "react-modal";
import PreviewModal from "./PreviewModal";
import { convertCurrency } from "../../../helper/helper";
import { ThemeContext } from "../../ThemeContext";

function ProductTile({
  productDetails,
  onAddToCart,
  productCategories,
  shopDetails,
}) {
  // theme
  const { theme, themBtn, themeTileBorder, themeView, themeTileBorderStyle } =
    useContext(ThemeContext);

  // product preview modal
  const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);
  const setPreviewModalIsOpenToTrue = () => {
    setPreviewModalIsOpen(true);
  };
  const setPreviewModalIsOpenToFalse = () => {
    setPreviewModalIsOpen(false);
  };

  const [totalPrice, setTotalPrice] = useState("");

  useEffect(() => {
    if (productDetails.show_variant === true) {
      setTotalPrice(
        parseInt(Object.values(productDetails?.variant)[0]) +
          productDetails.shipping_price
      );
    } else {
      setTotalPrice(productDetails.total_price);
    }
  }, [productDetails]);

  return (
    <div className={`${themeView === "grid" ? " " : ""} `}>
      {/* product tile border div */}
      <div
        onClick={setPreviewModalIsOpenToTrue}
        className={`
          ${
            themeTileBorder === "none"
              ? ``
              : themeTileBorder === "light"
              ? ` ${theme} ${themeTileBorderStyle} shadow-sm p-0.25`
              : ` ${theme} ${themeTileBorderStyle} shadow-sm p-0.5`
          }    `}
      >
        {/*product tile */}
        <div
          className={`bg-white ${themeTileBorderStyle} shadow-sm flex ${
            themeView === "grid"
              ? "md:flex-col md:gap-2 md:h-72 "
              : " space-x-4 w-full "
          }   p-2   cursor-pointer hover:opacity-60 `}
        >
          {/* product image */}
          <div
            className={`  ${
              themeView === "grid" ? "w-5/12 md:w-full" : "w-24"
            } `}
          >
            <Carousel
              infiniteLoop={true}
              showStatus={false}
              showIndicators={false}
              showArrows={false}
              swipeable={false}
              showThumbs={false}
              className="shopProductCarousel"
            >
              {productDetails.images.map((image) => (
                <div key={image}>
                  <img
                    src={image}
                    alt=""
                    className={`${
                      themeView === "grid"
                        ? "h-48 md:h-36  object-cover"
                        : "h-20  object-contain"
                    } `}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <div className="flex flex-col  justify-between  pb-4">
            <div
              className={`flex ${
                themeView === "grid" ? "flex-col pl-2" : ""
              }  justify-between w-full pb-1 `}
            >
              <div className="space-y-1  ">
                <h3 className="text-lg w-40 pr-4   font-semibold leading-snug ">
                  {productDetails.itemname}
                </h3>
                <p
                  className={`text-xs  w-40  text-justify  truncate text-gray-700
            } `}
                >
                  {productDetails.description}
                </p>
              </div>
              <div
                className={` ${
                  themeView === "grid"
                    ? " text-gray-600 mt-2"
                    : "text-right font-semibold "
                }  `}
              >
                <p className="text-lg ">{totalPrice}£</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* preview modal */}
      <Modal
        isOpen={previewModalIsOpen}
        className=" max-w-2xl z-50 mx-5 sm:mx-auto my-5 md:my-10 rounded-md shadow-lg text-center focus:outline-none"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setPreviewModalIsOpen(false)}
      >
        <button
          onClick={setPreviewModalIsOpenToFalse}
          className={` ${themBtn} flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer ${themeTileBorderStyle} text-sm font-semibold flex-shrink`}
        >
          x
        </button>
        <PreviewModal
          onCloseClick={setPreviewModalIsOpenToFalse}
          productDetails={productDetails}
          onAddToCart={onAddToCart}
          productCategories={productCategories}
        />
      </Modal>
    </div>
  );
}

export default ProductTile;
