const awsConfig = {
  aws_project_region: "eu-west-2",
  // aws_cognito_identity_pool_id:
  //   "eu-west-2:0a47724a-e942-4957-b42e-5ba44604d6b8",
  aws_cognito_region: "eu-west-2",
  aws_user_pools_id: "eu-west-2_PLEKK7M02",
  aws_user_pools_web_client_id: "3a4ot1guf8mf43f43ousl7noq0",
  oauth: {
    domain: "zaplyn-web.auth.eu-west-2.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn:
      "http://localhost:3000/home/,https://dev.zaplyn.link/home/,https://www.zaplyn.link/home/",
    redirectSignOut:
      "http://localhost:3000/,https://dev.zaplyn.link/,https://www.zaplyn.link/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const isStage = window.location.hostname === "dev.zaplyn.link";
const isProduction =
  window.location.hostname === "www.zaplyn.link" ||
  window.location.hostname === "zaplyn.link";
// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, stageRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, stageRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : isStage
      ? stageRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : isStage
      ? stageRedirectSignOut
      : productionRedirectSignOut,
  },
};
export default updatedAwsConfig;
