import React, { useContext, useEffect, useState } from "react";
import getToken from "../../Token";
import getUsername from "../../Account_Infomation/Username";
import { convertToBase64, createTileRef } from "../../../helper/helper";
import imageCompression from "browser-image-compression";
import { GlobalContext } from "../../GlobalContext";
import CreateShopModal from "./modal/CreateShopModal";
import Modal from "react-modal";
import Endpoints from "../../../api/endpoints";
import { ToastContainer, toast } from "react-toastify";
import ErrImage from "../../../assets/imageErr.jpg";
import { useQuery, useQueryClient } from "react-query";
import ShopTile from "./ShopTile";
import { Link } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import LoadingModal from "../../popups/LoadingModal";
import zaplynIconT1 from "../../../assets/t1.svg";
import zaplynIconT2 from "../../../assets/t2.svg";
function UserShops() {
  const curUser = getUsername();
  const accesstoken = getToken();
  const queryClient = useQueryClient();
  const { productImages, setAvailableProducts } = useContext(GlobalContext);

  //fetch shops
  const {
    isLoading: isShopsLoading,
    data: shopsData,
    isError: isShopsError,
    error: shopsErr,
  } = useQuery("shopsData", Endpoints.FetchShops, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  //create shop modal
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const setCreateModalIsOpenToFalse = () => {
    setCreateModalIsOpen(false);
  };

  if (isShopsLoading) {
    return <LoadingModal />;
  }
  if (isShopsError) {
    return (
      <div className="pt-24 flex flex-col items-center justify-centeri">
        <div className="flex items-center h-full sm:p-16 text-gray-700">
          <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="w-40 h-40 text-gray-600"
            >
              <path
                fill="currentColor"
                d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z"
              ></path>
              <rect
                width="176"
                height="32"
                x="168"
                y="320"
                fill="currentColor"
              ></rect>
              <polygon
                fill="currentColor"
                points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042"
              ></polygon>
              <polygon
                fill="currentColor"
                points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63"
              ></polygon>
            </svg>
            <p className="text-3xl">
              Looks like our services are currently offline
            </p>
            <div className="flex items-center ">
              <img
                src={zaplynIconT1}
                alt=""
                className="h-4 w-4 transform -translate-y-0.5 translate-x-3 "
              />

              <img src={zaplynIconT1} alt="" className="h-4 w-4 " />

              <p className="text-lg bg-gradient-to-b from-purple-500 to-pink-500 text-transparent bg-clip-text text-center ml-2">
                We'll be back soon!
              </p>
              <img
                src={zaplynIconT2}
                alt=""
                className="h-4 w-4  transform -translate-y-0.5 translate-x-3"
              />

              <img src={zaplynIconT2} alt="" className="h-4 w-4 " />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // console.log(shopsData);
  // console.log(isShopsError);
  // console.log(shopsErr);

  return (
    <div className="pt-24 flex flex-col max-w-4xl mx-auto p-5">
      <ToastContainer autoClose={1500} />
      {/* Create new Shop */}
      <Link
        to="/create-shop"
        className="flex gap-2 items-center justify-center cursor-pointer w-40 text-md md:text-md shadow-md px-6 py-1   bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
      >
        Create
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
        >
          <path
            d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
            fill="#878080"
          ></path>
        </svg>
      </Link>

      {/* Available shops div */}
      <div className="p-2 grid md:grid-cols-2 ">
        {shopsData &&
          Object.keys(shopsData).map((shopref) => (
            <ShopTile key={shopref} shopDetails={shopsData[shopref]} />
          ))}
      </div>

      {/* Create Shop Modal*/}
      <Modal
        isOpen={createModalIsOpen}
        className=" max-w-2xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setCreateModalIsOpen(false)}
      >
        <button
          onClick={setCreateModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 button shadow-md focus:outline-none cursor-pointer rounded-md hover:bg-black hover:text-white bg-white text-md font-semibold flex-shrink"
        >
          x
        </button>
        <CreateShopModal closeOnClick={setCreateModalIsOpenToFalse} />
      </Modal>
    </div>
  );
}

export default UserShops;
