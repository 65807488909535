import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import basket from "../assets/basket.svg";
import folder from "../assets/folder.svg";
import home from "../assets/home.svg";
import add from "../assets/add.svg";
import search from "../assets/search.svg";
import profile from "../assets/zaplynuser.svg";
import basket2 from "../assets/basket2.svg";
import folder2 from "../assets/folder2.svg";
import home2 from "../assets/home2.svg";
import add2 from "../assets/add2.svg";
import search2 from "../assets/search2.svg";
import ZaplynIcon from "../assets/ZaplynLogo.svg";
import Modal from "react-modal";
import getIsVerified from "./Account_Infomation/isVerified.js";
import PostMessagePopup from "./PostMessagePopup";
import Card_popout from "./Card_popout.js";
import { GlobalContext } from "./GlobalContext.js";
import Endpoints from "../api/endpoints";
import { useQuery } from "react-query";

import getToken from "./Token";
import getUsername from "./Account_Infomation/Username";

function TopBar(props) {
  const { setShowFilteredData, userData, userAvatar, setUserAvatar } =
    useContext(GlobalContext);
  const isUserVerified = getIsVerified();
  const [homeimage, setHomeImage] = useState(home);
  const [basketimage, setBasketImage] = useState(basket);
  const [addimage, setAddImage] = useState(add);
  const [folderimage, setFolderImage] = useState(folder);
  const [searchimage, setSearchImage] = useState(search);

  const handleMymessagesclick = () => {
    setHomeImage(home);
    setSearchImage(search);
    setBasketImage(basket);
    setFolderImage(folder2);
    setAddImage(add);
  };

  const handlemessageboardsclick = () => {
    setShowFilteredData(false);
    setHomeImage(home2);
    setSearchImage(search);
    setBasketImage(basket);
    setFolderImage(folder);
    setAddImage(add);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const setModalIsOpenToTrue = () => {
    setHomeImage(home);
    setSearchImage(search);
    setBasketImage(basket);
    setFolderImage(folder);
    setAddImage(add2);
    console.log(isUserVerified);
    if (isUserVerified) {
      // console.log("d");
      setModalIsOpen(true);
    }
  };
  const setModalIsOpenToFalse = () => {
    setHomeImage(home);
    setSearchImage(search);
    setBasketImage(basket);
    setFolderImage(folder);
    setAddImage(add);
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (window.location.pathname === "/home") {
      setHomeImage(home2);
    } else if (window.location.pathname === "/orders") {
      setFolderImage(folder2);
    } else if (window.location.pathname === "/shops") {
      setSearchImage(search2);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      setUserAvatar(userData?.logo);
    }
  }, [userData]);

  return (
    <header>
      <div className="z-30 fixed top-0 w-full border-b border-indigo-200 bg-gradient-to-r from-pink-200 via-gray-200 to-indigo-200  shadow-sm">
        {/* Navbar mobile devices */}
        <div className="md:hidden flex flex-col bg-gradient-to-t from-pink-200 via-gray-200 to-indigo-200 ">
          {/* top-row */}
          <div className=" px-5 flex shadow-sm items-center justify-between ">
            {/* Zaplyn logo*/}
            <div className="flex ">
              <div onClick={handlemessageboardsclick}>
                <Link to="/home">
                  <img
                    src={ZaplynIcon}
                    alt="zaplyn"
                    className="w-28 cursor-pointer"
                  />
                </Link>
              </div>
            </div>
            {/* Profile_Icon */}
            <div id="profile" className="relative ">
              <Card_popout>
                <button
                  onClick={() => {
                    setShowFilteredData(false);
                  }}
                  className="  border:none  "
                >
                  <img
                    src={
                      userAvatar ||
                      "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_placeholder.svg"
                    }
                    alt=""
                    className="h-8 w-8 mt-1.5 md:mt-0 lg:h-10 lg:w-10 rounded-full cursor-pointer"
                  />
                </button>
              </Card_popout>
            </div>
          </div>
          {/* bottom-row */}

          <div className="flex pt-1  flex-row border-t border-purple-200 shadow-md items-center justify-center space-x-16 lg:space-x-20">
            {/* Home_Icon */}
            <div id="home" className="">
              <Link to="/home">
                <div
                  className="flex flex-col items-center  text-xs font-semibold "
                  onClick={handlemessageboardsclick}
                >
                  <img src={homeimage} alt="" className="h-6 w-6 " />
                  <p className="text-xxs">Home</p>
                </div>
              </Link>
            </div>
            {/* Add_Icon */}

            <div id="add" className=" ">
              <Link to="/add-product">
                <div
                  className="cursor-pointer text-center flex flex-col items-center hover:text-black  text-xs font-semibold"
                  onClick={handlemessageboardsclick}
                >
                  <img src={addimage} alt="" className="h-6 w-6 " />
                  <p className="text-xxs">Add New</p>
                </div>
              </Link>
            </div>
            {/* <div id="add" className="  ">
              <p
                className="cursor-pointer text-center flex flex-col items-center hover:text-black  text-xs font-semibold"
                onClick={setModalIsOpenToTrue}
              >
                <img src={addimage} alt="" className="h-6 w-6 " />
                <p className="text-xxs">Add New</p>
              </p>

              <Modal
                isOpen={modalIsOpen}
                className=" max-w-2xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-lg text-center"
                overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
                onRequestClose={() => setModalIsOpen(false)}
              >
                <button
                  onClick={setModalIsOpenToFalse}
                  className="flex justify-start mb-1 px-2 button shadow-md focus:outline-none cursor-pointer rounded-md hover:bg-black hover:text-white bg-white text-md font-semibold flex-shrink"
                >
                  x
                </button>
                <PostMessagePopup
                  onClick={setModalIsOpenToFalse}
                  onPost={
                    (value) => props.postprop(value)
                  }
                />
              </Modal>
            </div> */}
            {/* Manage_Icon */}
            <div className="">
              <p
                id="manage"
                className="  flex flex-col items-center cursor-pointer  hover:text-black  text-xs font-semibold "
                onClick={handleMymessagesclick}
              >
                <Link to="/orders">
                  <img src={folderimage} alt="" className=" h-6 w-6" />
                  <p className="text-xxs">Orders</p>
                </Link>
              </p>
            </div>
            {/* Shops_Icon */}
            <div id="shops" className="relative ">
              <Link to="/shops">
                <button
                  className="flex flex-col  items-center cursor-pointer  text-black  text-xs font-semibold"
                  onClick={() => {
                    setHomeImage(home);
                    setSearchImage(search2);
                    setBasketImage(basket);
                    setFolderImage(folder);
                    setAddImage(add);
                  }}
                >
                  <img src={searchimage} alt="" className="h-6 w-6" />
                  <p className="text-xxs pb-0.5">Shops</p>
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* Navbar md+ devices */}
        <div className="hidden md:flex px-5 py-1 shadow-md items-center justify-between ">
          {/* Zaplyn logo*/}
          <div className="flex ">
            <div onClick={handlemessageboardsclick}>
              <Link to="/home">
                <img
                  src={ZaplynIcon}
                  alt="zaplyn"
                  className="w-36 cursor-pointer"
                />
              </Link>
            </div>
          </div>
          <div className="flex items-center w-1/2 justify-between">
            {/* Home_Icon */}
            <div id="home" className=" w-16 mt-4 align-bottom flex flex-row ">
              <Link to="/home">
                <div
                  className="flex flex-row   cursor-pointer text-center  text-xs font-semibold "
                  onClick={handlemessageboardsclick}
                >
                  <img src={homeimage} alt="" className="h-4 w-4 mr-1" />
                  <p className="hidden md:flex flex-row text-xs text-gray-700">
                    Home
                  </p>
                </div>
              </Link>
            </div>

            {/* Add_Icon */}

            <div id="add" className="mt-4 flex flex-row ">
              <Link to="/add-product">
                <div
                  className="text-center flex flex-row items-center hover:text-black  text-xs font-semibold "
                  onClick={handlemessageboardsclick}
                >
                  <img src={addimage} alt="" className="h-4 w-4 " />
                  <p className="hidden md:block w-16 text-xs text-gray-700 ">
                    Add New
                  </p>
                </div>
              </Link>
            </div>

            {/* <div id="add" className="mt-4 flex flex-row ">
              <p
                className="cursor-pointer text-center flex flex-row items-center hover:text-black  text-xs font-semibold"
                onClick={setModalIsOpenToTrue}
              >
                <img src={addimage} alt="" className="h-4 w-4 " />
                <p className="hidden md:block w-16 text-xs text-gray-700 ">
                  Add New
                </p>
              </p>
            </div> */}
            {/* Manage_Icon */}
            <div className="mx-2 flex flex-row mt-4">
              <Link to="/orders">
                <p
                  id="manage"
                  className="  flex flex-row md:items-center cursor-pointer  text-black  text-xs font-semibold "
                  onClick={handleMymessagesclick}
                >
                  <img
                    src={folderimage}
                    alt=""
                    className="h4 w-4 flex flex-row mr-1"
                  />

                  <span className="hidden md:flex flex-row gap-1 text-xs text-gray-700">
                    Orders
                  </span>
                </p>
              </Link>
            </div>
            {/* Shops_Icon */}
            <div id="shops" className="relative mx-2 flex flex-row mt-4">
              <Link to="/shops">
                <button
                  className=" text-xs text-black font-semibold flex flex-row gap-1 "
                  onClick={() => {
                    setHomeImage(home);
                    setSearchImage(search2);
                    setBasketImage(basket);
                    setFolderImage(folder);
                    setAddImage(add);
                  }}
                >
                  <img
                    src={searchimage}
                    alt=""
                    className="h-4 w-4 flex flex-row mr-1"
                  />
                  <div className="hidden md:flex flex-row gap-1 text-xs text-gray-700">
                    Shops
                  </div>
                </button>
              </Link>
            </div>

            {/* Profile_Icon */}
            <div id="profile" className="relative ">
              <Card_popout>
                <button
                  onClick={() => {
                    setShowFilteredData(false);
                  }}
                  className="  border:none  "
                >
                  <img
                    src={
                      userAvatar ||
                      "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_placeholder.svg"
                    }
                    alt=""
                    className="h-8 w-8 md:mt-0 lg:h-10 lg:w-10 rounded-full cursor-pointer"
                  />
                </button>
              </Card_popout>
            </div>
          </div>
        </div>
      </div>

      {/* React tool-tip(s)  */}
      {/* <ReactTooltip
        style={{ backgroundColor: "#D7E3F0", borderRadius: 10, zIndex: 100 }}
        anchorId="home"
        place="bottom"
        variant="warning"
        content={hometext}
      />
      <ReactTooltip
        style={{ backgroundColor: "#D7E3F0", borderRadius: 10, zIndex: 100 }}
        anchorId="search"
        place="bottom"
        variant="warning"
        content={searchtext}
      />
      <ReactTooltip
        style={{ backgroundColor: "#D7E3F0", borderRadius: 10, zIndex: 100 }}
        anchorId="cart"
        place="bottom"
        variant="warning"
        content={carttext}
      />
      <ReactTooltip
        style={{ backgroundColor: "#D7E3F0", borderRadius: 10, zIndex: 100 }}
        anchorId="manage"
        place="bottom"
        variant="warning"
        content={managetext}
      />
      <ReactTooltip
        style={{ backgroundColor: "#D7E3F0", borderRadius: 10, zIndex: 100 }}
        anchorId="profile"
        place="bottom"
        variant="warning"
        content={profiletext}
      />
      <ReactTooltip
        style={{ backgroundColor: "#D7E3F0", borderRadius: 10, zIndex: 100 }}
        anchorId="add"
        place="bottom"
        variant="warning"
        content={addtext}
      /> */}
    </header>
  );
}

export default TopBar;
