import { useState } from "react";
import React from "react";

function ColorComponent({ index, onDelete, color }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <button
        className="rounded-full"
        style={{
          backgroundColor: color,
          width: "30px",
          height: "30px",
          margin: "5px",
        }}
      ></button>
      <div className=" md:hidden absolute cursor-pointer inset-0 flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          className="w-4 h-4"
          onClick={() => onDelete(index)}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm6.414 8l1.768-1.768-1.414-1.414L12 12.586l-1.768-1.768-1.414 1.414L10.586 14l-1.768 1.768 1.414 1.414L12 15.414l1.768 1.768 1.414-1.414L13.414 14zM9 4v2h6V4H9z" />
        </svg>
      </div>
      {isHovered && (
        <div className="hidden absolute cursor-pointer inset-0 md:flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="20"
            height="20"
            onClick={() => onDelete(index)}
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm6.414 8l1.768-1.768-1.414-1.414L12 12.586l-1.768-1.768-1.414 1.414L10.586 14l-1.768 1.768 1.414 1.414L12 15.414l1.768 1.768 1.414-1.414L13.414 14zM9 4v2h6V4H9z" />
          </svg>
        </div>
      )}
    </div>
  );
}

export default ColorComponent;
