import getUserAttributes from "../components/Account_Infomation/get-user-attributes";
import getUsername from "../components/Account_Infomation/Username";
import getToken from "../components/Token";
import { createTileRef } from "../helper/helper";

//userData
const FetchUserData = async () => {
  const accesskeytoken = getToken();
  const curUser = await getUserAttributes();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "get-user-data/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({ user: curUser.username }),
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();

    return { ...json, ...curUser };
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

//topcategories for fiter button
const FetchTopCategory = async () => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "top-cat/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({
        seller: curUser,
      }),
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();

    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

//products data
const LoadUserData = async (pageNo = 1) => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + accesskeytoken);
  try {
    const response = await fetch(link + "message-list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({
        user: curUser,
        page: pageNo,
      }),
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();

    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

const FetchProductsListData = async () => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  try {
    const response = await fetch(
      `https://omnistack.org${process.env.REACT_APP_LINK}` + "message-list2/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesskeytoken,
        },
        body: JSON.stringify({
          user: curUser,
        }),
      }
    );
    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Error", error);
  }
};

//images data
const LoadImages = async () => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "image-list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({
        user: curUser,
      }),
    });
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    if (json) {
      const newImages = Object.keys(json).map((key) => {
        const { name, image } = json[key];
        return { id: key, name, imga: image };
      });
      return newImages;
    }
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

// Edit product sharing link
const EditLink = async (tileRef) => {
  const accesskeytoken = getToken();
  try {
    const response = await fetch(
      `https://omnistack.org${process.env.REACT_APP_LINK}` + "link/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesskeytoken,
        },
        body: JSON.stringify({
          ZaplynTileRef: tileRef,
        }),
      }
    );
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

//Clone Product
const CloneProduct = async (content) => {
  const accesskeytoken = getToken();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "message-create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify(content),
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    if (json) {
      // console.log(json);
      return json;
    }
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

//Delete Product
const DeleteProduct = async (tileRef) => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "message-delete/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({
        ZaplynTileRef: tileRef,
        user: curUser,
      }),
    });
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    if (json) {
      return json;
    }
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

// fetch-orders
const FetchOrderData = async (pageNo = 1) => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "fetch-orders/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({ user: curUser, page: pageNo }),
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

//category list
const GetCategories = async () => {
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "subject-list/", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

//user specific category list
const FetchUserCategories = async () => {
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const curUser = getUsername();
  try {
    const response = await fetch(link + "subject-list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: curUser }),
    });
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

// Edit  Internal note
const EditInternalNote = async (content) => {
  const accesskeytoken = getToken();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "create-note/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify(content),
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

//create Shop
const CreateShop = async ({
  currency,
  shopname,
  linkEnabled,
  img_file,
  productlist,
  multiple_bookings,
  booking_time,
  email,
  mobile,
  address,
  partial,
  enable_scheduling,
  shipping_enabled,
  theme,
  description,
  categorylist,
  discount,
}) => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  const newTileref = createTileRef();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "shop-create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({
        author: curUser,
        ZaplynShopRef: newTileref,
        description: description,
        currency: currency,
        discount: discount || "",
        vat: "10",
        link_enabled: linkEnabled,
        shipping_enabled,
        multiple_bookings,
        email,
        mobile,
        address,
        partial,
        booking_time,
        enable_scheduling,
        shopname: shopname,
        image: img_file,
        theme,
        ZaplynTileRef: productlist,
        category: categorylist,
      }),
    });
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

//Edit Shop
const EditShop = async ({
  shopRef,
  currency,
  shopname,
  linkEnabled,
  img_file,
  multiple_bookings,
  partial,
  booking_time,
  productlist,
  email,
  mobile,
  address,
  shipping_enabled,
  description,
  enable_scheduling,
  theme,
  categorylist,
  discount,
}) => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "shop-edit/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({
        author: curUser,
        ZaplynShopRef: shopRef,
        description: description,
        currency: currency,
        discount: discount || "0",
        vat: "10",
        link_enabled: linkEnabled,
        multiple_bookings,
        partial,
        booking_time,
        email,
        mobile,
        address,
        shopname: shopname,
        image: img_file,
        theme,
        shipping_enabled,
        enable_scheduling,
        ZaplynTileRef: productlist,
        category: categorylist,
      }),
    });
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

//Clone Shop
const CloneShop = async (content) => {
  const accesskeytoken = getToken();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "shop-create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify(content),
    });
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

//fetch Shop
const FetchShops = async () => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "fetch-shops/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({
        user: curUser,
      }),
    });
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

// Edit Shop sharing link
const EditShopLink = async (shopRef) => {
  const accesskeytoken = getToken();
  try {
    const response = await fetch(
      `https://omnistack.org${process.env.REACT_APP_LINK}` + "shop-link/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesskeytoken,
        },
        body: JSON.stringify({
          ZaplynShopRef: shopRef,
        }),
      }
    );
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

//Delete Shop
const DeleteShop = async (shopRef) => {
  const accesskeytoken = getToken();
  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    const response = await fetch(link + "shop-delete/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({
        ZaplynShopRef: shopRef,
      }),
    });
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

// Shop Share page Endpoints
const FetchShopDetails = async (tile) => {
  try {
    const response = await fetch(
      `https://omnistack.org${process.env.REACT_APP_LINK}` + "share-shop/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ZaplynShopRef: tile.slice(0, 9),
        }),
      }
    );
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};
// shop Products
const FetchShopProducts = async (tile) => {
  try {
    const response = await fetch(
      `https://omnistack.org${process.env.REACT_APP_LINK}` +
        "share-shop-tiles/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ZaplynShopRef: tile.slice(0, 9),
        }),
      }
    );
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const json = await response.json();
    return json;
  } catch (error) {
    // console.log("Something went wrong, please try again later", error);
    throw error;
  }
};

const Endpoints = {
  FetchUserData,
  FetchTopCategory,
  LoadUserData,
  LoadImages,
  EditLink,
  EditInternalNote,
  CloneProduct,
  DeleteProduct,
  FetchOrderData,
  GetCategories,
  FetchUserCategories,
  CreateShop,
  EditShop,
  FetchShops,
  EditShopLink,
  FetchProductsListData,

  DeleteShop,
  CloneShop,
  FetchShopDetails,
  FetchShopProducts,
};

export default Endpoints;
