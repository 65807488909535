import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

const PartialPaymentCheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isStripeLoading, setIsStripLoading] = useState(true);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url:
          "https://www.zaplyn.link/sucess?" +
          props.orderData.order_id +
          props.tileref +
          props.qty,
      },
    });
    console.log();

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element.on("ready", () => {
        setIsStripLoading(false);
      });
    }
  }, [elements]);

  return (
    <section className="mt-1">
      <div>
        <form id="payment-form" onSubmit={handleSubmit}>
          {isStripeLoading && (
            <div className="border rounded p-2 mb-1 text-center">
              <p className=" text-gray-600">Loading...</p>
            </div>
          )}
          <PaymentElement />
          {!isStripeLoading && (
            <button
              type="submit"
              disabled={!stripe}
              className="bg-checkoutbutton shadow-md w-full p-2 mt-2 text-xl font-semibold text-white "
            >
              Continue
            </button>
          )}
        </form>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </div>
    </section>
  );
};

export default PartialPaymentCheckoutForm;
