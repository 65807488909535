import React, { useContext } from "react";

import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import getUsername from "./Account_Infomation/Username";
import getToken from "./Token";
import getEmail from "./Account_Infomation/RegEmail";

import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { GlobalContext } from "./GlobalContext";

const VerifyMessagePopup = (props) => {
  const queryClient = useQueryClient();

  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  //var link = "http://localhost:8000/api/";
  const userEmail = getEmail();
  const userName = getUsername();

  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  const code = "dan442m";
  var ReferralCode = "";

  const [posting, setPosting] = useState(false);

  //const [isPending, setIsPending] = useState(false);

  const [subject, setSubject] = useState(null); //subject state for select

  //const {data: subjectlist} = useSubject(/*link+"topic-list/'*/'http://localhost:8000/api/topic-list/', accesstoken).then(setSubject(Object.keys(subjectlist)[0]));

  const [body, setBody] = useState("");

  const [VerifyCode, setVerifyCode] = useState("");

  const [message, setMessage] = useState("Referral Code");
  const [ValidCodes, setValidCodes] = useState("Referral Code");

  const [chosencurrency, setChosencurrency] = useState("GBP");
  const accesstoken = getToken();

  var myHeaders = new Headers();

  function CreateConnection() {
    var CreatorReferral = VerifyCode || "dan442m";
    var FriendReferral = ReferralCode;

    const ConnectContent = {
      CreatorReferral,
      FriendReferral,
    };
    fetch(
      `https://omnistack.org${process.env.REACT_APP_LINK}` +
        "create-zaplyn-connection/",
      {
        method: "POST",
        body: JSON.stringify({
          CreatorReferral: VerifyCode || "dan442m",
          FriendReferral: ReferralCode,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      }
    )
      .then((response) => response.json())
      .then((json) => console.log(""))
      .catch((err) => console.log(err));
  }

  async function AuthUser() {
    await CreateReferralCode();
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:isVerified": "true",
    });
    await queryClient.invalidateQueries("userDetails");
  }

  useEffect(() => {
    var Loaded = false;
    async function onLoad() {
      let accesskeytoken = getToken();
      myHeaders.append("Authorization", "Bearer " + accesskeytoken);
      await fetch(link + "zaplynreferral-list/", {
        headers: myHeaders,
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("could not fetch the data for that resource");
          }
          return res.json();
        })
        .then((data) => {
          //console.log(data);
          setValidCodes(data);
          Loaded = true;
          // for (var i in ValidCodes) {
          //   console.log(props);
          //   console.log(ValidCodes[i]["ReferralCode"]);
          //   if (code == ValidCodes[i]["ReferralCode"]) {
          //     return true;
          //   }
          // }
        })
        .catch((err) => {
          if (err.name === "AbortError") {
          } else {
          }
        });
    }
    if (!Loaded) {
      onLoad();
    }
  }, []);

  async function CreateReferralCode() {
    var part1 = userEmail.substring(0, 3);
    var part2 = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    var part3 = alphabet[Math.floor(Math.random() * alphabet.length)];

    ReferralCode = part1 + part2 + part3;

    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:ReferralCode": ReferralCode,
    });
    const content = {
      ZaplynUser: userName,
      ZaplynEmail: userEmail,
      ReferralCode,
    };

    fetch(link + "zaplynreferral-create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify(content),
    })
      .then((response) => response.json())
      .then((json) => CreateConnection())
      .catch((err) => console.log(err));
  }

  const handleSumbit = (e) => {
    e.preventDefault(); //prevents refreshing page
    //CurValidCodes();

    // console.log(ValidCodes);

    for (var i in ValidCodes) {
      if (VerifyCode === ValidCodes[i]["ReferralCode"]) {
        AuthUser();
        props.onClick();
        props.onClick2();
        continue;
      }
    }

    setVerifyCode("");
    setMessage("Invalid Code");
  };

  const handleSumbitCancel = (e) => {
    e.preventDefault(); //prevents refreshing page
    //CurValidCodes();

    // console.log(ValidCodes);

    AuthUser();
    props.onClick();
    props.onClick2();

    setVerifyCode("");
    setMessage("Invalid Code");
  };
  //need to make onclickfunction to update message board feed when submit in messageboard component

  return (
    <div className="bg-white rounded-md p-2 ">
      <form id="messagesubject" className="">
        <p className=" text-center">
          Please input the referral code provided to you by an existing user of
          the app
        </p>
        <div className=" flex flex-col text-center pt-2">
          <div className="col-span-2 flex-3 px-4 flex-grow">
            <input
              className="focus:outline-none p-2 w-full  rounded-md bg-gray-300 text-center"
              maxLength={7}
              value={VerifyCode}
              required
              onChange={(e) => setVerifyCode(e.target.value)}
              placeholder={message}
              type="text"
              rows="1"
            />
          </div>
          <p></p>
          <div className="flex flex-col md:flex-row items-center justify-center gap-2 text-center pt-5">
            <button
              onClick={handleSumbit}
              className=" px-5 py-1 w-full   text-center shadow-md rounded-full focus:outline-none cursor-pointer bg-gradient-to-t from-pink-300 to-purple-200 transform active:scale-90 text-sm "
            >
              Verify
            </button>
            <button
              onClick={handleSumbitCancel}
              className=" px-5 py-1 w-full   text-center shadow-md rounded-full focus:outline-none cursor-pointer bg-gradient-to-t from-pink-300 to-purple-200 transform active:scale-90 text-sm "
            >
              I don't have a referral code
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VerifyMessagePopup;
