import React from "react";

import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import getUsername from "./Account_Infomation/Username";
import getToken from "./Token";
import getEmail from "./Account_Infomation/RegEmail";

import { useEffect } from "react";

const VerifyMessagePopup2 = (props) => {
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  //var link = "http://localhost:8000/api/";



  const handleSumbit = (e) => {
    e.preventDefault(); //prevents refreshing page
    props.onClick3();
  };

  //need to make onclickfunction to update message board feed when submit in messageboard component

  return (
    <div className="bg-white rounded-md font-lexand py-5 ">
      <p className=" text-center">Welcome to zaplyn!</p>
      <p className=" text-center">Want to Know how it works?</p>
      <div className=" flex  flex-col text-center pt-2">
        <p></p>
        <div className="col-span-2 flex-1 pl-2 text-center pt-5">
          <button
            className="col-span-2 focus:outline-none text-center"
            onClick={handleSumbit}
          >
            <div className="focus:border-0 flex-3 ">
              <div className=" button text-center shadow-md rounded-full px-5 focus:outline-none cursor-pointer bg-gradient-to-t from-pink-300 to-purple-200 transform active:scale-90 text-xl font-semibold">
                <Link to="/how-it-works">Yes</Link>{" "}
              </div>
            </div>
          </button>
          <span> </span>
          <button
            className="col-span-2 focus:outline-none text-center sm:ml-4"
            onClick={handleSumbit}
          >
            <div className="focus:border-0 flex-3 ">
              <div className=" button cursor-pointer rounded-full px-5 text-center shadow-md focus:outline-none bg-gradient-to-t from-pink-300 to-purple-200 transform active:scale-90 text-xl font-semibold">
                <Link to="/">No thanks</Link>{" "}
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyMessagePopup2;
