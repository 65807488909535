import React, { createContext, useState, useReducer } from "react";
import ReactDOM from "react-dom";
import { useQuery } from "react-query";
import Endpoints from "../api/endpoints";

// Initial state
// const initialState = [];
// transactions: []

// Create context
export const GlobalContext = createContext();

// Reducer
// export const AppReducer = (state, action) => {
//   switch(action.type) {
//     case 'DELETE_TRANSACTION':
//       return {
//         ...state,
//         transactions: state.Shortlist.filter(transaction => transaction.id !== action.payload)
//       }
//     case 'ADD_TRANSACTION':
//       return {
//         ...state,
//         transactions: [action.payload, ...state.transactions]
//       }
//     default:
//       return state;
//   }
// }

// Provider component

export const GlobalProvider = ({ children }) => {
  // fetch userData
  const {
    isLoading: isUserDataLoading,
    data: userData,
    isError: isUserError,
    error: userErr,
  } = useQuery("userDetails", Endpoints.FetchUserData, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // fetch category list
  const {
    isLoading: isCategoryLoading,
    data: productCategories,
    isError: isCategoryError,
    error: categoryErr,
  } = useQuery("categoryData", Endpoints.FetchUserCategories, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // fetch products data
  // const {
  //   isLoading: isProductsLoading,
  //   data: fetchedProducts,
  //   isError: isProductsError,
  //   error: productsErr,
  // } = useQuery("productsData", Endpoints.LoadUserData, {
  //   refetchOnMount: false,
  //   refetchOnWindowFocus: false,
  // });

  // fetch products data
  const {
    isLoading: isProductsListLoading,
    data: fetchedProductsList,
    isError: isProductsListError,
    error: productsListErr,
  } = useQuery("productsDataList", Endpoints.FetchProductsListData, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  //Homepage filter
  const [filteredData, setFilteredData] = useState(null);
  const [showFilteredData, setShowFilteredData] = useState(false);

  // shops page product data
  const [availableProducts, setAvailableProducts] = useState(null);

  const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);

  const [selectedHomeFilterOptions, setSelectedHomeFilterOptions] = useState(
    []
  );

  const [selectedButtonFilters, setSelectedButtonFilters] = useState([]);

  const [showOrdersFilteredData, setShowOrdersFilteredData] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [selectedShopId, setSelectedShopId] = useState("");
  const [selectedOrderDate, setSelectedOrderDate] = useState("");
  const [selectedSortType, setSelectedSortType] = useState("latest");

  // userAvatar
  const [userAvatarArray, setUserAvatarArray] = useState([
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_1.svg",
      id: 1,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_2.svg",
      id: 2,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_3.svg",
      id: 3,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_4.svg",
      id: 4,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_5.svg",
      id: 5,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_6.svg",
      id: 6,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_7.svg",
      id: 7,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_8.svg",
      id: 8,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_9.svg",
      id: 9,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_10.svg",
      id: 10,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_11.svg",
      id: 11,
    },
  ]);
  const [userAvatar, setUserAvatar] = useState(
    "https://zaplynimages.s3.eu-west-2.amazonaws.com/zaplyn_avatar_placeholder.svg"
  );

  // const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  // function deleteTransaction(id) {
  //   dispatch({
  //     type: 'DELETE_TRANSACTION',
  //     payload: id
  //   });
  // }

  // function addTransaction(transaction) {
  //   dispatch({
  //     type: 'ADD_TRANSACTION',
  //     payload: transaction
  //   });
  // }

  return (
    <GlobalContext.Provider
      value={{
        isUserDataLoading,
        userData,
        isUserError,
        userErr,
        userAvatarArray,
        userAvatar,
        setUserAvatar,
        isCategoryLoading,
        productCategories,
        isCategoryError,
        filteredData,
        setFilteredData,
        showFilteredData,
        setShowFilteredData,
        availableProducts,
        setAvailableProducts,
        categoryErr,
        showOrdersFilteredData,
        setShowOrdersFilteredData,
        selectedFilterOptions,
        setSelectedFilterOptions,
        selectedHomeFilterOptions,
        setSelectedHomeFilterOptions,
        selectedButtonFilters,
        setSelectedButtonFilters,
        selectedOrderId,
        setSelectedOrderId,
        selectedProductName,
        setSelectedProductName,
        selectedCustomerName,
        setSelectedCustomerName,
        selectedShopId,
        setSelectedShopId,
        selectedOrderDate,
        setSelectedOrderDate,
        selectedSortType,
        setSelectedSortType,
        isProductsListLoading,
        fetchedProductsList,
        isProductsListError,
        productsListErr,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
