import React, { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Carousel } from "react-responsive-carousel";
import { convertCurrency } from "../../../../helper/helper";

function PreviewShopProductsComponent({
  selectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  theme,
  themBtn,
  themBtnStyle,
  themeTileBorder,
  themeTileBorderStyle,
  themeFont,
  themeView,
}) {
  const queryClient = useQueryClient();
  const shopDetails = queryClient.getQueryData("shopDetails");
  const products = queryClient.getQueryData("shopProducts");

  const [modProducts, setModProducts] = useState(null);

  useEffect(() => {
    const data = Object.values(products)?.filter(
      (item) => item.category === selectedCategory
    );
    setModProducts(data);
  }, [products, selectedCategory]);

  const subCategories = useMemo(() => {
    if (products) {
      return Object.values(products)
        ?.filter((item) => item.category === selectedCategory)
        .map((item) => item.sub_category);
    }
  }, [products, selectedCategory]);

  const uniqueSubCategories = useMemo(() => {
    return [...new Set(subCategories)];
  }, [subCategories]);

  return (
    <div className="p-1 flex flex-col ">
      <div>
        <ul className="flex items-center justify-center divide-x divide-gray-200 overflow-x-auto">
          {uniqueSubCategories
            ?.slice()
            .reverse()
            .map((item) => (
              <li key={item}>
                <p
                  onClick={() => setSelectedSubCategory(item)}
                  className={` cursor-pointer ${themBtnStyle}   px-4 py-0.5 font-semibold text-xs  mx-1  ${
                    selectedSubCategory === item
                      ? `${themBtn} text-black `
                      : "text-gray-500"
                  }   `}
                >
                  {item !== "select" ? `${item}` : "All"}
                </p>
              </li>
            ))}
        </ul>
      </div>
      <ul
        className={`${
          themeView === "grid"
            ? "grid md:grid-cols-2 gap-5 md:max-w-lg mx-auto"
            : "flex flex-col divide-y divide-gray-200 md:mx-auto "
        }  mt-2 `}
      >
        {selectedSubCategory === "select"
          ? modProducts?.map((item) => (
              <div
                className={`${
                  themeTileBorder === "none"
                    ? ``
                    : themeTileBorder === "light"
                    ? ` ${theme}  ${themeTileBorderStyle} shadow-sm p-0.25`
                    : ` ${theme}  ${themeTileBorderStyle} shadow-sm p-0.5`
                }
                ${themeView === "grid" ? " " : ""}
                mt-5`}
              >
                <div
                  key={item}
                  className={`${
                    theme === "dark" ? "" : "bg-white"
                  } ${themeTileBorderStyle} shadow-sm flex ${
                    themeView === "grid"
                      ? "md:flex-col md:gap-2 md:h-72 "
                      : " space-x-4 w-full "
                  }  p-2 cursor-pointer hover:opacity-60`}
                >
                  {/* product image */}
                  <div
                    className={` ${
                      themeView === "grid" ? "w-5/12 md:w-full" : "w-24"
                    }`}
                  >
                    <Carousel
                      infiniteLoop={true}
                      showStatus={false}
                      showIndicators={false}
                      showArrows={false}
                      swipeable={false}
                      showThumbs={false}
                      className="shopProductCarousel"
                    >
                      {item.images.map((image) => (
                        <div key={image}>
                          <img
                            src={image}
                            alt=""
                            className={`${
                              themeView === "grid"
                                ? "h-48 md:h-36  object-cover"
                                : "h-20  object-contain"
                            } `}
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <div className="flex flex-col  justify-between  pb-4">
                    <div
                      className={`flex ${
                        themeView === "grid" ? "flex-col pl-2" : ""
                      }  justify-between w-full pb-1 `}
                    >
                      <div className="space-y-1  ">
                        <h3 className="text-lg w-40 text-left font-semibold leading-snug ">
                          {item.itemname}
                        </h3>
                        <p
                          className={`text-xs w-40 text-left pb-2 truncate text-gray-700
            } `}
                        >
                          {item.description}
                        </p>
                      </div>
                      <div
                        className={` ${
                          themeView === "grid"
                            ? " text-gray-600 mt-2  text-left"
                            : "text-right font-semibold "
                        }  `}
                      >
                        <p className="text-lg ">
                          {item.total_price.toFixed(1)}£
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : modProducts
              ?.filter((item) => item.sub_category === selectedSubCategory)
              .map((item) => (
                <div
                  className={`${
                    themeTileBorder === "none"
                      ? ``
                      : themeTileBorder === "light"
                      ? ` ${theme}  ${themeTileBorderStyle} shadow-sm p-0.25`
                      : ` ${theme}  ${themeTileBorderStyle} shadow-sm p-0.5`
                  }
                ${themeView === "grid" ? " " : ""}
                mt-5`}
                >
                  <div
                    key={item}
                    className={`${
                      theme === "dark" ? "" : "bg-white"
                    } ${themeTileBorderStyle} shadow-sm flex ${
                      themeView === "grid"
                        ? "md:flex-col md:gap-2 md:h-72 "
                        : " space-x-4 w-full "
                    }  p-2 cursor-pointer hover:opacity-60`}
                  >
                    {/* product image */}
                    <div
                      className={` ${
                        themeView === "grid" ? "w-5/12 md:w-full" : "w-24"
                      }`}
                    >
                      <Carousel
                        infiniteLoop={true}
                        showStatus={false}
                        showIndicators={false}
                        showArrows={false}
                        swipeable={false}
                        showThumbs={false}
                        className="shopProductCarousel"
                      >
                        {item.images.map((image) => (
                          <div key={image}>
                            <img
                              src={image}
                              alt=""
                              className={`${
                                themeView === "grid"
                                  ? "h-48 md:h-36  object-cover"
                                  : "h-20  object-contain"
                              } `}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                    <div className="flex flex-col  justify-between  pb-4">
                      <div
                        className={`flex ${
                          themeView === "grid" ? "flex-col pl-2" : ""
                        }  justify-between w-full pb-1 `}
                      >
                        <div className="space-y-1  ">
                          <h3 className="text-lg w-40 text-left  font-semibold leading-snug ">
                            {item.itemname}
                          </h3>
                          <p
                            className={`text-xs w-40 text-left  pb-2 truncate text-gray-700
            } `}
                          >
                            {item.description}
                          </p>
                        </div>
                        <div
                          className={` ${
                            themeView === "grid"
                              ? " text-gray-600 mt-2  text-left"
                              : "text-right font-semibold "
                          }  `}
                        >
                          <p className="text-lg ">
                            {item.total_price.toFixed(1)}£
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
      </ul>
    </div>
  );
}

export default PreviewShopProductsComponent;
